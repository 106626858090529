import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Modal from "../ui/Modal";
import Result from "../ui/Result";

interface Props {
  onContinue?: () => void;
}

export default NiceModal.create(({ onContinue }: Props) => {
  const modal = useModal("application-sent-modal");
  return (
    <Modal
      visible={modal.visible}
      onClose={() => modal.hide()}
      afterClose={() => modal.remove()}
    >
      <Result
        state="success"
        title={"Спасибо!"}
        text="Ваша заявка была отправлена продавцу, ожидайте пока с вами свяжутся"
        button="Готово"
        onClick={() => {
          if (onContinue) {
            onContinue();
          }
          modal.hide();
        }}
      ></Result>
    </Modal>
  );
});
