import React from "react";
import { CreatorPageTheme, Links } from "./CreatorPage";
import FullImageProfile from "../ui/FullImageProfile";
import Profile from "../ui/Profile";
import GradientProfile from "../ui/GradientProfile";
import FullscreenProfile from "../ui/FullscreenProfile";

interface Props {
  avatar?: string;
  name: string;
  bio?: string;
  dark?: boolean;
  className?: string;
  theme: CreatorPageTheme;
  links?: Links;
  disableEvents?: boolean;
  maxWidth?: number;
}

export default function PreviewProfile(props: Props) {
  return props.theme === "sand" ? (
    <FullImageProfile {...props}></FullImageProfile>
  ) : props.theme === "black" ? (
    <GradientProfile {...props}></GradientProfile>
  ) : props.theme === "violet" ? (
    <FullscreenProfile {...props}></FullscreenProfile>
  ) : (
    <Profile {...props}></Profile>
  );
}
