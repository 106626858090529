import React, { useEffect } from "react";
import MenuItem from "./MenuItem";
import { IconList } from "../../ui/Icon";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useBreakpoint } from "use-breakpoint";
import { BREAKPOINTS } from "../../../constants/breakpoints";
import MenuItemPopover from "./MenuItemPopover";
import { useAuth } from "../../../providers/AuthProvider";
import { useApplications } from "../../../store/useApplications";
import { useShallow } from "zustand/react/shallow";
import { trackEvent } from "../../../lib/ym";

export interface Option {
  id: string;
  to: string;
  icon?: IconList;
  iconFill?: IconList;
  label: string;
  hasSubItems?: boolean;
  isSubItem?: boolean;
  counter?: number;
  parentItem?: string;
  selected?: (path: string) => boolean;
  eventParams: {
    item: string;
    type?: "new" | "completed";
  };
}

const menu: Option[] = [
  {
    id: "home",
    to: "/",
    icon: "shop",
    iconFill: "shop-fill",
    label: "Мой магазин",
    selected: (path) => path === "/" || path.includes("/items/"),
    eventParams: {
      item: "store",
    },
  },
  {
    id: "applicants",
    to: "/applicants/new",
    icon: "wallet",
    // iconFill: "shop-fill",
    hasSubItems: true,
    label: "Мои заявки",
    eventParams: {
      item: "applications",
    },
  },
  {
    id: "new-applicants",
    to: "/applicants/new",
    isSubItem: true,
    parentItem: "applicants",
    label: "Новые",
    eventParams: {
      item: "applications",
      type: "new",
    },
  },
  {
    id: "completed-applicants",
    to: "/applicants/completed",
    isSubItem: true,
    parentItem: "applicants",
    label: "Завершенные",
    eventParams: {
      item: "applications",
      type: "completed",
    },
  },
  {
    id: "pro",
    to: "/pricing",
    icon: "fire",
    iconFill: "fire-fill",
    label: "Pro тариф",
    eventParams: {
      item: "pricing",
    },
  },
];

function getSubItems(id: string) {
  return menu.filter((item) => item.parentItem === id);
}

interface Props {
  className?: string;
}

export default function Menu({ className }: Props) {
  const location = useLocation();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { user } = useAuth();
  const { getCount, count } = useApplications(
    useShallow((state) => ({
      getCount: state.getCount,
      count: state.count,
    })),
  );

  useEffect(() => {
    if (user?.id) {
      getCount(user.id);
    }
  }, [user?.id]);

  return (
    <div
      className={twMerge(
        "px-6 py-4 bg-neutral1 flex w-auto md:block md:w-full",
        className,
      )}
    >
      <Link
        to="/"
        className="w-[48px] h-[48px] mb-[48px] hidden md:block"
        onClick={() => {
          trackEvent("header_click", {
            target: "logo",
          });
        }}
      >
        <img src="/logo.svg"></img>
      </Link>
      {breakpoint !== "mobile"
        ? menu.map((item) => (
            <Link
              to={item.to}
              key={item.id}
              className={twMerge(
                item.to === location.pathname && "pointer-events-none",
              )}
              onClick={() => {
                trackEvent("menu_click", item.eventParams);
              }}
            >
              <MenuItem
                selected={
                  "selected" in item && item.selected
                    ? item.selected(location.pathname)
                    : location.pathname === item.to
                }
                icon={item.icon}
                iconFill={item.iconFill}
                hasSubItems={item.hasSubItems}
                isSubItem={item.isSubItem}
                counter={
                  item.id === "new-applicants" && count ? count : undefined
                }
              >
                {item.label}
              </MenuItem>
            </Link>
          ))
        : menu
            .filter((item) => !item.isSubItem)
            .map((item) => {
              return item.hasSubItems ? (
                <MenuItemPopover
                  key={item.id}
                  items={getSubItems(item.id)}
                  onItemClick={(id) => {
                    trackEvent(
                      "menu_click",
                      menu.find((iterated) => iterated.id === id)?.eventParams,
                    );
                  }}
                >
                  <MenuItem
                    selected={Boolean(
                      getSubItems(item.id).find(
                        (item) => location.pathname === item.to,
                      ),
                    )}
                    icon={item.icon}
                    iconFill={item.iconFill}
                    hasSubItems={item.hasSubItems}
                    isSubItem={item.isSubItem}
                    counter={item.counter}
                  >
                    {item.label}
                  </MenuItem>
                </MenuItemPopover>
              ) : (
                <Link
                  className="flex-1 w-full inline-flex justify-center items-center"
                  to={item.to}
                  key={item.id}
                  onClick={() => {
                    trackEvent("menu_click", item.eventParams);
                  }}
                >
                  <MenuItem
                    selected={location.pathname === item.to}
                    icon={item.icon}
                    iconFill={item.iconFill}
                    hasSubItems={item.hasSubItems}
                    isSubItem={item.isSubItem}
                    counter={item.counter}
                  >
                    {item.label}
                  </MenuItem>
                </Link>
              );
            })}
    </div>
  );
}
