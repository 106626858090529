import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Button from "./Button";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock-upgrade";
import { useEffect } from "react";

interface Props {
  title: string;
  text?: string;
  afterClose?: () => void;
  onClose?: () => void;
  visible: boolean;
  onContinue?: () => void;
  onCancel?: () => void;
  continueText?: string;
  theme?: "red";
}

export default function Dialog({
  title,
  text,
  onClose,
  afterClose,
  visible,
  onCancel,
  onContinue,
  continueText,
  theme,
}: Props) {
  useEffect(() => {
    if (visible) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
    return () => {
      enableBodyScroll(document.body);
    };
  }, [visible]);
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 h-full w-full z-50">
      <AnimatePresence onExitComplete={afterClose}>
        {visible && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.9 }}
              exit={{ opacity: 0 }}
              className="fixed top-0 right-0 bottom-0 left-0 bg-neutral2 opacity-90"
            ></motion.div>
            <motion.div
              initial={{
                opacity: 0,
                scale: 1.05,
                translateX: "-50%",
                translateY: "-50%",
              }}
              animate={{
                opacity: 1,
                scale: 1,
                translateX: "-50%",
                translateY: "-50%",
              }}
              exit={{
                opacity: 0,
                scale: 1.05,
                translateX: "-50%",
                translateY: "-50%",
              }}
              className="absolute top-1/2 left-1/2 opacity-100 p-6 rounded-[16px] bg-neutral1 flex flex-col max-w-[400px] w-[90%]"
            >
              <div className="flex flex-col gap-6 text-center">
                <div className="flex flex-col gap-2">
                  <div className="text-[24px] leading-[30px] font-bold">
                    {title}
                  </div>
                  {text && (
                    <div className="text-[15px] font-medium text-neutral4">
                      {text}
                    </div>
                  )}
                </div>
                <div className="flex items-center gap-3 flex-col">
                  <Button
                    grow
                    theme={theme}
                    onClick={() => {
                      onClose && onClose();
                      if (onContinue) {
                        onContinue();
                      }
                    }}
                  >
                    {continueText ?? "Продолжить"}
                  </Button>
                  {onCancel && (
                    <Button
                      theme="secondary"
                      grow
                      onClick={() => {
                        onClose && onClose();
                        if (onCancel) {
                          onCancel();
                        }
                      }}
                    >
                      Отменить
                    </Button>
                  )}
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}
