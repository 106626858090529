import React, { HTMLProps, ReactNode, useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import FormLabel from "./FormLabel";
import Icon, { IconList } from "./Icon";

export type InputTheme = "default" | "white" | "dark" | "black";

interface Props {
  value?: string | null;
  onInput?: (value: string) => any;
  type?: string;
  label?: string;
  labelTooltip?: string;
  placeholder?: string;
  error?: string | boolean;
  inputProps?: HTMLProps<HTMLInputElement>;
  grow?: boolean;
  theme?: InputTheme;
  className?: string;
  icon?: IconList;
  children?: ReactNode;
}

export default function Input({
  value,
  onInput,
  label,
  labelTooltip,
  type = "text",
  placeholder,
  error,
  inputProps,
  grow,
  theme = "default",
  className,
  icon,
  children,
}: Props) {
  const [active, setActive] = useState(false);
  const id = useRef(String(Date.now() + Math.random()));

  const bg = useMemo(() => {
    if (theme === "black") {
      return [
        "bg-neutral8 border-neutral8",
        "bg-neutral7 border-[#2A85FF59]",
        "bg-neutral7 border-secondary1",
      ];
    }
    if (theme === "dark") {
      return [
        "bg-neutral6 border-neutral6",
        "bg-neutral6 border-[#2A85FF59]",
        "bg-neutral6 border-secondary1",
      ];
    }
    if (theme === "white") {
      return [
        "bg-white border-white",
        "bg-white border-[#2A85FF59]",
        "bg-white border-secondary1",
      ];
    }
    return [
      "bg-neutral2 border-neutral2",
      "bg-white border-[#2A85FF59]",
      "bg-white border-secondary1",
    ];
  }, [theme]);

  return (
    <div
      className={twMerge(
        "inline-flex flex-col gap-3",
        grow && "w-full",
        className,
      )}
    >
      <FormLabel
        id={id.current}
        error={error}
        label={label}
        labelTooltip={labelTooltip}
        dark={["black", "dark"].includes(theme)}
      ></FormLabel>
      <div className="flex w-full rounded-lg md:rounded-xl h-[40px] md:h-[48px]">
        {children && (
          <div
            className={twMerge(
              "border-t-2 border-b-2 border-l-2 flex items-center px-4 rounded-l-lg md:rounded-l-xl text-neutral4 text-[13px] leading-[18px] md:text-[15px] md:leading-[24px] font-bold",
              "bg-neutral2",
            )}
          >
            {children}
          </div>
        )}
        <div
          className={twMerge(
            "flex items-center border-l-2 flex-1 transition-colors h-[40px] md:h-[48px] gap-2 border-t-2 border-b-2 border-r-2 px-3 rounded-r-md md:rounded-r-xl",
            children
              ? error
                ? bg[2]
                : "border-neutral3"
              : error
                ? bg[2]
                : active
                  ? bg[1]
                  : bg[0],
            !children && "rounded-l-lg md:rounded-l-xl",
          )}
        >
          {icon && (
            <Icon
              id={icon}
              color="var(--neutral4)"
            ></Icon>
          )}
          <input
            value={value ?? ""}
            onInput={(e) => {
              if (onInput) {
                onInput((e.target as HTMLInputElement).value);
              }
            }}
            id={id.current}
            className={twMerge(
              "text-[16px] h-[36px] md:h-[44px] w-full bg-inherit outline-none placeholder-[#9A9FA5] font-semibold",
              ["white", "default"].includes(theme)
                ? "text-neutral7"
                : "text-neutral2",
            )}
            type={type}
            placeholder={placeholder}
            {...inputProps}
            onFocus={() => {
              setActive(true);
            }}
            onBlur={() => {
              setActive(false);
            }}
          ></input>
        </div>
      </div>
    </div>
  );
}
