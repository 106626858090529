import React from "react";
import Button from "./Button";
import { twMerge } from "tailwind-merge";

interface Props {
  state?: "success" | "warning";
  title: string;
  text?: string;
  button?: string;
  onClick?: () => void;
  className?: string;
}

export default function Result({
  state,
  title,
  text,
  button,
  onClick,
  className,
}: Props) {
  return (
    <div className={twMerge("flex flex-col gap-5 items-center", className)}>
      {state === "success" && (
        <div className="w-32 h-32 rounded-full bg-secondary4 flex items-center justify-center text-[48px]">
          🎉
        </div>
      )}
      {(title || text) && (
        <div className="flex flex-col gap-2 items-center text-center">
          {title && (
            <div className="text-[20px] leading-[32px] font-semibold whitespace-pre">
              {title}
            </div>
          )}
          {text && (
            <div className="text-[15px] leading-[24px] font-semibold text-neutral4">
              {text}
            </div>
          )}
        </div>
      )}
      {button && <Button onClick={onClick}>{button}</Button>}
    </div>
  );
}
