import React from "react";
import Tooltip from "./Tooltip";
import { twMerge } from "tailwind-merge";

interface Props {
  id?: string;
  label?: string;
  labelTooltip?: string;
  error?: string | boolean;
  dark?: boolean;
}

export default function FormLabel({
  id,
  label,
  labelTooltip,
  error,
  dark,
}: Props) {
  return label || typeof error === "string" ? (
    <label
      className="inline-flex justify-between"
      htmlFor={id}
    >
      {label &&
        (labelTooltip ? (
          <Tooltip
            iconColor={error ? "var(--primary3)" : "#9A9FA5"}
            content={labelTooltip}
          >
            <div
              className={twMerge(
                "text-[14px] leading-[20px] font-semibold",
                dark ? "text-neutral3" : "text-neutral5",
              )}
            >
              {label}
            </div>
          </Tooltip>
        ) : (
          <div
            className={twMerge(
              "text-[14px] leading-[20px] font-semibold",
              dark ? "text-neutral3" : "text-neutral5",
            )}
          >
            {label}
          </div>
        ))}
      {typeof error === "string" && (
        <div className="text-primary3 text-[12px] font-medium">{error}</div>
      )}
    </label>
  ) : null;
}
