import React from "react";
import Title from "./Title";
import Button from "./Button";
import Icon from "./Icon";
import Label from "./Label";

interface Option {
  id: string;
  label: string;
}

interface Props {
  title: string;
  subtitle: string;
  price: number;
  options: Option[];
  isPrimary?: boolean;
  oldPrice?: number;
  discountPercent?: number;
  titleColor: string;
  onClick?: () => void;
  loading?: boolean;
}

export default function PricingCard({
  title,
  subtitle,
  price,
  options,
  isPrimary,
  oldPrice,
  discountPercent,
  titleColor,
  loading,
  onClick,
}: Props) {
  return (
    <div className="flex flex-col gap-6 h-full">
      <Title color={titleColor}>
        <div className="flex gap-4 items-center">
          <div className="flex flex-col gap-1">
            {title}
            <div className="text-[13px] leading-[16px] font-medium text-neutral4">
              {subtitle}
            </div>
          </div>
          {discountPercent && (
            <Label
              color="var(--secondary4)"
              nowrap
            >
              Скидка {discountPercent}%
            </Label>
          )}
        </div>
      </Title>
      <div className="h-[1px] bg-neutral3 w-full"></div>
      <div className="flex gap-6 items-center">
        <div className="text-[64px] font-semibold leading-[64px] text-neutal7">
          {price}₽
        </div>
        {oldPrice && (
          <div className="text-[24px] leading-[32px] font-semibold text-neutral4 line-through">
            {oldPrice}₽
          </div>
        )}
      </div>
      <div className="h-[1px] bg-neutral3 w-full"></div>
      {options.map((option) => (
        <div
          className="flex gap-4 items-center"
          key={option.id}
        >
          <Icon
            id="check"
            color="var(--secondary4)"
          ></Icon>
          {option.label}
        </div>
      ))}
      <div className="mt-auto pt-3">
        <Button
          theme={isPrimary ? "primary" : "secondary"}
          grow
          onClick={onClick}
          disabled={loading}
        >
          Подписаться
        </Button>
      </div>
    </div>
  );
}
