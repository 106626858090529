import React from "react";
import { twMerge } from "tailwind-merge";
import Image from "./Image";

interface Props {
  size?: number;
  src?: string | null;
  dark?: boolean;
  onClick?: () => any;
  className?: string;
}

export default function Avatar({
  src,
  size = 30,
  dark,
  onClick,
  className,
}: Props) {
  return src ? (
    <Image
      alt="Avatar"
      src={src}
      className={twMerge(
        "rounded-full object-cover",
        onClick && "cursor-pointer",
        className,
      )}
      style={{ height: size + "px", width: size + "px", minWidth: size + "px" }}
      onClick={onClick}
    ></Image>
  ) : (
    <div
      className={twMerge(
        "rounded-full",
        dark ? "bg-neutral2" : "bg-neutral3",
        onClick && "cursor-pointer",
      )}
      style={{ height: size + "px", width: size + "px", minWidth: size + "px" }}
      onClick={onClick}
    ></div>
  );
}
