import { BREAKPOINTS } from "../constants/breakpoints";

function hideMobileButton() {
  if (window.innerWidth < BREAKPOINTS.tablet) {
    const el = document.querySelector(".__jivoMobileButton") as HTMLDivElement;
    if (el) {
      el.style.display = "none";
    }
  }
}

export function setContactEmail(email: string) {
  // @ts-ignore
  const widget = window?.jivo_api;
  if (!widget) {
    // @ts-ignore
    window.jivo_onLoadCallback = () => {
      setContactEmail(email);
      hideMobileButton();
    };
  } else {
    widget.setContactInfo({
      email,
    });
  }
}

export function openSupportChat() {
  // @ts-ignore
  const widget = window?.jivo_api;
  if (widget) {
    widget.open();
  }
}
