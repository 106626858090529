import React from "react";
import Avatar from "./Avatar";
import { twMerge } from "tailwind-merge";
import { CreatorPageTheme, Links } from "../creator/CreatorPage";
import ProfileIconList from "./ProfileIconList";

interface Props {
  avatar?: string;
  name: string;
  bio?: string;
  dark?: boolean;
  className?: string;
  theme?: CreatorPageTheme;
  links?: Links;
  disableEvents?: boolean;
}

export default function Profile({
  theme = null,
  avatar,
  name,
  bio,
  dark,
  className,
  links,
  disableEvents,
}: Props) {
  return (
    <div
      className={twMerge(
        "pt-6 px-6 flex items-center gap-3",
        theme === "dark" ? "" : "flex-col",
        className,
      )}
    >
      <Avatar
        src={avatar}
        size={theme === "dark" ? 84 : 96}
        dark={dark}
        className={theme === "dark" ? "self-start" : ""}
      ></Avatar>
      <div
        className={twMerge(
          "flex flex-col items-center",
          theme === "dark" && "items-start",
        )}
      >
        <div className={twMerge(dark && "text-neutral2")}>{name}</div>
        <div
          className={twMerge(
            "text-[#9A9FA5] whitespace-pre-wrap",
            theme === "dark" ? "" : "text-center",
          )}
        >
          {bio || "Ваше био"}
        </div>
        {links && (
          <ProfileIconList
            theme={theme ? "dark" : undefined}
            className={theme === "dark" ? "mt-2" : "mt-3"}
            links={links}
            disabled={disableEvents}
          />
        )}
      </div>
    </div>
  );
}
