import React, { ReactNode, useState } from "react";
import Popover from "../../ui/Popover";
import { useBreakpoint } from "use-breakpoint";
import { BREAKPOINTS } from "../../../constants/breakpoints";
import { Link } from "react-router-dom";

interface Props {
  children: ReactNode;
  items: { to: string; id: string; label: string; counter?: number }[];
  onItemClick?: (id: string) => void;
}

export default function MenuItemPopover({
  children,
  items,
  onItemClick,
}: Props) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [open, setOpen] = useState(false);
  return (
    <Popover
      open={open}
      setOpen={setOpen}
      className="flex-1 w-full inline-flex justify-center items-center"
      placement={breakpoint === "tablet" ? "right" : "top"}
      content={
        <div className="flex flex-col gap-3 cursor-pointer transition-colors duration-500 py-2 px-3">
          {items.map(({ to, id, label, counter }) => (
            <Link
              to={to}
              key={id}
              className="flex items-center justify-between p-1 text-neutral5"
              onClick={() => {
                setOpen(false);
                onItemClick && onItemClick(id);
              }}
            >
              {label}
              {Boolean(counter) && (
                <div className="flex w-[24px] h-[24px] rounded-md bg-[#FABB97] justify-center items-center">
                  {counter}
                </div>
              )}
            </Link>
          ))}
        </div>
      }
    >
      {children}
    </Popover>
  );
}
