import React, { ReactNode } from "react";
import Icon from "./Icon";
import { AnimatePresence, motion } from "framer-motion";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock-upgrade";
import { useEffect } from "react";

interface Props {
  children: ReactNode;
  onClose?: () => void;
  visible: boolean;
  afterClose?: () => void;
  notClosable?: boolean;
}

export default function Modal({
  visible,
  children,
  onClose,
  afterClose,
  notClosable,
}: Props) {
  useEffect(() => {
    if (visible) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
  }, [visible]);

  return (
    <>
      <div className="fixed top-0 right-0 bottom-0 left-0 h-full w-full z-50 flex justify-center items-center flex-col overflow-y-auto">
        <AnimatePresence onExitComplete={afterClose}>
          {visible && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.9 }}
                exit={{ opacity: 0 }}
                className="fixed top-0 right-0 bottom-0 left-0 bg-neutral2 opacity-90"
              ></motion.div>
              <motion.div
                initial={{
                  opacity: 0,
                  scale: 1.05,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  opacity: 0,
                  scale: 1.05,
                }}
                className="absolute top-0 md:pt-6 z-30 md:pb-6 w-full flex min-h-full"
              >
                <div className="opacity-100 p-6 md:rounded-[16px] bg-neutral1 flex flex-col max-w-[560px] md:w-[90%] mx-auto my-auto">
                  {!notClosable && onClose && (
                    <div
                      onClick={onClose}
                      className="cursor-pointer ml-auto w-[36px] h-[36px] rounded-full bg-neutral2 flex items-center justify-center"
                    >
                      <Icon
                        id="close"
                        size={20}
                      ></Icon>
                    </div>
                  )}
                  {children}
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
