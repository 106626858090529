import React, { useEffect, useState } from "react";
import CreatorPage, {
  CreatorPageItem,
  CreatorPageTheme,
  Profile,
  getItemMeta,
} from "../../components/creator/CreatorPage";
import { twMerge } from "tailwind-merge";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import toast from "react-hot-toast";
import { mapItemFromServer } from "../../helpers/mappers";
import { trackEvent } from "../../lib/ym";

export default function Creator() {
  const params = useParams();
  const [page, setPage] = useState("main");
  const navigate = useNavigate();

  const [creatorPage, setCreatorPage] = useState<{
    id: string | null;
    profile: Profile;
    theme: CreatorPageTheme;
    items: CreatorPageItem[];
  }>({
    id: null,
    profile: {
      nickname: null,
      name: null,
      bio: null,
      avatar: null,
      links: {
        vk: null,
        tg: null,
        yt: null,
        tt: null,
        site: null,
      },
    },
    theme: null,
    items: [],
  });
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);

  function onChangePath(path: string) {
    setPage(path);
    if (path !== "main") {
      const itemId = Number(path.split("content/")[1]);
      const item = creatorPage.items.find((iterated) => iterated.id === itemId);
      if (item) {
        trackEvent("creator_page_click_item", {
          ...getItemMeta(item),
          nickname: params.id,
        });
      }
    }
  }

  async function handleGetCreatorPage(id: string) {
    setLoadingUser(true);
    setLoadingItems(true);
    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("nickname", id);
    if (error || !data || !data.length) {
      trackEvent("creator_page_view_error", {
        url: id,
      });
      navigate("/not-found");
      toast.error("Ошибка при загрузке страницы");
      return;
    }
    trackEvent("creator_page_view", {
      nickname: id,
    });
    const user = data[0];
    setCreatorPage({
      id: user.id,
      profile: {
        name: user.name,
        bio: user.bio,
        avatar: user.avatar,
        nickname: user.nickname,
        links: {
          vk: user.vk_link,
          yt: user.youtube_link,
          tg: user.telegram_link,
          tt: user.tiktok_link,
          site: user.site_url,
        },
      },
      theme: user.theme,
      items: [],
    });
    setLoadingUser(false);
    const { data: itemsData, error: itemsError } = await supabase
      .from("page_items")
      .select()
      .eq("user_id", user.id);

    if (itemsError || !itemsData) {
      toast.error("Ошибка при загрузке страницы");
      setLoadingItems(false);
      return;
    }
    const resultItems = itemsData
      .filter((item) => {
        return !item.is_deleted && item.is_published;
      })
      .map((item) => {
        return mapItemFromServer(item);
      });
    const orderedItems = resultItems.sort((a, b) => {
      return user.items.indexOf(a.id) - user.items.indexOf(b.id);
    });
    setCreatorPage((data) => {
      return {
        ...data,
        items: orderedItems,
      };
    });
    setLoadingItems(false);
  }

  useEffect(() => {
    if (!params?.id) {
      navigate("/not-found");
      return;
    }
    handleGetCreatorPage(params.id);
  }, []);

  return (
    <div
      className={twMerge(
        "w-full h-full",
        creatorPage?.theme && ["dark", "black"].includes(creatorPage.theme)
          ? "bg-neutral8"
          : "bg-neutral1",
      )}
    >
      <div className={twMerge("mx-auto max-w-[480px] md:py-12 h-full")}>
        <div className="flex flex-col gap-16 h-full">
          <CreatorPage
            userId={creatorPage.id}
            theme={creatorPage.theme}
            profile={creatorPage.profile}
            items={creatorPage.items}
            className="md:rounded-[20px]"
            path={page}
            onChangePath={onChangePath}
            onSubmit={() => {
              const itemId = Number(page.split("content/")[1]);
              const item = creatorPage.items.find(
                (iterated) => iterated.id === itemId,
              );
              if (item) {
                trackEvent("creator_page_submit_form", {
                  ...getItemMeta(item),
                  nickname: params.id,
                });
              }
            }}
            hasStoreLabel
            isItemsLoading={loadingItems}
            isUserLoading={loadingUser}
            onCreatorStoreButtonClick={() => {
              trackEvent("creator_page_store_button", {
                nickname: params.id,
              });
            }}
          ></CreatorPage>
        </div>
      </div>
    </div>
  );
}
