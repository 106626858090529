import React from "react";
import { twMerge } from "tailwind-merge";
import { CreatorPageTheme, Links } from "../creator/CreatorPage";
import ProfileIconList from "./ProfileIconList";

interface Props {
  avatar?: string;
  name: string;
  bio?: string;
  dark?: boolean;
  className?: string;
  theme: CreatorPageTheme;
  links?: Links;
  maxWidth?: number;
}

export default function GradientProfile({
  avatar,
  name,
  bio,
  className,
  links,
  maxWidth,
}: Props) {
  return (
    <div
      className={twMerge(
        "w-full flex flex-col items-center gap-6 relative",
        className,
      )}
    >
      <div
        className="absolute top-0 flex flex-col w-full -mx-6 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${avatar})`,
          height: "100vw",
          maxHeight: maxWidth ? maxWidth + "px" : "500px",
        }}
      >
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full bg-gradient-to-t from-[#111315] to-transparent"></div>
      </div>
      <div
        className="w-full z-10"
        style={{
          height: "75vw",
          maxHeight: maxWidth ? 0.75 * maxWidth : "400px",
        }}
      ></div>
      <div
        className={twMerge(
          "relative px-6 flex flex-col items-center gap-1 z-10 mb-2",
        )}
      >
        <div
          className={twMerge(
            "text-[24px] leading-[32px] font-semibold text-white",
          )}
        >
          {name}
        </div>
        <div
          className={twMerge(
            "text-[#9A9FA5] text-[15px] leading-[24px] font-medium text-center whitespace-pre-wrap",
          )}
        >
          {bio || "Ваше био"}
        </div>
        {links && (
          <ProfileIconList
            theme="dark"
            links={links}
            className="mt-3"
          ></ProfileIconList>
        )}
      </div>
    </div>
  );
}
