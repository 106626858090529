import { Reorder, useDragControls } from "framer-motion";
import React, { useState } from "react";
import Icon from "../../components/ui/Icon";
import { twMerge } from "tailwind-merge";
import Label from "../../components/ui/Label";
import { CreatorPageItem } from "../../components/creator/CreatorPage";
import Popover from "../../components/ui/Popover";

export interface GrabItemType {
  id: number;
  price?: number;
  imgSrc?: string;
  title?: string;
  subtitle?: string;
  published: boolean;
  isLink: boolean;
}

interface Props {
  value: GrabItemType;
  onDeleteItem: () => void;
  onEditItem: () => void;
  onClick: () => void;
  onTriggerClick: () => void;
}

function getItemImage(item: CreatorPageItem) {
  if (item.type === "link") {
    return item.link?.icon ?? undefined;
  }
  if (item.content?.cover) {
    return item.content.cover;
  }
  return item.type === "button"
    ? item.button?.icon ?? undefined
    : item.card?.icon ?? undefined;
}

function getItemTitle(item: CreatorPageItem) {
  if (item.type === "link") {
    return item.link?.text ?? undefined;
  }
  if (item.content?.title) {
    return item.content.title;
  }
  return item.type === "button"
    ? item.button?.text ?? undefined
    : item.card?.text ?? undefined;
}

export function getGrabItemsFromCreatorPageItems(
  items: CreatorPageItem[],
  order: number[],
): GrabItemType[] {
  return items
    .sort((a, b) => {
      return order.indexOf(a.id) - order.indexOf(b.id);
    })
    .map((item) => {
      return {
        id: item.id,
        type: item.type,
        price: item.type === "card" ? item.card.price : undefined,
        imgSrc: getItemImage(item),
        title: getItemTitle(item),
        subtitle:
          item.type !== "link"
            ? item.content?.description ?? undefined
            : undefined,
        published: item.published,
        isLink: item.type === "link",
      };
    });
}

export default function GrabItem({
  value,
  onTriggerClick,
  onDeleteItem,
  onEditItem,
  onClick,
}: Props) {
  const controls = useDragControls();
  const [dragging, setDragging] = useState(false);
  const { imgSrc, title, subtitle, price, published, isLink } = value;
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  return (
    <Reorder.Item
      value={value}
      dragListener={false}
      dragControls={controls}
      className={twMerge(
        "cursor-pointer flex items-center bg-neutral1 rounded-lg py-2 px-4 min-w-0 h-[96px]",
        dragging && "select-none",
      )}
      onClick={() => {
        onClick();
      }}
      style={{ touchAction: "none" }}
    >
      <div
        className={twMerge(
          "mr-3",
          dragging ? "cursor-grabbing" : "cursor-grab",
        )}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onPointerDown={(e) => {
          setDragging(true);
          controls.start(e);
        }}
        onPointerUp={() => {
          setDragging(false);
        }}
      >
        <Icon
          id="drag"
          color="var(--neutral4-shade)"
        ></Icon>
      </div>
      {imgSrc ? (
        <img
          src={imgSrc}
          className="w-[56px] min-w-[56px] h-[56px] rounded-lg object-cover mr-5"
        ></img>
      ) : (
        <div className="w-[56px] min-w-[56px] h-[56px] rounded-lg object-cover mr-5 bg-neutral2"></div>
      )}
      <div className="flex md:items-center flex-col md:flex-row min-w-0 flex-1 gap-1 md:gap-0">
        <div className="flex-1 flex flex-col gap-1 mr-8 min-w-0 ">
          <div className="min-w-0 truncate">{title}</div>
          <div className="hidden md:block min-w-0 truncate text-neutral4-shade">
            {subtitle}
          </div>
        </div>
        <div className="flex flex-col mr-3 gap-2 xl:flex-row">
          {isLink ? (
            <Label size="sm">Ссылка</Label>
          ) : (
            <Label size="sm">{price ? price + "₽" : "Бесплатно"}</Label>
          )}
          {!published && (
            <Label
              className="hidden md:flex"
              size="sm"
            >
              Черновик
            </Label>
          )}
        </div>
      </div>

      <Popover
        content={
          <div>
            <div
              className="flex px-6 py-3 hover:bg-neutral2 rounded-t-md cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onEditItem();
                setIsPopoverOpened(false);
              }}
            >
              Редактировать
            </div>
            <div
              className="flex px-6 py-3 hover:bg-neutral2 rounded-b-md cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteItem();
                setIsPopoverOpened(false);
              }}
            >
              Удалить
            </div>
          </div>
        }
        open={isPopoverOpened}
        setOpen={setIsPopoverOpened}
        onRefClick={() => {
          onTriggerClick();
        }}
      >
        <Icon
          className="cursor-pointer"
          id="more-vertical"
          color="var(--neutral4-shade)"
        ></Icon>
      </Popover>
    </Reorder.Item>
  );
}
