import NiceModal, { useModal } from "@ebay/nice-modal-react";
import React from "react";
import Modal from "../ui/Modal";
import Title from "../ui/Title";
import Icon, { IconList } from "../ui/Icon";
import { useBreakpoint } from "use-breakpoint";
import { BREAKPOINTS } from "../../constants/breakpoints";

interface CardItemProps {
  id: string;
  icon: IconList;
  title: string;
  description: string;
  onClick: () => void;
}

function CardItem({ id, icon, title, description, onClick }: CardItemProps) {
  const [hovered, setHovered] = React.useState(false);
  return (
    <div
      className="cursor-pointer flex p-3 rounded-xl border border-neutral3 items-center hover:bg-neutral2 transition-colors group"
      onClick={onClick}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      key={id}
    >
      <div className="flex items-center gap-4 w-full">
        <div className="min-w-[48px] w-[48px] h-[48px] rounded-full border-[2px] border-neutral3 flex items-center justify-center bg-white">
          <Icon
            id={icon}
            color="var(--neutral7)"
          ></Icon>
        </div>
        <div className="flex flex-col gap-1 mr-auto">
          <div className="leading-[24px] text-neutral7">{title}</div>
          <div className="hidden md:block text-neutral4 leading-[16px] text-[13px]">
            {description}
          </div>
        </div>
        <Icon
          className="hidden md:inline-block"
          id="chevron-forward"
          color={!hovered ? "var(--neutral4-shade)" : "var(--neutral7)"}
        ></Icon>
      </div>
    </div>
  );
}

interface Props {
  onContinue: (itemType: "product" | "link") => void;
  onCancel: () => void;
}

export default NiceModal.create(({ onContinue, onCancel }: Props) => {
  const modal = useModal("item-type-modal");
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <Modal
      visible={modal.visible}
      onClose={() => modal.hide()}
      afterClose={() => modal.remove()}
      notClosable
    >
      <div className="flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <Title color="var(--secondary1)">Создать продукт или ссылку?</Title>
          <div
            onClick={() => {
              modal.hide();
              onCancel();
            }}
            className="cursor-pointer ml-auto min-w-[36px] w-[36px] h-[36px] rounded-full bg-neutral2 flex items-center justify-center"
          >
            <Icon
              id="close"
              size={20}
            ></Icon>
          </div>
        </div>
        <div className="flex w-full h-[1px] bg-neutral3"></div>
        <div className="flex flex-col gap-2">
          <CardItem
            id="form"
            icon="store"
            title={breakpoint === "mobile" ? "Карточка продукта" : "Продукт"}
            description="Карточка или кнопка продукта с формой заявки"
            onClick={() => {
              onContinue("product");
              modal.hide();
            }}
          />
          <CardItem
            id="link"
            icon="link"
            title={breakpoint === "mobile" ? "Кнопка-ссылка" : "Ссылка"}
            description="Кнопка-ссылка на внешнюю страницу"
            onClick={() => {
              onContinue("link");
              modal.hide();
            }}
          />
        </div>
      </div>
    </Modal>
  );
});
