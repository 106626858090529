import React from "react";
import Icon, { IconList } from "./Icon";
import { twMerge } from "tailwind-merge";

interface Props {
  link: string;
  icon: IconList;
  theme?: "default" | "dark";
  disabled?: boolean;
}

export default function ProfileIcon({
  link,
  icon,
  theme = "default",
  disabled,
}: Props) {
  return (
    <a
      href={link}
      target="_blank"
      className={twMerge(disabled && "pointer-events-none")}
    >
      <Icon
        id={icon}
        color={theme === "default" ? "#9A9FA5" : "#1A1D1F"}
        hoverColor="#33383F"
        size={32}
      ></Icon>
    </a>
  );
}
