import React, { useState } from "react";
import Button from "../../../components/ui/Button";
import Title from "../../../components/ui/Title";
import Input from "../../../components/ui/Input";
import Textarea from "../../../components/ui/Textarea";
import FormLabel from "../../../components/ui/FormLabel";
import Checkbox from "../../../components/ui/Checkbox";
import useRestoreScroll from "../../../hooks/useRestoreScroll";
import { useOutletContext, useParams } from "react-router-dom";
import { CreateItemContextType } from "./CreateItem";
import toast from "react-hot-toast";
import { useAuth } from "../../../providers/AuthProvider";
import { useShallow } from "zustand/react/shallow";
import { useCreatorPage } from "../../../store/useCreatorPage";
import { supabase } from "../../../lib/supabase";
import { trackEvent } from "../../../lib/ym";
import { useDebounceFunction } from "../../../hooks/useDebounce";
import InputUpload from "../../../components/ui/InputUpload";

export default function CreateCardItem() {
  const params = useParams();
  const { user } = useAuth();
  const { content, setContent, itemType, onSubmitContent } =
    useOutletContext<CreateItemContextType>();

  useRestoreScroll();

  const { isContentCreating, isContentUpdating } = useCreatorPage(
    useShallow((state) => {
      return {
        isContentCreating: state.isContentCreating,
        isContentUpdating: state.isContentUpdating,
      };
    }),
  );

  const [isFileLoading, setIsFileLoading] = useState(false);
  async function handleUploadFile(file: File) {
    console.log(`${user?.id}`, file);
    setIsFileLoading(true);
    const filePath = `${user?.id}/${Date.now()}`;
    const { error: uploadError } = await supabase.storage
      .from("images")
      .upload(filePath, file, {
        upsert: true,
      });
    setIsFileLoading(false);
    if (uploadError) {
      if ((uploadError as any)?.statusCode === 413) {
        toast.error("Файл слишком большой");
      } else {
        toast.error("Ошибка загрузки файла");
      }
      console.log({ uploadError });
      return;
    }
    const { data } = await supabase.storage
      .from("images")
      .getPublicUrl(filePath);

    trackEvent("item_upload_content_icon", {
      item_type: itemType,
    });
    setContent({
      ...content,
      cover: data.publicUrl,
    });
  }

  const trackTitle = useDebounceFunction(() => {
    trackEvent("item_input", {
      input: "content_title",
    });
  });
  const trackDescription = useDebounceFunction(() => {
    trackEvent("item_input", {
      input: "content_description",
    });
  });
  const trackButtonText = useDebounceFunction(() => {
    trackEvent("item_input", {
      input: "content_button",
    });
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="p-6 rounded-lg bg-neutral1 flex flex-col gap-4 md:gap-8">
          <Title
            color="var(--secondary3)"
            number={2}
          >
            Обложка
          </Title>
          <InputUpload
            loading={isFileLoading}
            accept="image/jpeg,image/png,image/svg+xml,image/webp"
            onInput={handleUploadFile}
            onDelete={() => {
              setContent({
                ...content,
                cover: null,
              });
            }}
          >
            Иконка
          </InputUpload>
          <Input
            grow
            label="Заголовок"
            value={content.title}
            onInput={(title) => {
              setContent({
                ...content,
                title: title || null,
              });
              trackTitle();
            }}
          ></Input>
          <Textarea
            grow
            label="Описание"
            value={content.description}
            onInput={(description) => {
              setContent({
                ...content,
                description: description || null,
              });
              trackDescription();
            }}
          ></Textarea>
        </div>
      </div>
      <div className="p-6 rounded-lg bg-neutral1 flex flex-col gap-8">
        <Title
          color="var(--secondary2)"
          number={3}
        >
          Форма заявки
        </Title>
        <div className="text-neutral5 font-medium">
          Настрой поля формы, которые необходимо будет заполнить клиентам для
          оформления заявки
        </div>
        <div className="flex flex-col gap-3">
          <FormLabel label="Доступные поля"></FormLabel>
          <div className="grid grid-cols-2 gap-4">
            <Checkbox
              value={content.fields.name}
              onInput={(name) => {
                setContent({
                  ...content,
                  fields: {
                    ...content.fields,
                    name,
                  },
                });
              }}
              label="Имя"
            ></Checkbox>
            <Checkbox
              value={content.fields.phone}
              onInput={(phone) => {
                setContent({
                  ...content,
                  fields: {
                    ...content.fields,
                    phone,
                  },
                });
              }}
              label="Телефон"
            ></Checkbox>
            <Checkbox
              value={content.fields.mail}
              onInput={(mail) => {
                setContent({
                  ...content,
                  fields: {
                    ...content.fields,
                    mail,
                  },
                });
              }}
              label="Почта"
            ></Checkbox>
            <Checkbox
              value={content.fields.comment}
              onInput={(comment) => {
                setContent({
                  ...content,
                  fields: {
                    ...content.fields,
                    comment,
                  },
                });
              }}
              label="Комментарии"
            ></Checkbox>
          </div>
        </div>

        <Input
          grow
          label="Текст кнопки"
          value={content.button}
          onInput={(button) => {
            setContent({
              ...content,
              button: button || null,
            });
            trackButtonText();
          }}
        ></Input>
      </div>

      <div className="mt-6 flex justify-between">
        <Button
          theme="secondary"
          icon="left"
          iconSize={18}
          to={`/items/${params.id}/appearance`}
          onClick={() => {
            trackEvent("item_click_to_appearance");
          }}
        >
          Внешний вид
        </Button>
        <Button
          disabled={isContentCreating || isContentUpdating}
          onClick={() => {
            onSubmitContent();
          }}
        >
          Опубликовать
        </Button>
      </div>
    </div>
  );
}
