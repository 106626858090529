import { Link, Outlet } from "react-router-dom";
import Icon from "../../components/ui/Icon";
import Title from "../../components/ui/Title";

export default function AuthLayout() {
  return (
    <div className="h-full flex">
      <div className="p-12 h-full hidden lg:flex flex-col bg-neutral2">
        <a
          href="https://home.creatorstore.pro/"
          className="mb-auto absolute"
        >
          <img
            src="/logo.svg"
            alt="Logo"
          ></img>
        </a>
        <div className="my-auto flex flex-col justify-center gap-8">
          <img
            className="w-32"
            src="/auth-image.png"
            alt="Illustration"
          ></img>
          <Title>На бесплатном тарифе вы сможете</Title>
          <div className="flex items-center gap-3">
            <Icon
              id="check-circle"
              color="var(--primary2)"
            ></Icon>
            <div className="text-neutral4 font-semibold text-[14px] leading-[20px]">
              Создать свою страницу c продуктами
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Icon
              id="check-circle"
              color="var(--primary2)"
            ></Icon>
            <div className="text-neutral4 font-semibold text-[14px] leading-[20px]">
              Опубликовать страницу
            </div>
          </div>
          <div className="flex items-center gap-3">
            <Icon
              id="check-circle"
              color="var(--primary2)"
            ></Icon>
            <div className="text-neutral4 font-semibold text-[14px] leading-[20px]">
              Отслеживать заявки от клиентов
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full items-center flex-1 justify-center">
        <a
          href="https://home.creatorstore.pro/"
          className="lg:hidden self-start flex max-w-96 w-full mx-auto mb-6"
        >
          <img
            className="w-16"
            src="/logo.svg"
            alt="Logo"
          ></img>
        </a>
        <Outlet></Outlet>
      </div>
    </div>
  );
}
