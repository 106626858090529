import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Page from "../../components/layout/Page";
import Title from "../../components/ui/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";
import "swiper/css";
import PreviewWrapper from "../../components/creator/PreviewWrapper";
import { twMerge } from "tailwind-merge";
import CreatorPage, {
  CreatorPageTheme,
} from "../../components/creator/CreatorPage";
import Button from "../../components/ui/Button";
import { useCreatorPage } from "../../store/useCreatorPage";
import { useShallow } from "zustand/react/shallow";
import { supabase } from "../../lib/supabase";
import { useAuth } from "../../providers/AuthProvider";
import toast from "react-hot-toast";
import { trackEvent } from "../../lib/ym";
import BottomPanel from "../../components/ui/BottomPanel";

const options = [
  {
    id: null,
    label: "Классический светлый",
  },
  {
    id: "dark",
    label: "Классический темный",
  },
  {
    id: "sand",
    label: "Пляжный песок",
  },
  {
    id: "black",
    label: "Темный градиент",
  },
  {
    id: "violet",
    label: "Фоновая картинка",
  },
];

export default function Design() {
  const { user, isSubscriptionActive, isProfileLoading } = useAuth();
  const swiper = useRef<SwiperType | null>(null);
  const {
    theme,
    setTheme,
    items,
    profile,
    fetchItems,
    isItemsInvalidated,
    isItemsLoading,
  } = useCreatorPage(
    useShallow((state) => ({
      theme: state.theme,
      items: state.items,
      profile: state.profile,
      setTheme: state.setTheme,
      fetchItems: state.fetchItems,
      isItemsInvalidated: state.isItemsInvalidated,
      isItemsLoading: state.isItemsLoading,
    })),
  );
  const [cursor, setCursor] = useState(0);
  const save = useCallback(async () => {
    if (!user) {
      return;
    }
    trackEvent("design_click_save", {
      theme: options[cursor]?.label,
      order: cursor,
    });
    const { data, error } = await supabase
      .from("users")
      .update({ theme: options[cursor]?.id ?? null })
      .eq("id", user.id)
      .select();
    console.log({ data, error });
    if (error) {
      toast("Ошибка при сохранении темы. Попробуйте снова");
    } else {
      setTheme((options[cursor]?.id || null) as CreatorPageTheme);
    }
  }, [setTheme, cursor, user]);

  const savedThemeIndex = useMemo(() => {
    return options.findIndex((el) => el.id === theme) || 0;
  }, [theme]);

  useEffect(() => {
    if (!isProfileLoading) {
      setCursor(savedThemeIndex);
      swiper.current?.slideToLoop(savedThemeIndex);
    }
  }, [isProfileLoading]);

  useEffect(() => {
    if (!isItemsLoading && isItemsInvalidated && user?.id) {
      fetchItems(user.id).then((items) => {
        if (!items) {
          toast.error(
            "Ошибка при загрузке страницы. Перезагрузите страницу или попробуйте позже",
          );
        }
      });
    }
  }, [isItemsInvalidated, user?.id]);

  const activeItems = useMemo(() => {
    return items.filter((item) => {
      return !item.deleted && item.published;
    });
  }, [items]);

  return (
    <Page className="bg-neutral2 min-h-full">
      <BottomPanel
        disableSidebar
        visible={cursor !== savedThemeIndex && !isProfileLoading}
      >
        <div className="text-[13px] leading-[16px]">
          <span className="font-semibold hidden md:inline">Стиль: </span>
          <span className="font-normal">{options[cursor].label}</span>
        </div>
        <Button onClick={save}>
          Сохранить <span className="hidden md:inline">изменения</span>
        </Button>
      </BottomPanel>

      <div className="flex mb-6 items-center justify-between">
        <Title>Настройка дизайна</Title>
        <div className="flex items-center gap-3">
          <Button
            icon="close"
            theme="secondary"
            to="/"
            onClick={() => {
              trackEvent("design_click_close");
            }}
          ></Button>
        </div>
      </div>
      <div className="-mx-10">
        {isProfileLoading ? (
          <div className="flex gap-10 justify-center items-center">
            <div className="h-[480px] min-w-[330px] animate-pulse bg-neutral1 rounded-xl"></div>
            <div className="h-[530px] min-w-[350px] animate-pulse bg-neutral1 rounded-xl"></div>
            <div className="h-[480px] min-w-[330px] animate-pulse bg-neutral1 rounded-xl"></div>
          </div>
        ) : (
          <Swiper
            spaceBetween={40}
            slidesPerView={"auto"}
            loop
            onRealIndexChange={({ realIndex }: any) => {
              setCursor(realIndex);
            }}
            onInit={(instance: SwiperType) => {
              swiper.current = instance;
              swiper.current.slideToLoop(0);
            }}
            className="w-full"
            style={{ overflowY: "unset" }}
            centeredSlides
          >
            {options.map((option, index) => (
              <SwiperSlide
                key={option.id}
                style={{ width: "fit-content" }}
                onClick={() => {
                  if (swiper.current) {
                    swiper.current.slideToLoop(index);
                    trackEvent("design_click_slide", {
                      theme: options[index].label,
                      order: index,
                    });
                  }
                }}
              >
                {({ isActive }) => {
                  return (
                    <div
                      className={twMerge(
                        "relative transform-gpu transition-all duration-500 cursor-pointer",
                        isActive
                          ? "scale-100"
                          : "scale-90 opacity-30 hover:opacity-100",
                      )}
                    >
                      <PreviewWrapper className="min-w-[340px] pointer-events-none">
                        <CreatorPage
                          maxWidth={352}
                          userId={user?.id ?? null}
                          theme={options[index].id as CreatorPageTheme}
                          profile={profile}
                          items={activeItems}
                          disableEvents
                        ></CreatorPage>
                      </PreviewWrapper>
                    </div>
                  );
                }}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div className="mx-auto mt-8 flex items-center gap-4">
        <Button
          icon="chevron-backward"
          theme="secondary"
          onClick={() => {
            const newIndex = cursor === 0 ? options.length - 1 : cursor - 1;
            setCursor(newIndex);
            if (swiper.current) swiper.current.slideToLoop(newIndex);
            trackEvent("design_click_navigation", {
              direction: "backward",
              order: newIndex,
              theme: options[newIndex].label,
            });
          }}
        ></Button>
        {options[cursor].label}
        <Button
          icon="chevron-forward"
          theme="secondary"
          onClick={() => {
            const newIndex = cursor === options.length - 1 ? 0 : cursor + 1;
            setCursor(newIndex);
            if (swiper.current) swiper.current.slideToLoop(newIndex);
            trackEvent("design_click_navigation", {
              direction: "forward",
              order: newIndex,
              theme: options[newIndex].label,
            });
          }}
        ></Button>
      </div>
    </Page>
  );
}
