import { Link } from "react-router-dom";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import Title from "../../components/ui/Title";

export default function MagicLinkPage() {
  return (
    <div className="w-96 flex flex-col gap-6">
      <Title size="lg">Войти через magic link</Title>
      <div className="flex flex-col gap-4">
        <Input
          type="email"
          placeholder="Почта"
        ></Input>
        <Button grow>Отправить ссылку</Button>
      </div>
      <div className="flex flex-col gap-4 items-center">
        <Link
          to="/auth/signup"
          className="text-neutral4 underline font-medium"
        >
          Нет аккаунта? Зарегистрироваться
        </Link>
        <Link
          to="/auth/recovery"
          className="text-neutral4 underline font-medium"
        >
          Восстановить пароль
        </Link>
        <Link
          to="/auth/login"
          className="text-neutral4 underline font-medium"
        >
          Войти через почту и пароль
        </Link>
      </div>
    </div>
  );
}
