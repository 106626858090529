import React from "react";
import TabItem from "./TabItem";
import { twMerge } from "tailwind-merge";
import Tooltip from "../Tooltip";

export interface Option {
  id: string;
  label: string;
  labelTooltip?: string;
}

export type TabsTheme = "white" | "gray";

interface Props {
  options: Option[];
  value: string;
  onInput: (id: string) => any;
  theme?: TabsTheme;
  className?: string;
}

export default function Tabs({
  options,
  value,
  onInput,
  theme = "white",
  className,
}: Props) {
  return (
    <div
      className={twMerge(
        "flex p-2 rounded-xl w-full gap-2 flex-row",
        theme === "white" ? "bg-white" : "bg-neutral2",
        className,
      )}
    >
      {options.map((option) => (
        <div
          className="flex-1 min-w-0"
          key={option.id}
        >
          <TabItem
            theme={theme}
            selected={option.id === value}
            onClick={() => {
              onInput(option.id);
            }}
          >
            <div className="flex gap-1">
              <div className="truncate min-w-0">{option.label}</div>
              {option.labelTooltip && (
                <Tooltip
                  content={option.labelTooltip}
                  iconColor="var(--neutral4)"
                ></Tooltip>
              )}
            </div>
          </TabItem>
        </div>
      ))}
    </div>
  );
}
