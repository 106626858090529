import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children?: ReactNode;
  className?: string;
  enableScroll?: boolean;
}

export default function PreviewWrapper({
  children,
  className,
  enableScroll,
}: Props) {
  return (
    <div
      className={twMerge(
        "overflow-x-hidden max-h-[600px] h-[600px] max-w-[360px] md:min-w-[360px] border-4 bg-neutral1 border-neutral5 rounded-[20px] flex-1",
        !enableScroll && "overflow-y-hidden",
        className,
      )}
    >
      {children}
    </div>
  );
}
