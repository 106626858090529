import {
  CreatorPageItem,
  CreatorPageItemType,
  LinkItem,
  LinkType,
} from "../components/creator/CreatorPage";
import { Content } from "../pages/store/create-item/CreateItem";

type ItemTypeServer = 1 | 2 | 3;

export function mapItemTypeToServer(
  itemType: CreatorPageItemType,
): ItemTypeServer {
  return itemType === "button" ? 1 : itemType === "card" ? 2 : 3;
}

export type CreatorPageItemServer = {
  id: number;
  item_type: ItemTypeServer;
  text: string | null;
  icon: string | null;
  description: string | null;
  button: string | null;
  price: number | null;
  user_id: string;
  is_published: boolean;
  is_deleted: boolean;
  content_title: string | null;
  content_description: string | null;
  content_button: string | null;
  content_cover: string | null;
  has_content_name: boolean;
  has_content_phone: boolean;
  has_content_mail: boolean;
  has_content_comment: boolean;
  created_at: string;
  updated_at: string;
  link: string | null;
  link_type: LinkType;
};

export function mapItemFromServer(
  item: CreatorPageItemServer,
): CreatorPageItem {
  if (item.item_type === 3) {
    return {
      id: item.id,
      type: "link",
      link: {
        text: item.text,
        icon: item.icon,
        link: item.link,
        linkType: item.link_type,
      },
      published: item.is_published,
      deleted: item.is_deleted,
      createdAt: new Date(item.created_at),
      updatedAt: new Date(item.updated_at),
    };
  }
  return item.item_type === 1
    ? {
        id: item.id,
        type: "button",
        button: {
          text: item.text,
          icon: item.icon,
        },
        content: mapContentFromServer(item),
        published: item.is_published,
        deleted: item.is_deleted,
        createdAt: new Date(item.created_at),
        updatedAt: new Date(item.updated_at),
      }
    : {
        id: item.id,
        type: "card",
        card: {
          text: item.text,
          icon: item.icon,
          price: item.price ?? 0,
          description: item.description,
          button: item.button,
        },
        content: mapContentFromServer(item),
        published: item.is_published,
        deleted: item.is_deleted,
        createdAt: new Date(item.created_at),
        updatedAt: new Date(item.updated_at),
      };
}

type ButtonPageItemServerSave = {
  item_type: 1;
  text: string | null;
  icon: string | null;
};

type CardPageItemServerSave = {
  item_type: 2;
  text: string | null;
  icon: string | null;
  description: string | null;
  button: string | null;
  price: number | null;
};

type LinkPageItemServerSave = {
  item_type: 3;
  text: string | null;
  icon: string | null;
  link: string | null;
  link_type: LinkType;
};

function mapLinkItemToServer(item: LinkItem): LinkPageItemServerSave {
  return {
    item_type: 3,
    text: item.link.text,
    icon: item.link.icon,
    link: item.link.link,
    link_type: item.link.linkType,
  };
}

export function mapItemToServer(
  item: CreatorPageItem,
): ButtonPageItemServerSave | CardPageItemServerSave | LinkPageItemServerSave {
  if (item.type === "link") {
    return mapLinkItemToServer(item);
  }
  return item.type === "button"
    ? {
        item_type: 1,
        icon: item.button.icon,
        text: item.button.text,
      }
    : {
        item_type: 2,
        icon: item.card.icon,
        text: item.card.text,
        button: item.card.button,
        description: item.card.description,
        price: item.card.price,
      };
}

type ContentServer = {
  content_cover: string | null;
  content_title: string | null;
  content_description: string | null;
  content_button: string | null;
  has_content_phone: boolean;
  has_content_mail: boolean;
  has_content_name: boolean;
  has_content_comment: boolean;
};

export function mapContentToServer(content: Content): ContentServer {
  return {
    content_cover: content.cover,
    content_title: content.title,
    content_description: content.description,
    content_button: content.button,
    has_content_name: content.fields.name,
    has_content_mail: content.fields.mail,
    has_content_phone: content.fields.phone,
    has_content_comment: content.fields.comment,
  };
}

export function mapContentFromServer(item: ContentServer): Content {
  return {
    cover: item.content_cover,
    title: item.content_title,
    description: item.content_description,
    button: item.content_button,
    fields: {
      name: item.has_content_name,
      mail: item.has_content_mail,
      phone: item.has_content_phone,
      comment: item.has_content_comment,
    },
  };
}

export type ApplicationServer = {
  id: number;
  user_id: string;
  name: string | null;
  phone: string | null;
  mail: string | null;
  comment: string | null;
  item_id: number;
  created_at: string;
  completed: boolean;
};

export type Application = {
  id: number;
  userId: string;
  name: string | null;
  phone: string | null;
  mail: string | null;
  comment: string | null;
  itemId: number;
  createdAt: string;
  completed: boolean;
};

export function mapApplicationsFromServer(
  applications: ApplicationServer[],
): Application[] {
  return applications.map((iterated) => ({
    id: iterated.id,
    userId: iterated.user_id,
    name: iterated.name,
    phone: iterated.phone,
    mail: iterated.mail,
    comment: iterated.comment,
    itemId: iterated.item_id,
    createdAt: iterated.created_at,
    completed: iterated.completed,
  }));
}

export function mapApplicationsToServer(
  applications: Application[],
): ApplicationServer[] {
  return applications.map((iterated) => ({
    id: iterated.id,
    name: iterated.name,
    phone: iterated.phone,
    mail: iterated.mail,
    comment: iterated.comment,
    item_id: iterated.itemId,
    created_at: iterated.createdAt,
    user_id: iterated.userId,
    completed: iterated.completed,
  }));
}
