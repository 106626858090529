import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import Title from "../../components/ui/Title";
import { useAuth } from "../../providers/AuthProvider";
import ButtonWithImage from "../../components/ui/ButtonWithImage";
import { validateEmail } from "../../helpers/validations";
import toast from "react-hot-toast";
import { trackEvent } from "../../lib/ym";

export default function SignUpPage() {
  const { signInWithGoogle, signUp } = useAuth();
  const [waitingForVerivication, setWaitingForVerification] = useState(false);
  const [form, setForm] = useState<{
    loading: boolean;
    email: string | null;
    isEmailInvalid: boolean;
    password: string | null;
    isPasswordInvalid: boolean;
  }>({
    loading: false,
    email: null,
    isEmailInvalid: false,
    password: null,
    isPasswordInvalid: false,
  });

  function validate() {
    return {
      isEmailInvalid: !form.email || !validateEmail(form.email),
      isPasswordInvalid: !form.password || form.password.length < 6,
    };
  }
  async function submit() {
    setForm({
      ...form,
      loading: true,
    });
    const { data, error } = await signUp({
      email: form.email as string,
      password: form.password as string,
    });
    setForm({
      ...form,
      loading: false,
    });
    console.log({ data, error });
    if (error) {
      console.log({ error });
      if (error.status === 429) {
        toast("Слишком много попыток регистрации. Попробуйте позже");
      }
      return;
    } else if (
      data.user &&
      data.user.identities &&
      data.user.identities.length === 0
    ) {
      toast.error("Эта почта уже используется");
      return;
    }
    trackEvent("signup");
    setWaitingForVerification(true);
  }

  function handleSubmit() {
    const validations = validate();
    console.log({ validations });
    if (validations.isEmailInvalid || validations.isPasswordInvalid) {
      setForm({
        ...form,
        isEmailInvalid: validations.isEmailInvalid,
        isPasswordInvalid: validations.isPasswordInvalid,
      });
      return;
    }
    submit();
  }

  return (
    <div className="w-96 flex flex-col gap-6">
      {waitingForVerivication ? (
        <>
          <Title size="lg">Подтвердите почту</Title>
          <div className="text-[15px] text-neutral4">
            Перейдите по ссылке из письма, чтобы подтвердить почту
          </div>
        </>
      ) : (
        <>
          <Title size="lg">Создать аккаунт</Title>
          <div className="flex flex-col gap-4">
            <form
              className="flex flex-col gap-4"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Input
                type="email"
                placeholder="Почта"
                error={form.isEmailInvalid}
                value={form.email}
                onInput={(email) => {
                  setForm({
                    ...form,
                    email,
                    isEmailInvalid: false,
                  });
                }}
              ></Input>
              <Input
                type="password"
                placeholder="Пароль"
                error={form.isPasswordInvalid}
                value={form.password}
                onInput={(password) => {
                  setForm({
                    ...form,
                    password,
                    isPasswordInvalid: false,
                  });
                }}
              ></Input>
              <Button
                grow
                type="submit"
                disabled={form.loading}
              >
                Зарегистрироваться
              </Button>
            </form>
            <div className="h-[1px] w-full bg-neutral3"></div>
            <div className="flex flex-col gap-3">
              <div className="text-[15px] leading-[15px] text-neutral4">
                Войти с помощью:
              </div>
              <ButtonWithImage
                icon="/google.svg"
                text="Google"
                onClick={async () => {
                  const { error, data } = await signInWithGoogle();
                  console.log({ data, error });
                  if (!error) {
                    trackEvent("signup", {
                      provider: "google",
                    });
                  }
                }}
              ></ButtonWithImage>
            </div>
          </div>
          <div className="flex flex-col gap-4 items-center">
            <Link
              to="/auth/login"
              className="text-neutral4 underline font-medium"
              onClick={() => {
                trackEvent("auth_click", {
                  button: "from_signup_to_login",
                });
              }}
            >
              Есть аккаунт? Войти
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
