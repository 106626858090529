import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import Title from "../../components/ui/Title";
import { validateEmail } from "../../helpers/validations";
import { useAuth } from "../../providers/AuthProvider";
import toast from "react-hot-toast";
import { trackEvent } from "../../lib/ym";

export default function RecoveryPage() {
  const [mailSent, setMailSent] = useState(false);
  const { resetPassword } = useAuth();
  const [form, setForm] = useState<{
    loading: boolean;
    email: string | null;
    isEmailInvalid: boolean;
  }>({
    loading: false,
    email: null,
    isEmailInvalid: false,
  });

  function validate() {
    return {
      isEmailInvalid: !form.email || !validateEmail(form.email),
    };
  }

  async function submit() {
    try {
      console.log("go");
      setForm({
        ...form,
        loading: true,
      });
      const { data, error } = await resetPassword(form.email as string);
      console.log({ data, error });
      if (!error) {
        setMailSent(true);
        trackEvent("recovery");
      } else {
        toast.error("Ошибка при попытке восстановления");
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setForm({
        ...form,
        loading: false,
      });
    }
  }

  function handleSubmit() {
    const validations = validate();
    console.log({ validations });
    if (validations.isEmailInvalid) {
      setForm({
        ...form,
        isEmailInvalid: validations.isEmailInvalid,
      });
      return;
    }
    submit();
  }

  return (
    <div className="w-96 flex flex-col gap-6">
      {mailSent ? (
        <>
          <Title size="lg">Измените пароль</Title>
          <div className="text-[15px] text-neutral4">
            Перейдите по ссылке из письма, чтобы изменить пароль
          </div>
        </>
      ) : (
        <>
          <Title size="lg">Восстановить пароль</Title>
          <form
            className="flex flex-col gap-4"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Input
              type="email"
              placeholder="Введите почту"
              error={form.isEmailInvalid}
              value={form.email}
              onInput={(email) => {
                setForm({
                  ...form,
                  email,
                  isEmailInvalid: false,
                });
              }}
            ></Input>
            <Button
              grow
              type="submit"
              disabled={form.loading}
            >
              Восстановить
            </Button>
          </form>
          <div className="flex flex-col gap-4 items-center">
            <Link
              to="/auth/login"
              className="text-neutral4 underline font-medium"
              onClick={() => {
                trackEvent("auth_click", {
                  button: "from_recovery_to_login",
                });
              }}
            >
              Войти
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
