import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children?: ReactNode;
  className?: string;
  onClick?: () => any;
}

export default function TableRow({ children, className, onClick }: Props) {
  return (
    <tr
      className={twMerge("transition-colors hover:bg-neutral2", className)}
      onClick={onClick}
    >
      {children}
    </tr>
  );
}
