import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Modal from "../ui/Modal";
import Button from "../ui/Button";
import { useCallback, useState } from "react";
import { supabase } from "../../lib/supabase";
import { useAuth } from "../../providers/AuthProvider";
import toast from "react-hot-toast";
import Input from "../ui/Input";
import Textarea from "../ui/Textarea";
import { trackEvent } from "../../lib/ym";
import Profile from "../ui/Profile";
import InputUpload from "../ui/InputUpload";
import { Profile as ProfileType } from "../creator/CreatorPage";
import ProfileContainer from "../ProfileContainer";

interface Props {
  defaultProfile: ProfileType;
  onFinish: (profile: ProfileType) => void;
}

export default NiceModal.create(({ onFinish, defaultProfile }: Props) => {
  const modal = useModal("set-nickname-modal");
  const [invalidNickname, setInvalidNickname] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const { user } = useAuth();
  const [localProfile, setLocalProfile] = useState(defaultProfile);

  function closeModal() {
    modal.hide();
  }

  const handleUpdateProfile = useCallback(async () => {
    console.log({ name: localProfile.name?.trim() });
    if (!localProfile.name?.trim()) {
      toast.error("Введи свое имя");
      setInvalidName(true);
      return;
    }
    const nickname = localProfile.nickname?.trim();
    if (nickname?.includes("/")) {
      toast.error("Символ `/` запрещено использовать в никнейме");
      setInvalidNickname(true);
      return;
    }
    if (!user || !nickname?.length) {
      return;
    }

    const { error } = await supabase
      .from("users")
      .update({
        nickname: nickname,
        bio: localProfile.bio,
        name: localProfile.name,
        avatar: localProfile.avatar,
      })
      .eq("id", user.id)
      .select();

    if (!error) {
      toast.success("Профиль обновлен");
      onFinish(localProfile);
      modal.hide();
      return;
    }
    if (error?.code === "23505") {
      setInvalidNickname(true);
      toast.error("Такой никнейм уже занят");
    }
  }, [localProfile, user]);

  const [isFileLoading, setIsFileLoading] = useState(false);
  async function uploadFile(file: File) {
    console.log(`${user?.id}`, file);
    trackEvent("profile_click_avatar", {
      button: "set_nickname",
    });
    setIsFileLoading(true);
    const filePath = `${user?.id}/${Date.now()}`;
    const { error: uploadError } = await supabase.storage
      .from("images")
      .upload(filePath, file, {
        upsert: true,
      });
    setIsFileLoading(false);
    if (uploadError) {
      console.log({ uploadError });
      if ((uploadError as any)?.statusCode === 413) {
        toast.error("Файл слишком большой");
      } else {
        toast.error("Ошибка загрузки файла");
      }
      return;
    }
    const { data } = await supabase.storage
      .from("images")
      .getPublicUrl(filePath);
    setLocalProfile((profile) => ({
      ...profile,
      avatar: data.publicUrl,
    }));
  }

  return (
    <Modal
      visible={modal.visible}
      onClose={closeModal}
      afterClose={() => modal.remove()}
      notClosable
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className="text-[20px] leading-[32px] font-semibold">
            Давай настроим твою страницу
          </div>
          <div className="text-[15px] leading-[24px] text-neutral4 font-semibold">
            Клиенты больше доверяют страницам с аватаром и био, поэтому эти поля
            нужно заполнить. Позже все настройки можно поменять на странице
            профиля.
          </div>
        </div>
        <ProfileContainer nickname={localProfile.nickname || ""}>
          <Profile
            avatar={localProfile.avatar || ""}
            name={localProfile.name || "Твое имя"}
            bio={localProfile.bio || "Твое био"}
          ></Profile>
        </ProfileContainer>
        <InputUpload
          loading={isFileLoading}
          accept="image/jpeg,image/png,image/svg+xml,image/webp"
          filePath={localProfile.avatar}
          onInput={uploadFile}
          onDelete={() => {
            setLocalProfile((localProfile) => ({
              ...localProfile,
              avatar: null,
            }));
          }}
        >
          Аватар
        </InputUpload>
        <Input
          placeholder="Введи свое имя"
          error={invalidName}
          label="Твое имя"
          value={localProfile.name}
          onInput={(value) => {
            setLocalProfile((localProfile) => ({
              ...localProfile,
              name: value,
            }));
            setInvalidName(false);
          }}
        ></Input>
        <Textarea
          placeholder="Опиши себя кратко"
          label="Твое био"
          value={localProfile.bio || ""}
          onInput={(value) => {
            setLocalProfile((localProfile) => ({
              ...localProfile,
              bio: value,
            }));
          }}
        ></Textarea>
        <Input
          error={invalidNickname}
          label="Ссылка на твою страницу"
          labelTooltip="Задай уникальную публичную ссылку на свою страницу"
          value={localProfile.nickname}
          onInput={(value) => {
            setLocalProfile((localProfile) => ({
              ...localProfile,
              nickname: value,
            }));
            setInvalidNickname(false);
          }}
          theme="white"
        >
          creatorstore.pro/
        </Input>
        <Button
          disabled={!localProfile.nickname?.trim()?.length}
          onClick={handleUpdateProfile}
        >
          Готово
        </Button>
      </div>
    </Modal>
  );
});
