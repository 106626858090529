import React, { CSSProperties, useState } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  src: string;
  alt: string;
  className?: string;
  style?: CSSProperties;
  onClick?: (e: any) => any;
}

export default function Image({ src, alt, className, style, onClick }: Props) {
  const [visible, setVisible] = useState(false);

  return (
    <img
      className={twMerge(
        "opacity-0 transition-opacity duration-500",
        visible && "opacity-100",
        className,
      )}
      src={src}
      alt={alt}
      onLoad={() => setVisible(true)}
      onClick={onClick}
      style={style}
    />
  );
}
