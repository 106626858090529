import React, { ChangeEvent, ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";
import Icon from "./Icon";
import Button from "./Button";

interface Props {
  accept?: string;
  multiple?: boolean;
  onInput?: (file: File) => void;
  onDelete: () => void;
  className?: string;
  children?: ReactNode;
  filePath?: string | null;
  loading?: boolean;
}

export default function InputUpload({
  accept,
  multiple,
  onInput,
  className,
  filePath,
  children,
  onDelete,
  loading,
}: Props) {
  const [hover, setHover] = useState(false);

  function handleUploadFile(event: ChangeEvent<HTMLInputElement>) {
    if (onInput && event.target.files?.[0]) {
      onInput(event.target.files[0]);
    }
  }
  return (
    <div className={twMerge("inline-flex flex-col gap-3 w-full", className)}>
      <div
        className={twMerge(
          "relative w-full flex items-center bg-neutral2 rounded-xl px-3",
          filePath && "pr-[68px]",
        )}
      >
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          onFocus={() => {
            setHover(true);
          }}
          onBlur={() => {
            setHover(true);
          }}
          className="cursor-pointer relative w-full flex items-center gap-2 h-[48px]"
        >
          <input
            type="file"
            accept={accept}
            title=""
            multiple={multiple}
            className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer"
            onChange={handleUploadFile}
          ></input>
          {filePath ? (
            <img
              className="object-cover rounded-full w-8 h-8"
              src={filePath}
            ></img>
          ) : (
            <Icon
              id="image"
              color="var(--neutral4)"
            ></Icon>
          )}
          {children}
          {
            <Button
              size="xs"
              className="ml-auto"
              manualHover={hover}
              disabled={loading}
            >
              {filePath ? "Изменить" : "Загрузить"}
            </Button>
          }
        </div>
        {filePath && (
          <Button
            className="z-10 absolute right-[12px]"
            onClick={() => {
              onDelete();
            }}
            size="xs"
            icon="trash"
            theme="red"
            iconSize={16}
          ></Button>
        )}
      </div>
    </div>
  );
}
