import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: ReactNode;
  className?: string;
}

export default function TableCell({ children, className }: Props) {
  return <td className={twMerge(className)}>{children}</td>;
}
