import React, { CSSProperties, useEffect } from "react";
import { Option } from "./Menu";
import Icon from "../../ui/Icon";
import { twMerge } from "tailwind-merge";
import { Link, useLocation } from "react-router-dom";
import MenuItemPopover from "./MenuItemPopover";
import { useApplications } from "../../../store/useApplications";
import { useShallow } from "zustand/react/shallow";
import { useAuth } from "../../../providers/AuthProvider";

const MOBILE_MENU_OPTIONS: Option[] = [
  {
    id: "home",
    to: "/",
    icon: "home-light",
    iconFill: "home-fill",
    label: "Главная",
    selected: (path) => path === "/",
    eventParams: {
      item: "store",
    },
  },
  {
    id: "design",
    to: "/design-mobile",
    icon: "brush",
    iconFill: "brush-fill",
    label: "Шаблон",
    selected: (path) => path === "/design-mobile",
    eventParams: {
      item: "design",
    },
  },
  {
    id: "add-item",
    to: "/?create-item=true",
    icon: "add-circled",
    label: "Добавить",
    selected: (path) => path.includes("/items/"),
    eventParams: {
      item: "add-item",
    },
  },
  {
    id: "preview",
    to: "/preview",
    icon: "show",
    iconFill: "show-fill",
    label: "Превью",
    selected: (path) => path === "/preview",
    eventParams: {
      item: "preview",
    },
  },
];

interface Props {
  className?: string;
  style?: CSSProperties;
}

export default function MenuMobile({ className }: Props) {
  const location = useLocation();
  const { user } = useAuth();
  const { getCount, count } = useApplications(
    useShallow((state) => ({
      getCount: state.getCount,
      count: state.count,
    })),
  );

  useEffect(() => {
    if (user?.id) {
      getCount(user.id);
    }
  }, [user?.id]);
  return (
    <div
      className={twMerge(
        "flex items-center pt-2 px-4 pb-5 w-full bg-neutral1 border-t-2 border-t-neutral2",
        className,
      )}
      style={{
        boxShadow: "0px -1px 0px 0px rgba(244, 244, 244, 1) inset",
      }}
    >
      {MOBILE_MENU_OPTIONS.map((option) => (
        <Link
          to={option.to}
          key={option.id}
          className="flex-1 flex flex-col items-center"
        >
          {option.icon &&
            (option.iconFill && option?.selected?.(location.pathname) ? (
              <Icon
                id={option.iconFill}
                color="var(--neutral6)"
              ></Icon>
            ) : (
              <Icon
                id={option.icon}
                color="var(--neutral4)"
              ></Icon>
            ))}
          <div
            className={twMerge(
              "text-[12px] leading-[16px] font-medium",
              option?.selected?.(location.pathname)
                ? "text-neutral5"
                : "text-neutral4",
            )}
          >
            {option.label}
          </div>
        </Link>
      ))}

      <MenuItemPopover
        items={[
          {
            id: "new-applicants",
            to: "/applicants/new",
            label: "Новые",
            counter: count,
          },
          {
            id: "completed-applicants",
            to: "/applicants/completed",
            label: "Завершенные",
          },
        ]}
      >
        <div className="flex-1 flex flex-col items-center">
          {location.pathname.includes("/applicants/") ? (
            <Icon
              id="receipt-fill"
              color="var(--neutral6)"
            ></Icon>
          ) : (
            <Icon
              id="receipt"
              color="var(--neutral4)"
            ></Icon>
          )}
          <div
            className={twMerge(
              "text-[12px] leading-[16px] font-medium",
              location.pathname.includes("/applicants/")
                ? "text-neutral5"
                : "text-neutral4",
            )}
          >
            Заявки
          </div>
        </div>
      </MenuItemPopover>
    </div>
  );
}
