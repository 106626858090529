import React from "react";
import { twMerge } from "tailwind-merge";
import { CreatorPageTheme, Links } from "../creator/CreatorPage";
import ProfileIconList from "./ProfileIconList";

interface Props {
  avatar?: string;
  name: string;
  bio?: string;
  dark?: boolean;
  className?: string;
  theme: CreatorPageTheme;
  links?: Links;
}

export default function FullImageProfile({
  avatar,
  name,
  bio,
  className,
  links,
}: Props) {
  return (
    <div
      className={twMerge("w-full flex flex-col items-center gap-6", className)}
    >
      <div
        className="w-full relative h-[205px] -mx-6 bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${avatar})` }}
      >
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full bg-gradient-to-b from-[#0000006e] to-transparent to-[60%]"></div>
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full bg-gradient-to-t from-[#00000054] to-transparent to-[30%]"></div>
      </div>

      <div className={twMerge("px-6 flex flex-col items-center gap-1")}>
        <div className={twMerge("text-[24px] leading-[32px] font-semibold")}>
          {name}
        </div>
        <div
          className={twMerge(
            "text-[#9A9FA5] text-[15px] leading-[24px] font-medium text-center whitespace-pre-wrap",
          )}
        >
          {bio || "Ваше био"}
        </div>
        {links && (
          <ProfileIconList
            links={links}
            className="mt-2"
          ></ProfileIconList>
        )}
      </div>
    </div>
  );
}
