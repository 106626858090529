import React, { ReactNode } from "react";

import { AnimatePresence, motion } from "framer-motion";
import {
  FloatingPortal,
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useClick,
  useInteractions,
} from "@floating-ui/react";
import { twMerge } from "tailwind-merge";

interface Props {
  content: ReactNode;
  placement?: Placement;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  onRefClick?: () => void;
}

export default function Popover({
  content,
  placement = "top",
  children,
  className,
  disabled,
  open,
  setOpen,
  onRefClick,
}: Props) {
  const {
    x,
    y,
    refs,
    strategy,
    context,
    placement: computedPlacement,
  } = useFloating({
    placement,
    open,
    onOpenChange(open) {
      setOpen(open);
    },
    middleware: [offset(12), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocus(context),
    useDismiss(context),
  ]);

  const translate = {
    top: { translateY: 5 },
    bottom: { translateY: -5 },
    left: { translateX: 5 },
    right: { translateX: -5 },
  }[
    computedPlacement.includes("-")
      ? computedPlacement.split("-")[0]
      : computedPlacement
  ];

  return (
    <>
      <div
        className={twMerge(
          "cursor-pointer",
          disabled && "events-none",
          className,
        )}
        ref={refs.setReference}
        {...getReferenceProps({
          onClick: (e) => {
            e.stopPropagation();
            onRefClick && onRefClick();
          },
        })}
      >
        {children}
      </div>
      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ opacity: 0, ...translate }}
              animate={{ opacity: 1, translateX: 0, translateY: 0 }}
              exit={{ opacity: 0, ...translate }}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}
              className="z-30 bg-neutral1 rounded-md max-w-[260px] text-[14px] leading-[16px] shadow-sm"
            >
              {content}
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  );
}
