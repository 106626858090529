import { create } from "zustand";
import { supabase } from "../lib/supabase";

interface ApplicationStore {
  count: number;
  getCount: (userId: string) => Promise<void>;
  setCount: (value: number) => void;
  reset: () => void;
}

export const useApplications = create<ApplicationStore>((set, get) => ({
  count: 0,
  getCount: async (userId) => {
    const { count, error } = await supabase
      .from("applications")
      .select("*", { count: "exact", head: true })
      .eq("user_id", userId)
      .eq("completed", false);
    console.log({ count, error });
    if (typeof count === "number" && !error) {
      set({ count });
    }
  },
  setCount: (value) => {
    set({ count: value });
  },
  reset: () => {
    set({
      count: 0,
    });
  },
}));
