import React from "react";
import Dialog from "../ui/Dialog";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

interface Props {
  title: string;
  text?: string;
  afterClose: () => void;
  visible: boolean;
  onContinue: () => void;
  onCancel: () => void;
}
export default NiceModal.create(
  ({ title, text, onCancel, onContinue }: Props) => {
    const modal = useModal("dialog");
    return (
      <Dialog
        title={title}
        text={text}
        visible={modal.visible}
        onClose={() => modal.hide()}
        afterClose={() => modal.remove()}
        onCancel={onCancel}
        onContinue={onContinue}
      ></Dialog>
    );
  },
);
