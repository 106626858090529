import React, { ReactNode } from "react";

interface Props {
  children: ReactNode[] | ReactNode;
}

export default function TableHeader({ children }: Props) {
  return (
    <thead>
      <tr className="border-b-1 border-neutral3">{children}</tr>
    </thead>
  );
}
