import React, { HTMLProps, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import FormLabel from "./FormLabel";

interface Props {
  value: string | null;
  onInput: (value: string) => any;
  label?: string;
  labelTooltip?: string;
  placeholder?: string;
  error?: string | boolean;
  textareaProps?: HTMLProps<HTMLTextAreaElement>;
  grow?: boolean;
}

export default function Textarea({
  label,
  labelTooltip,
  placeholder,
  error,
  textareaProps,
  value,
  onInput,
  grow,
}: Props) {
  const [active, setActive] = useState(false);
  const id = useRef(String(Date.now() + Math.random()));
  return (
    <div className={twMerge("inline-flex flex-col gap-3", grow && "w-full")}>
      <FormLabel
        id={id.current}
        error={error}
        label={label}
        labelTooltip={labelTooltip}
      ></FormLabel>
      <textarea
        id={id.current}
        value={value ?? ""}
        onInput={(e) => {
          onInput((e.target as HTMLTextAreaElement).value);
        }}
        placeholder={placeholder}
        className={twMerge(
          "text-[16px] p-[12px] flex w-full items-center rounded-xl border-2 transition-colors h-[112px] outline-none placeholder-[#9A9FA5] font-semibold text-neutral-7",
          error
            ? "bg-white border-secondary1"
            : active
              ? "bg-white border-[#2A85FF59]"
              : "bg-neutral2 border-neutral2",
        )}
        onFocus={() => {
          setActive(true);
        }}
        onBlur={() => {
          setActive(false);
        }}
        {...textareaProps}
      ></textarea>
    </div>
  );
}
