import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { trackPageView } from "./lib/ym";

export default function Root() {
  const location = useLocation();
  useEffect(() => {
    trackPageView(window.location.href);
  }, [location.pathname]);
  return <Outlet></Outlet>;
}
