export const METRIKA_ID = 96808862;

export function trackPageView(href: string) {
  console.log("Track page view:", href);
  ym(METRIKA_ID, "hit", href);
}

export function trackEvent(
  event: string,
  params?: Record<
    string,
    string | number | boolean | null | undefined | string[]
  >,
) {
  console.log("Track event:", event, params);
  ym(METRIKA_ID, "reachGoal", event, params);
}
