import {
  useState,
  useEffect,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from "react";
import { supabase } from "../lib/supabase";
import {
  AuthResponse,
  AuthTokenResponsePassword,
  OAuthResponse,
  SignInWithPasswordCredentials,
  SignUpWithPasswordCredentials,
  User,
  UserResponse,
} from "@supabase/supabase-js";
import { useCreatorPage } from "../store/useCreatorPage";
import { SubscriptionType } from "../pages/pricing/Pricing";
import { useApplications } from "../store/useApplications";
import { setContactEmail } from "../lib/jivo";

export type AuthContextType = {
  user: User | null;
  signUp: (data: SignUpWithPasswordCredentials) => Promise<AuthResponse>;
  signIn: (
    data: SignInWithPasswordCredentials,
  ) => Promise<AuthTokenResponsePassword>;
  signOut: () => any;
  resetPassword: (email: string) => Promise<any>;
  loading: boolean;
  isProfileLoading: boolean;
  signInWithGoogle: () => Promise<OAuthResponse>;
  changePassword: (password: string) => Promise<UserResponse>;
  subscription: Subscription;
  updateSubscription: (type: SubscriptionType) => Promise<void>;
  isSubscriptionActive: boolean;
  isProfileInvalidated: boolean;
};
const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  return useContext(AuthContext) as AuthContextType;
}

interface Subscription {
  subscription: SubscriptionType | null;
  subscriptionUntil: Date | null;
}

interface Props {
  children: ReactNode;
}

export function AuthProvider({ children }: Props) {
  const [subscription, setSubscription] = useState<Subscription>({
    subscription: null,
    subscriptionUntil: null,
  });
  const isSubscriptionActive = useMemo(() => {
    return Boolean(
      subscription.subscription &&
        subscription.subscriptionUntil &&
        Date.now() < subscription.subscriptionUntil.getTime(),
    );
  }, [subscription]);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const setProfile = useCreatorPage((state) => state.setProfile);
  const setTheme = useCreatorPage((state) => state.setTheme);
  const setItemList = useCreatorPage((state) => state.setItemList);
  const resetCreatorPage = useCreatorPage((state) => state.reset);
  const resetApplications = useApplications((state) => state.reset);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isProfileInvalidated, setIsProfileInvalidated] = useState(true);

  async function retrieveSession() {
    const session = await supabase.auth.getSession();
    console.log({ session });
    const user = session?.data.session?.user;
    setUser(user ?? null);
    if (user && user.email) {
      setContactEmail(user.email);
    }
    setLoading(false);
  }

  async function updateSubscription(type: SubscriptionType) {
    if (!user) {
      return;
    }
    const date = new Date();
    date.setDate(date.getDate() + 30);
    console.log({ date });
    const { data, error } = await supabase
      .from("users")
      .update({
        subscription: type,
        subscription_until: date,
      })
      .eq("id", user.id)
      .select();
    console.log({ data, error });
    if (data && !error) {
      setSubscription({
        subscription: type,
        subscriptionUntil: date,
      });
    }
  }

  function signOut() {
    return supabase.auth
      .signOut({
        scope: "local",
      })
      .then(() => {
        setIsProfileInvalidated(true);
        setTimeout(() => {
          resetApplications();
          resetCreatorPage();
        });
        setSubscription({
          subscription: null,
          subscriptionUntil: null,
        });
      });
  }

  async function getUserProfile(user: User) {
    if (!user) {
      return;
    }
    setIsProfileLoading(true);
    setIsProfileInvalidated(false);
    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("id", user.id);
    console.log({ data, error });

    if (data?.length) {
      setProfile({
        name: data[0].name,
        bio: data[0].bio,
        avatar: data[0].avatar,
        nickname: data[0].nickname,
        links: {
          vk: data[0].vk_link,
          tg: data[0].telegram_link,
          tt: data[0].tiktok_link,
          yt: data[0].youtube_link,
          site: data[0].site_url,
        },
      });
      setTheme(data[0].theme);
      setItemList(data[0].items ?? []);
      setSubscription({
        subscription: data[0].subscription ?? null,
        subscriptionUntil: data[0].subscription_until
          ? new Date(data[0].subscription_until)
          : null,
      });
    } else {
      signOut();
    }
    setIsProfileLoading(false);
  }

  useEffect(() => {
    // Check active sessions and sets the user
    retrieveSession();

    // Listen for changes on auth state (logged in, signed out, etc.)
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log({ event, session });
      const user = session?.user;
      setUser(user ?? null);
      if (user && user.email) {
        setContactEmail(user.email);
      }
      setLoading(false);
    });

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user && !loading && !isProfileLoading) {
      getUserProfile(user);
    }
  }, [user?.id]);

  // Will be passed down to Signup, Login and Dashboard components
  const value = {
    signUp: (data: SignUpWithPasswordCredentials) => supabase.auth.signUp(data),
    signIn: (data: SignInWithPasswordCredentials) =>
      supabase.auth.signInWithPassword(data),
    signInWithGoogle: async () => {
      return supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: process.env.REACT_APP_URL,
        },
      });
    },
    resetPassword: (email: string) => {
      return supabase.auth.resetPasswordForEmail(email, {
        redirectTo: process.env.REACT_APP_URL + "change-password",
      });
    },
    signOut,
    changePassword: (password: string) => {
      return supabase.auth.updateUser({ password });
    },
    user,
    loading,
    isProfileLoading,
    subscription,
    updateSubscription,
    isSubscriptionActive,
    isProfileInvalidated,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
