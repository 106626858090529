import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: ReactNode;
  minWidth?: number | string;
  maxWidth?: number | string;
  className?: string;
  style?: CSSStyleSheet;
}

export default function TableHeaderCell({
  children,
  minWidth,
  maxWidth,
  className,
  style,
}: Props) {
  return (
    <th
      className={twMerge(
        "text-left text-[13px] leading-[16px] font-semibold text-neutral4",
        className,
      )}
      style={{
        ...style,
        minWidth: typeof minWidth === "number" ? minWidth + "px" : minWidth,
        maxWidth: typeof maxWidth === "number" ? maxWidth + "px" : maxWidth,
      }}
    >
      {children}
    </th>
  );
}
