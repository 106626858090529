import React, { useEffect, useMemo, useState } from "react";
import CreatorPage from "../components/creator/CreatorPage";
import { trackEvent } from "../lib/ym";
import { useShallow } from "zustand/react/shallow";
import { useAuth } from "../providers/AuthProvider";
import { useApplications } from "../store/useApplications";
import { useCreatorPage } from "../store/useCreatorPage";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import Button from "../components/ui/Button";
import { useNavigate } from "react-router-dom";

export default function PreviewPage() {
  const { user } = useAuth();
  const {
    profile,
    theme,
    items,
    fetchItems,
    isItemsInvalidated,
    isItemsLoading,
  } = useCreatorPage(
    useShallow((state) => ({
      profile: state.profile,
      theme: state.theme,
      items: state.items,
      fetchItems: state.fetchItems,
      isItemsInvalidated: state.isItemsInvalidated,
      isItemsLoading: state.isItemsLoading,
    })),
  );
  const activeItems = useMemo(() => {
    return items.filter((iterated) => !iterated.deleted);
  }, [items]);
  const [path, setPath] = useState("main");
  const count = useApplications((state) => state.count);
  const setCount = useApplications((state) => state.setCount);
  useEffect(() => {
    if (!isItemsLoading && isItemsInvalidated && user?.id) {
      fetchItems(user.id).then((items) => {
        if (!items) {
          toast.error(
            "Ошибка при загрузке страницы. Перезагрузите страницу или попробуйте позже",
          );
        }
      });
    }
  }, [isItemsInvalidated, user?.id]);
  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        "w-full h-full flex-1 relative",
        theme && ["dark", "black"].includes(theme)
          ? "bg-neutral8"
          : "bg-neutral1",
      )}
    >
      <Button
        onClick={() => {
          navigate(-1);
        }}
        theme="secondary"
        icon="close"
        className="absolute right-5 top-4 z-20"
      ></Button>
      <div className={twMerge("mx-auto max-w-[480px] md:py-12 h-full")}>
        <div className="flex flex-col gap-16 h-full">
          <CreatorPage
            maxWidth={352}
            userId={user?.id ?? null}
            profile={profile}
            theme={theme}
            items={activeItems}
            path={path}
            onChangePath={(path) => {
              setPath(path);
              if (path !== "main") {
                trackEvent("store_click_preview_item");
              }
            }}
            onSubmit={() => {
              setCount(count + 1);
              trackEvent("store_submit_preview_form");
            }}
          ></CreatorPage>
        </div>
      </div>
    </div>
  );
}
