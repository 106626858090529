import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children?: ReactNode;
  color?: string;
  number?: number;
  size?: "md" | "lg";
  className?: string;
}

export default function Title({
  children,
  color,
  number,
  size = "md",
  className,
}: Props) {
  return (
    <div
      className={twMerge(
        "flex gap-4 font-semibold",
        size === "md"
          ? "text-[20px] leading-[32px]"
          : "text-[40px] leading-[48px]",
        className,
      )}
    >
      {color && (
        <div
          className={twMerge(
            "flex items-center justify-center rounded-md",
            number ? "min-w-8 max-w-8" : "min-w-4 max-w-4",
          )}
          style={{ backgroundColor: color }}
        >
          {number}
        </div>
      )}
      {children}
    </div>
  );
}
