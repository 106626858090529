import React, { useEffect, useMemo, useState } from "react";
import Page from "../../../components/layout/Page";
import Tabs from "../../../components/ui/Tabs/Tabs";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CreatorPage, {
  CreatorPageItem,
  LinkAppearance,
  getItemMeta,
} from "../../../components/creator/CreatorPage";
import { CardType } from "../../../components/ui/Card";
import { ButtonType } from "../../../components/ui/ButtonWithImage";
import { useCreatorPage } from "../../../store/useCreatorPage";
import { useAuth } from "../../../providers/AuthProvider";
import { useShallow } from "zustand/react/shallow";
import toast from "react-hot-toast";
import { trackEvent } from "../../../lib/ym";
import BottomPanel from "../../../components/ui/BottomPanel";
import Button from "../../../components/ui/Button";
import Icon from "../../../components/ui/Icon";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../constants/breakpoints";
import ReactRouterPrompt from "react-router-prompt";
import Dialog from "../../../components/ui/Dialog";

export interface Fields {
  name: boolean;
  phone: boolean;
  mail: boolean;
  comment: boolean;
}

export interface Content {
  cover: string | null;
  title: string | null;
  description: string | null;
  fields: Fields;
  button: string | null;
}

export type CreateItemContextType = {
  onSubmitAppearance: () => void;
  onSubmitContent: () => void;
  loading: boolean;

  isTextInvalid: boolean;
  setIsTextInvalid: (flag: boolean) => void;

  isLinkInvalid: boolean;
  setIsLinkInvalid: (flag: boolean) => void;

  content: Content;
  setContent: (content: Content) => void;

  itemType: string;
  setItemType: (type: string) => void;

  button: ButtonType;
  setButton: (btn: ButtonType) => void;

  card: CardType;
  setCard: (btn: CardType) => void;

  link: LinkAppearance;
  setLink: (link: LinkAppearance) => void;
};

const tabs = [
  {
    id: "appearance",
    label: "Внешний вид",
  },
  {
    id: "content",
    label: "Страница продукта",
  },
];

export default function CreateItem() {
  const [loading, setLoading] = useState(false);
  const [isTextInvalid, setIsTextInvalid] = useState(false);
  const [isLinkInvalid, setIsLinkInvalid] = useState(false);

  const params = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [tab, setTab] = useState("appearance");

  const [itemType, setItemType] = useState("button");

  const [link, setLink] = useState<LinkAppearance>({
    text: null,
    icon: null,
    linkType: null,
    link: null,
  });
  const [button, setButton] = useState<ButtonType>({
    text: null,
    icon: null,
  });

  const [card, setCard] = useState<CardType>({
    text: null,
    price: 0,
    description: null,
    button: null,
    icon: null,
  });

  const [content, setContent] = useState<Content>({
    cover: null,
    title: null,
    description: null,
    fields: {
      name: false,
      phone: false,
      mail: false,
      comment: false,
    },
    button: null,
  });
  useEffect(() => {
    if (!matchPath(`/items/:id/${tab}`, location.pathname)) {
      const parts = location.pathname.split("/");
      setTab(parts[parts.length - 1]);
    }
  }, [location.pathname]);

  const isAppearancePage = useMemo(() => {
    return !!matchPath(`/items/:id/appearance`, location.pathname);
  }, [location.pathname]);

  const {
    fetchItems,
    isItemsInvalidated,
    isItemsLoading,
    theme,
    items,
    updateCardItem,
    updateButtonItem,
    updateItemContent,
    publishItem,
    updateAndPublishLinkItem,
  } = useCreatorPage(
    useShallow((state) => ({
      theme: state.theme,
      fetchItems: state.fetchItems,
      isItemsInvalidated: state.isItemsInvalidated,
      isItemsLoading: state.isItemsLoading,
      items: state.items,
      updateCardItem: state.updateCardItem,
      updateButtonItem: state.updateButtonItem,
      updateItemContent: state.updateItemContent,
      publishItem: state.publishItem,
      updateAndPublishLinkItem: state.updateAndPublishLinkItem,
    })),
  );

  const item = useMemo(() => {
    if (!params.id) {
      return undefined;
    }
    return items.find((item) => item.id === parseInt(params.id as string));
  }, [items]);

  const previewItems: CreatorPageItem[] = useMemo(() => {
    return params.id
      ? [
          itemType === "button"
            ? {
                id: parseInt(params.id),
                type: "button",
                button,
                content,
                published: item?.published ?? false,
                createdAt: item?.createdAt ?? new Date(),
                updatedAt: item?.updatedAt ?? new Date(),
              }
            : itemType === "link"
              ? {
                  id: parseInt(params.id),
                  type: "link",
                  link,
                  published: item?.published ?? false,
                  createdAt: item?.createdAt ?? new Date(),
                  updatedAt: item?.updatedAt ?? new Date(),
                }
              : {
                  id: parseInt(params.id),
                  type: "card",
                  card,
                  content,
                  published: item?.published ?? false,
                  createdAt: item?.createdAt ?? new Date(),
                  updatedAt: item?.updatedAt ?? new Date(),
                },
        ]
      : [];
  }, [button, card, itemType, content, isAppearancePage, link]);

  function initCurrentItem(items: CreatorPageItem[]) {
    const item = params.id
      ? items.find((iterated) => iterated.id === parseInt(params.id as string))
      : null;
    if (item) {
      setItemType(item.type);
      if (item.type === "button") {
        setButton({
          text: item.button.text,
          icon: item.button.icon,
        });
      } else if (item.type === "link") {
        setLink({
          ...item.link,
        });
      } else {
        setCard({
          text: item.card.text,
          icon: item.card.icon,
          button: item.card.button,
          description: item.card.description,
          price: item.card.price,
        });
      }
      if (item.type !== "link" && item.content) {
        setContent(item.content);
      }
    } else {
      // TODO
      navigate("/not-found");
    }
  }

  useEffect(() => {
    if (!isItemsLoading && isItemsInvalidated && user?.id) {
      fetchItems(user.id).then((items) => {
        if (items) {
          initCurrentItem(items);
        } else {
          toast(
            "Ошибка при загрузке страницы. Перезагрузите страницу или попробуйте позже",
          );
        }
      });
    } else if (!isItemsLoading && user?.id) {
      initCurrentItem(items);
    }
  }, [isItemsInvalidated, user?.id]);

  const hasDiffs = useMemo(() => {
    if (itemType !== item?.type) {
      return true;
    }
    if (item.type === "link") {
      return (
        item.link.icon !== link.icon ||
        item.link.text !== link.text ||
        item.link.link !== link.link ||
        item.link.linkType !== link.linkType
      );
    }
    if (
      item.content?.button !== content.button ||
      item.content?.title !== content.title ||
      item.content.cover !== content.cover ||
      item.content.description !== content.description ||
      (Object.keys(item.content?.fields) as Array<keyof Fields>)
        .filter((key) => item.content?.fields[key])
        .join("") !==
        (Object.keys(content.fields) as Array<keyof Fields>)
          .filter((key) => content.fields[key])
          .join("")
    ) {
      return true;
    }
    if (item.type === "button") {
      return (
        item.button.icon !== button.icon || item.button.text !== button.text
      );
    } else {
      return (
        item.card.icon !== card.icon ||
        item.card.text !== card.text ||
        item.card.button !== card.button ||
        item.card.price !== card.price ||
        item.card.description !== card.description
      );
    }
  }, [itemType, button, card, content, item, link]);

  async function submitLinkItem() {
    if (!user || !params.id) {
      return;
    }
    if (!link.text || !link.text.trim().length) {
      setIsTextInvalid(true);
      toast.error("Введите текст для кнопки");
      return;
    }
    if (!link.link || !link.link.trim().length) {
      setIsLinkInvalid(true);
      toast.error("Введите ссылку");
      return;
    }
    setLoading(true);
    const successItem = await updateAndPublishLinkItem(
      user?.id,
      parseInt(params.id),
      link,
    );
    if (successItem) {
      trackEvent("item_click_publish", getItemMeta(successItem, true));
      toast.success("Элемент успешно опубликован");
      navigate("/");
    } else {
      toast.error(
        "Ошибка при обновлении элемента. Перезагрузите страницу или попробуйте позже",
      );
    }
    setLoading(false);
  }

  async function onSubmitAppearance() {
    if (itemType === "link") {
      submitLinkItem();
      return;
    }
    if (!user || !params.id) {
      return;
    }
    if (itemType === "button" && (!button.text || !button.text.trim().length)) {
      setIsTextInvalid(true);
      toast.error("Введите текст для кнопки");
      return;
    }
    setLoading(true);
    const successUpdateItem =
      itemType === "card"
        ? await updateCardItem(user.id, parseInt(params.id), card)
        : await updateButtonItem(user.id, parseInt(params.id), button);
    if (successUpdateItem) {
      navigate(`/items/${params.id}/content`);
      trackEvent("item_click_continue", getItemMeta(successUpdateItem, true));
    } else {
      toast.error(
        "Ошибка при обновлении элемента. Перезагрузите страницу или попробуйте позже",
      );
    }
    setLoading(false);
  }

  async function onSubmitContent() {
    if (!user || !params.id) {
      return;
    }

    // TODO: add validation
    const successUpdateContent = await updateItemContent(
      user.id,
      parseInt(params.id),
      content,
    );
    const successPublishItem = await publishItem(user.id, parseInt(params.id));
    if (successUpdateContent && successPublishItem) {
      trackEvent("item_click_publish", getItemMeta(successPublishItem));
      toast.success("Элемент успешно опубликован");
      navigate("/");
    } else {
      toast.error(
        "Ошибка при обновлении элемента. Перезагрузите страницу или попробуйте позже",
      );
    }
  }

  const showBottomPanel = useMemo(() => {
    return Boolean(item) && hasDiffs && window.innerWidth >= BREAKPOINTS.tablet;
  }, [item, hasDiffs]);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  return (
    <Page
      row
      className="gap-6 top-0 flex-col lg:flex-row"
    >
      <ReactRouterPrompt when={hasDiffs}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            theme="red"
            visible={isActive}
            onCancel={onCancel}
            onContinue={onConfirm}
            title="Вы действительно хотите выйти?"
            text="У вас есть несохраненные изменения"
            continueText="Выйти без сохранения"
          ></Dialog>
        )}
      </ReactRouterPrompt>
      <div className="flex flex-col flex-1 md:mb-[100px]">
        {itemType !== "link" && (
          <Tabs
            className="mb-2 hidden md:flex"
            options={tabs}
            value={tab}
            onInput={(tab) => {
              if (!matchPath(`/items/:id/${tab}`, location.pathname)) {
                navigate(`/items/${params.id}/${tab}`);
                trackEvent("item_click_tab", {
                  tab,
                });
              }
            }}
          ></Tabs>
        )}
        <Outlet
          context={
            {
              content,
              setContent,
              itemType,
              setItemType,
              button,
              setButton,
              card,
              setCard,
              loading,
              onSubmitAppearance,
              isTextInvalid,
              setIsTextInvalid,
              isLinkInvalid,
              setIsLinkInvalid,
              onSubmitContent,
              link,
              setLink,
            } satisfies CreateItemContextType
          }
        />
      </div>
      <div className="max-w-[360px] min-w-[360px] hidden md:block">
        <div className="sticky top-[128px] max-h-[650px] overflow-y-auto rounded-[20px] border-4 border-neutral3 bg-neutral1">
          <CreatorPage
            maxWidth={352}
            userId={user?.id ?? null}
            theme={theme}
            path={isAppearancePage ? "main" : "content/" + params.id}
            onChangePath={(path) => {
              if (path === "main") {
                trackEvent("item_click_preview_back");
                navigate(`/items/${params.id}/appearance`);
              } else {
                trackEvent("item_click_preview_item", {
                  item_type: itemType,
                });
                navigate(`/items/${params.id}/content`);
              }
            }}
            onSubmit={() => {
              if (item) {
                trackEvent("item_submit_preview", getItemMeta(item));
              }
            }}
            items={previewItems}
          ></CreatorPage>
        </div>
      </div>
      <BottomPanel visible={showBottomPanel}>
        <div className="flex items-center gap-3">
          <Icon
            id="check-all"
            color="var(--neutral4)"
          ></Icon>

          <div className="text-[13px] leading-[16px] flex flex-col md:flex-row mr-4 md:items-center gap-2">
            <span className="text-neutral4">
              {item?.createdAt.toString() === item?.updatedAt.toString()
                ? "Создан"
                : "Сохранен"}
            </span>
            {item &&
              format(item.updatedAt, breakpoint === "mobile" ? "P" : "PPPp", {
                locale: ru,
              })}
          </div>
        </div>
        <Button
          className="md:mr-14"
          disabled={loading}
          onClick={() => {
            if (tab === "content") {
              onSubmitContent();
            } else {
              onSubmitAppearance();
            }
          }}
        >
          Сохранить
        </Button>
      </BottomPanel>
    </Page>
  );
}
