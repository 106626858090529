import React, { ReactNode } from "react";
import TableHeader from "./TableHeader";
import { twMerge } from "tailwind-merge";

interface Props {
  children: ReactNode[] | ReactNode;
  headers?: ReactNode[] | ReactNode;
  className?: string;
}

export default function Table({ children, headers, className }: Props) {
  return (
    <table className={twMerge("w-full", className)}>
      {Boolean(headers) && <TableHeader children={headers}></TableHeader>}
      <tbody>{children}</tbody>
    </table>
  );
}
