import React, { ReactNode, useState } from "react";
import Icon from "./Icon";

import { AnimatePresence, motion } from "framer-motion";
import {
  FloatingPortal,
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";

interface Props {
  content: ReactNode;
  showDelay?: number;
  hideDelay?: number;
  placement?: Placement;
  label?: string;
  children?: ReactNode;
  iconColor?: string;
}

export default function Tooltip({
  content,
  showDelay = 200,
  hideDelay = 100,
  placement = "top",
  children,
  iconColor,
  label,
}: Props) {
  const [open, setOpen] = useState(false);

  const {
    x,
    y,
    refs,
    strategy,
    context,
    placement: computedPlacement,
  } = useFloating({
    placement,
    open,
    onOpenChange(open) {
      setOpen(open);
    },
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { delay: showDelay, restMs: hideDelay }),
    useFocus(context),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  const translate = {
    top: { translateY: 5 },
    bottom: { translateY: -5 },
    left: { translateX: 5 },
    right: { translateX: -5 },
  }[
    computedPlacement.includes("-")
      ? computedPlacement.split("-")[0]
      : computedPlacement
  ];

  return (
    <>
      <div
        className="inline-flex gap-1 items-center cursor-pointer min-w-0"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {label ? (
          <div className="text-[14px] leading-[20px] font-semibold">
            {label}
          </div>
        ) : (
          children
        )}
        <Icon
          id="info-fill"
          color={iconColor}
          size={16}
        ></Icon>
      </div>
      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ opacity: 0, ...translate }}
              animate={{ opacity: 1, translateX: 0, translateY: 0 }}
              exit={{ opacity: 0, ...translate }}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}
              className="z-50 bg-neutral6 py-2 px-3 rounded-md text-white max-w-[260px] text-[14px] leading-[16px]"
            >
              {content}
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  );
}
