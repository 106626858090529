import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import Label from "../ui/Label";
import Button from "../ui/Button";
import Avatar from "../ui/Avatar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../constants/breakpoints";
import { AuthContextType, useAuth } from "../../providers/AuthProvider";
import { useCreatorPage } from "../../store/useCreatorPage";
import Popover from "../ui/Popover";
import { trackEvent } from "../../lib/ym";
import { openSupportChat } from "../../lib/jivo";

interface Props {
  className?: string;
}

export default function Header({ className }: Props) {
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { signOut, isProfileLoading, subscription } =
    useAuth() as AuthContextType;
  const profile = useCreatorPage((state) => state.profile);
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);

  return (
    <div
      className={twMerge(
        "py-6 px-6 md:px-10 h-[57px] md:h-[96px] bg-neutral1 flex items-center justify-between",
        className,
      )}
    >
      <Link
        to="/"
        className="w-10 h-10 md:w-12 md:h-12 inline-flex md:hidden"
        onClick={() => {
          trackEvent("header_click", {
            target: "logo",
          });
        }}
      >
        <img src="/logo.svg"></img>
      </Link>
      {!isProfileLoading && (
        <>
          {(!subscription.subscription ||
            !subscription.subscriptionUntil ||
            Date.now() > subscription.subscriptionUntil.getTime()) && (
            <Link
              to="/pricing"
              className="hidden md:inline-flex"
              onClick={() => {
                trackEvent("header_click", {
                  target: "pricing",
                });
              }}
            >
              <Label>Бесплатный тариф</Label>
            </Link>
          )}
          {subscription.subscriptionUntil &&
            Date.now() < subscription.subscriptionUntil.getTime() &&
            subscription.subscription === 1 && (
              <Link
                to="/pricing"
                className="hidden md:inline-flex"
                onClick={() => {
                  trackEvent("header_click", {
                    target: "pricing",
                  });
                }}
              >
                <Label>Pro на месяц</Label>
              </Link>
            )}
          {subscription.subscriptionUntil &&
            Date.now() < subscription.subscriptionUntil.getTime() &&
            subscription.subscription === 2 && (
              <Link
                to="/pricing"
                className="hidden md:inline-flex"
                onClick={() => {
                  trackEvent("header_click", {
                    target: "pricing",
                  });
                }}
              >
                <Label>Pro на год</Label>
              </Link>
            )}
        </>
      )}

      <div className="flex items-center gap-4 ml-auto">
        {isProfileLoading ? null : profile.nickname ? (
          <>
            <Button
              to={`/${profile.nickname}`}
              target="_blank"
              theme="secondary"
              icon="open"
              onClick={() => {
                trackEvent("header_click", {
                  target: "link",
                });
              }}
            >
              {breakpoint === "desktop"
                ? `creatorstore.pro/${profile.nickname}`
                : profile.nickname}
            </Button>
            <Button
              theme="secondary"
              icon="copy"
              onClick={() => {
                trackEvent("header_click", {
                  target: "copy",
                });
                navigator.clipboard
                  .writeText(`creatorstore.pro/${profile.nickname}`)
                  .then(() => {
                    toast.success("Ссылка скопирована");
                  });
              }}
            ></Button>
          </>
        ) : (
          <Button
            to="/settings"
            theme="secondary"
          >
            Задайте никнейм
          </Button>
        )}
        <Popover
          open={isPopoverOpened}
          setOpen={setIsPopoverOpened}
          content={
            <div>
              <div
                className="flex px-6 py-3 hover:bg-neutral2 rounded-t-md cursor-pointer text-primary4"
                onClick={() => {
                  trackEvent("header_click_profile_menu", {
                    item: "buy_subscription",
                  });
                  navigate("/pricing");
                }}
              >
                Купить подписку
              </div>
              <div className="h-[1px]  bg-neutral2 my-3 mx-6"></div>
              <div
                className="flex px-6 py-3 hover:bg-neutral2 cursor-pointer"
                onClick={() => {
                  trackEvent("header_click_profile_menu", {
                    item: "settings",
                  });
                  navigate("/settings");
                }}
              >
                Настройки аккаунта
              </div>
              <div
                className="flex px-6 py-3 hover:bg-neutral2 cursor-pointer"
                onClick={() => {
                  openSupportChat();
                }}
              >
                Поддержка
              </div>

              <div
                className="flex px-6 py-3 hover:bg-neutral2 rounded-b-md cursor-pointer"
                onClick={() => {
                  trackEvent("header_click_profile_menu", {
                    item: "logout",
                  });
                  signOut();
                }}
              >
                Выйти
              </div>
            </div>
          }
        >
          <Avatar
            onClick={() => {
              trackEvent("header_click_profile_menu");
            }}
            src={profile.avatar}
            size={breakpoint === "mobile" ? 40 : 48}
          ></Avatar>
        </Popover>
      </div>
    </div>
  );
}
