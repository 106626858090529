import React, { useState } from "react";
import Title from "../../../components/ui/Title";
import Button from "../../../components/ui/Button";
import Tabs from "../../../components/ui/Tabs/Tabs";
import Input from "../../../components/ui/Input";
import Textarea from "../../../components/ui/Textarea";
import useRestoreScroll from "../../../hooks/useRestoreScroll";
import { useOutletContext } from "react-router-dom";
import { CreateItemContextType } from "./CreateItem";
import toast from "react-hot-toast";
import { supabase } from "../../../lib/supabase";
import { useAuth } from "../../../providers/AuthProvider";
import { useShallow } from "zustand/react/shallow";
import { useCreatorPage } from "../../../store/useCreatorPage";
import { trackEvent } from "../../../lib/ym";
import { useDebounceFunction } from "../../../hooks/useDebounce";
import Select from "../../../components/ui/Select/Select";
import { LinkType } from "../../../components/creator/CreatorPage";
import { IconList } from "../../../components/ui/Icon";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../../constants/breakpoints";
import InputUpload from "../../../components/ui/InputUpload";

const tabs = [
  {
    id: "button",
    label: "Кнопка",
    labelTooltip: "Занимает мало место – можно расположить несколько подряд",
  },
  {
    id: "card",
    label: "Карточка",
    labelTooltip:
      "Позволяет описать продукт полнее, добавить большое изображение",
  },
];

const linkOptions: { id: LinkType; label: string; icon: IconList }[] = [
  {
    id: null,
    label: "Ссылка",
    icon: "link",
  },
  {
    id: 1,
    label: "Телефон (кликабельный)",
    icon: "phone-call-outgoing",
  },
  {
    id: 2,
    label: "Почта (кликабельная)",
    icon: "mail",
  },
];

export default function Appearance() {
  const { user } = useAuth();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const {
    button,
    setButton,
    card,
    setCard,
    itemType,
    setItemType,
    onSubmitAppearance,
    loading,
    isTextInvalid,
    setIsTextInvalid,
    isLinkInvalid,
    setIsLinkInvalid,
    link,
    setLink,
  } = useOutletContext<CreateItemContextType>();

  useRestoreScroll();

  const [isFileLoading, setIsFileLoading] = useState(false);
  async function uploadFile(file: File): Promise<string | null> {
    console.log(`${user?.id}`, file);
    setIsFileLoading(true);
    const filePath = `${user?.id}/${Date.now()}`;
    const { error: uploadError } = await supabase.storage
      .from("images")
      .upload(filePath, file, {
        upsert: true,
      });
    setIsFileLoading(false);
    if (uploadError) {
      console.log({ uploadError });
      if ((uploadError as any)?.statusCode === 413) {
        toast.error("Файл слишком большой");
      } else {
        toast.error("Ошибка загрузки файла");
      }
      return null;
    }
    const { data } = await supabase.storage
      .from("images")
      .getPublicUrl(filePath);
    return data.publicUrl;
  }

  async function uploadButtonImage(file: File) {
    const url = await uploadFile(file);
    trackEvent("item_upload_icon", {
      item_type: "button",
      ...(!url ? { error: true } : {}),
    });
    setButton({
      ...button,
      icon: url,
    });
  }

  async function uploadCardImage(file: File) {
    const url = await uploadFile(file);
    trackEvent("item_upload_icon", {
      item_type: "card",
      ...(!url ? { error: true } : {}),
    });
    setCard({
      ...card,
      icon: url,
    });
  }

  async function uploadLinkImage(file: File) {
    const url = await uploadFile(file);
    trackEvent("item_upload_icon", {
      item_type: "link",
      ...(!url ? { error: true } : {}),
    });
    setLink({
      ...link,
      icon: url,
    });
  }

  const { isItemsLoading } = useCreatorPage(
    useShallow((state) => {
      return {
        isItemsLoading: state.isItemsLoading,
      };
    }),
  );

  const trackDescription = useDebounceFunction(() => {
    trackEvent("item_input", {
      item_type: "card",
      input: "card_description",
    });
  });
  const trackPrice = useDebounceFunction(() => {
    trackEvent("item_input", {
      item_type: "card",
      input: "card_price",
    });
  });
  const trackButtonText = useDebounceFunction(() => {
    trackEvent("item_input", {
      item_type: "card",
      input: "button_text",
    });
  });
  const trackText = useDebounceFunction(() => {
    trackEvent("item_input", {
      item_type: itemType,
      input:
        itemType === "card"
          ? "card_text"
          : itemType === "link"
            ? "link"
            : "button",
    });
  });
  const trackLink = useDebounceFunction(() => {
    trackEvent("item_input", {
      item_type: itemType,
      input: "link",
    });
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="p-6 rounded-lg bg-neutral1">
          <Title
            color="var(--secondary4)"
            number={1}
            className="mb-4 md:mb-8"
          >
            Внешний вид
          </Title>

          {itemType === "link" ? (
            <div className="flex flex-col md:gap-4 gap-8">
              <InputUpload
                loading={isFileLoading}
                accept="image/jpeg,image/png,image/svg+xml,image/webp"
                filePath={link.icon}
                onDelete={() => {
                  setLink({
                    ...link,
                    icon: null,
                  });
                }}
                onInput={(file: File) => {
                  uploadLinkImage(file);
                }}
              >
                Иконка
              </InputUpload>
              <Input
                grow
                error={isTextInvalid}
                label="Текст на кнопке"
                placeholder="Что будет отображаться на кнопке"
                value={link.text}
                onInput={(text) => {
                  setIsTextInvalid(false);
                  setLink({
                    ...link,
                    text: text || null,
                  });
                  trackText();
                }}
              ></Input>
              <Select
                value={link.linkType}
                onInput={(linkType) => {
                  setLink({
                    ...link,
                    linkType: linkType as LinkType,
                  });
                }}
                options={linkOptions}
              ></Select>
              <Input
                grow
                error={isLinkInvalid}
                label="Ссылка"
                placeholder="Введите ссылку"
                value={link.link}
                onInput={(linkText) => {
                  setIsLinkInvalid(false);
                  setLink({
                    ...link,
                    link: linkText || null,
                  });
                  trackLink();
                }}
              ></Input>
            </div>
          ) : (
            <>
              <Tabs
                className="mb-8"
                theme="gray"
                options={tabs}
                value={itemType}
                onInput={(tab) => {
                  setItemType(tab);
                  trackEvent("item_click_type_tab", {
                    tab,
                  });
                }}
              ></Tabs>
              <InputUpload
                loading={isFileLoading}
                className="mb-4 md:mb-8"
                accept="image/jpeg,image/png,image/svg+xml,image/webp"
                filePath={
                  itemType === "button"
                    ? button.icon
                    : itemType === "link"
                      ? link.icon
                      : card.icon
                }
                onDelete={() => {
                  if (itemType === "button") {
                    setButton({
                      ...button,
                      icon: null,
                    });
                  } else if (itemType === "link") {
                    setLink({
                      ...link,
                      icon: null,
                    });
                  } else {
                    setCard({
                      ...card,
                      icon: null,
                    });
                  }
                }}
                onInput={(file: File) => {
                  if (itemType === "button") {
                    uploadButtonImage(file);
                  } else if (itemType === "link") {
                    uploadLinkImage(file);
                  } else {
                    uploadCardImage(file);
                  }
                }}
              >
                Иконка
              </InputUpload>
              {itemType === "button" ? (
                <Input
                  grow
                  error={isTextInvalid}
                  label="Текст на кнопке"
                  placeholder="Что будет отображаться на кнопке"
                  value={button.text}
                  onInput={(text) => {
                    setIsTextInvalid(false);
                    setButton({
                      ...button,
                      text: text || null,
                    });
                    trackText();
                  }}
                ></Input>
              ) : (
                <div className="flex flex-col gap-4 md:gap-8">
                  <Input
                    grow
                    label="Заголовок"
                    placeholder="Название продукта"
                    value={card.text}
                    onInput={(text) => {
                      setCard({
                        ...card,
                        text: text || null,
                      });
                      trackText();
                    }}
                  ></Input>
                  <Input
                    grow
                    type="number"
                    label="Цена, ₽"
                    value={String(card.price)}
                    onInput={(price) => {
                      setCard({
                        ...card,
                        price: Number(price),
                      });
                      trackPrice();
                    }}
                  ></Input>
                  <Textarea
                    grow
                    label="Описание"
                    value={card.description}
                    onInput={(description) => {
                      setCard({
                        ...card,
                        description: description || null,
                      });
                      trackDescription();
                    }}
                  ></Textarea>
                  <Input
                    grow
                    label="Текст на кнопке"
                    placeholder="Что будет отображаться на кнопке"
                    value={card.button}
                    onInput={(button) => {
                      setCard({
                        ...card,
                        button: button || null,
                      });
                      trackButtonText();
                    }}
                  ></Input>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="mt-6 flex justify-between">
        <Button
          theme="secondary"
          icon="left"
          iconSize={18}
          to="/"
          onClick={() => {
            trackEvent("item_click_back");
          }}
        >
          {breakpoint !== "mobile" ? "Мой магазин" : null}
        </Button>
        <Button
          disabled={loading || isItemsLoading}
          icon="right"
          iconAlignment="right"
          onClick={() => {
            onSubmitAppearance();
          }}
        >
          Сохранить и продолжить
        </Button>
      </div>
    </div>
  );
}
