import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Title from "../../components/ui/Title";
import Button from "../../components/ui/Button";
import Profile from "../../components/ui/Profile";
import { Reorder } from "framer-motion";
import GrabItem, {
  GrabItemType,
  getGrabItemsFromCreatorPageItems,
} from "./GrabItem";
import Page from "../../components/layout/Page";
import useRestoreScroll from "../../hooks/useRestoreScroll";
import CreatorPage, {
  Profile as ProfileType,
} from "../../components/creator/CreatorPage";
import PreviewWrapper from "../../components/creator/PreviewWrapper";
import { useShallow } from "zustand/react/shallow";
import { useCreatorPage } from "../../store/useCreatorPage";
import { useAuth } from "../../providers/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useApplications } from "../../store/useApplications";
import { useModal } from "@ebay/nice-modal-react";
import Label from "../../components/ui/Label";
import Icon from "../../components/ui/Icon";
import { trackEvent } from "../../lib/ym";
import { useQuery } from "../../hooks/useQuery";

export default function Store() {
  useRestoreScroll();
  const navigate = useNavigate();
  const count = useApplications((state) => state.count);
  const setCount = useApplications((state) => state.setCount);

  const { user, isSubscriptionActive } = useAuth();
  const { loading, isProfileLoading, isProfileInvalidated } = useAuth();
  const {
    profile,
    theme,
    items,
    fetchItems,
    isItemsInvalidated,
    isItemsLoading,
    createItem,
    isCreating,
    updateItemList,
    itemList,
    deleteItem,
    setProfile,
  } = useCreatorPage(
    useShallow((state) => ({
      profile: state.profile,
      theme: state.theme,
      items: state.items,
      fetchItems: state.fetchItems,
      isCreating: state.isCreating,
      createItem: state.createItem,
      isItemsInvalidated: state.isItemsInvalidated,
      isItemsLoading: state.isItemsLoading,
      updateItemList: state.updateItemList,
      itemList: state.itemList,
      deleteItem: state.deleteItem,
      setProfile: state.setProfile,
    })),
  );

  const activeItems = useMemo(() => {
    return items.filter((iterated) => !iterated.deleted);
  }, [items]);

  const [cards, setCards] = useState(
    getGrabItemsFromCreatorPageItems(activeItems, itemList),
  );

  useEffect(() => {
    if (!isProfileLoading && itemList && activeItems) {
      setCards(getGrabItemsFromCreatorPageItems(activeItems, itemList));
    }
  }, [isProfileLoading, activeItems, itemList]);

  const [path, setPath] = useState("main");
  const dialog = useModal("dialog");
  const location = useLocation();
  const handleCreateItem = useCallback(async () => {
    if (user?.id) {
      if (activeItems.length >= 10) {
        trackEvent("store_click_add_item", {
          failed: "limit",
        });
        dialog.show({
          title: "Вы не можете создать новый элемент",
          text: "Вы уже создали 10 элементов, это максимум. Удалите один из активных элементов или измените существующий",
        });
        return;
      }
      trackEvent("store_click_add_item");
      itemTypeModal.show({
        onContinue: (itemType: "product" | "link") => {
          createItem(user.id, itemType === "link" ? itemType : undefined).then(
            (item) => {
              if (item) {
                navigate(`/items/${item.id}/appearance`);
                updateItemList(user.id, [
                  ...activeItems.map((iterated) => iterated.id),
                  item.id,
                ]);
              } else {
                toast.error("Ошибка при создании элемента");
              }
            },
          );
        },
        onCancel: () => {
          if (query.get("create-item") === "true") {
            navigate(location.pathname, { replace: true });
          }
        },
      });
    }
  }, [user?.id]);

  const handleDeleteItem = useCallback(
    async (itemId: number) => {
      if (user?.id) {
        deleteItem(user.id, itemId).then((items) => {
          if (items) {
            toast.success("Элемент удален");
            updateItemList(
              user.id,
              activeItems.map((iterated) => iterated.id),
            );
          } else {
            toast.error(
              "Ошибка при удалении элемента. Перезагрузите страницу или попробуйте позже",
            );
          }
        });
      }
    },
    [user?.id],
  );

  useEffect(() => {
    if (!isItemsLoading && isItemsInvalidated && user?.id) {
      fetchItems(user.id).then((items) => {
        if (!items) {
          toast.error(
            "Ошибка при загрузке страницы. Перезагрузите страницу или попробуйте позже",
          );
        }
      });
    }
  }, [isItemsInvalidated, user?.id]);

  const modal = useModal("set-nickname-modal");
  const itemTypeModal = useModal("item-type-modal");
  const query = useQuery();

  useEffect(() => {
    if (isProfileLoading || !user || isProfileInvalidated) {
      return;
    }
    console.log({ query });
    if (!profile.nickname || !profile.name) {
      modal.show({
        defaultProfile: profile,
        onFinish: (newProfile: ProfileType) => {
          // TODO: bug – modal not closing
          setTimeout(() => {
            setProfile(newProfile);
          });
          //
          handleCreateItem();
        },
      });
    } else if (query.get("create-item") === "true") {
      handleCreateItem();
    }
  }, [isProfileLoading, profile, isProfileInvalidated, user, query]);

  const isReorderingTimeout = useRef<NodeJS.Timeout | null>(null);
  function handleUpdateOrder(cards: GrabItemType[]) {
    if (user) {
      updateItemList(
        user.id,
        cards.map((card) => card.id),
      );
      trackEvent("store_reorder");
    }
  }

  return (
    <Page className="flex-col lg:flex-row gap-6">
      <div className="flex-1 flex flex-col gap-6 min-w-0">
        <Title size="lg">Мой магазин</Title>
        <div className="inline-flex md:hidden">
          <Button
            icon="palette"
            grow
            theme="black"
            to="/design-mobile"
            onClick={() => {
              trackEvent("store_click_design");
            }}
          >
            Настроить дизайн
          </Button>
        </div>
        {loading || isProfileLoading ? (
          <div className="flex flex-col gap-3">
            <div className="animate-pulse rounded-xl bg-neutral1 h-[60px] w-full"></div>
            <div className="animate-pulse rounded-xl bg-neutral1 h-[60px] w-full"></div>
            <div className="animate-pulse rounded-xl bg-neutral1 h-[60px] w-full"></div>
          </div>
        ) : (
          <div className="relative bg-neutral1 rounded-xl py-8 px-6 flex flex-col gap-4 items-center">
            <Profile
              name={profile.name ?? ""}
              bio={profile.bio ?? undefined}
              avatar={profile.avatar ?? undefined}
              links={profile.links}
            ></Profile>
            <Button
              className="absolute right-6 top-6"
              icon="edit"
              iconAlignment="right"
              theme="secondary"
              to="/settings"
              onClick={() => {
                trackEvent("store_click_design");
              }}
            ></Button>
          </div>
        )}

        {Boolean(cards.length) && (
          <Reorder.Group
            values={cards}
            onReorder={(cards) => {
              if (user?.id) {
                setCards(cards);
                if (isReorderingTimeout.current) {
                  clearTimeout(isReorderingTimeout.current);
                }
                isReorderingTimeout.current = setTimeout(() => {
                  handleUpdateOrder(cards);
                }, 1000);
              }
            }}
            className="flex flex-col gap-4 min-w-0"
          >
            {cards.map((card) => (
              <GrabItem
                key={card.id}
                value={card as GrabItemType}
                onDeleteItem={() => {
                  handleDeleteItem(card.id);
                  trackEvent("store_click_item_menu", {
                    item: "delete",
                  });
                }}
                onEditItem={() => {
                  navigate(`/items/${card.id}/appearance`);
                  trackEvent("store_click_item_menu", {
                    item: "edit",
                  });
                }}
                onClick={() => {
                  navigate(`/items/${card.id}/appearance`);
                  trackEvent("store_click_item");
                }}
                onTriggerClick={() => {
                  trackEvent("store_click_item_menu");
                }}
              ></GrabItem>
            ))}
          </Reorder.Group>
        )}
        {!isProfileLoading &&
          !isSubscriptionActive &&
          activeItems.length >= 2 && (
            <Label
              size="md"
              color="var(--secondary3)"
              grow
            >
              <div className="flex gap-2 w-full justify-center">
                <Icon
                  id="lock-fill"
                  color="var(--neutral5)"
                ></Icon>
                <div className="text-[15px] leading-[24px] font-medium">
                  В бесплатной версии вы можете добавить только 2 продукта или
                  услуги
                </div>
              </div>
            </Label>
          )}
        {!isProfileLoading ? (
          isSubscriptionActive || activeItems.length <= 1 ? (
            <Button
              icon="plus"
              grow
              onClick={handleCreateItem}
              disabled={isCreating}
            >
              Добавить продукт или услугу
            </Button>
          ) : (
            <Button
              grow
              to="/pricing"
              icon="fire-fill"
            >
              Обновить до PRO
            </Button>
          )
        ) : null}
      </div>
      <div className="hidden md:flex flex-col gap-6 max-w-[360px] md:min-w-[360px]">
        {loading || isProfileLoading ? (
          <div className="flex flex-col gap-3">
            <div className="animate-pulse rounded-xl h-[60px] bg-neutral1"></div>
            <div className="animate-pulse rounded-xl h-[320px] bg-neutral1"></div>
          </div>
        ) : (
          <>
            <Button
              icon="palette"
              grow
              theme="black"
              to="/design"
              onClick={() => {
                trackEvent("store_click_design");
              }}
            >
              Настроить дизайн
            </Button>
            <PreviewWrapper enableScroll>
              <CreatorPage
                maxWidth={352}
                userId={user?.id ?? null}
                profile={profile}
                theme={theme}
                items={activeItems}
                path={path}
                onChangePath={(path) => {
                  setPath(path);
                  if (path !== "main") {
                    trackEvent("store_click_preview_item");
                  }
                }}
                onSubmit={() => {
                  setCount(count + 1);
                  trackEvent("store_submit_preview_form");
                }}
              ></CreatorPage>
            </PreviewWrapper>
          </>
        )}
      </div>
    </Page>
  );
}
