import React from "react";
import Button, { ButtonTheme } from "./Button";

export interface ButtonType {
  text: string | null;
  icon: string | null;
  theme?: ButtonTheme;
  fit?: boolean;
  onClick?: () => any;
  to?: string;
  href?: string;
  target?: string;
}

export default function ButtonWithImage({
  icon,
  text,
  theme = "secondary",
  fit,
  onClick,
  to,
  target,
  href,
}: ButtonType) {
  return (
    <Button
      grow={!fit}
      theme={theme}
      onClick={onClick}
      to={to}
      href={href}
      target={target}
    >
      <div className="flex items-center gap-1">
        {icon && (
          <img
            className="w-[28px] h-[28px] rounded-full object-cover"
            src={icon}
          ></img>
        )}
        {text}
      </div>
    </Button>
  );
}
