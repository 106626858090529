import React, { useMemo, useState } from "react";
import { CreatorPageTheme, isDarkTheme } from "./CreatorPage";
import { Content } from "../../pages/store/create-item/CreateItem";
import { twMerge } from "tailwind-merge";
import Button, { ButtonTheme } from "../ui/Button";
import Input from "../ui/Input";
import { supabase } from "../../lib/supabase";
import toast from "react-hot-toast";
import { useModal } from "@ebay/nice-modal-react";

interface Props {
  itemId: number;
  itemContent: Content;
  onChangePath: (path: string) => void;
  theme: CreatorPageTheme;
  cardButtonTheme: ButtonTheme;
  userId: string;
  onSubmit?: () => void;
}

export default function PreviewContent({
  itemId,
  itemContent,
  onChangePath,
  theme,
  cardButtonTheme,
  userId,
  onSubmit,
}: Props) {
  const modal = useModal("application-sent-modal");
  const inputTheme = useMemo(() => {
    if (theme === "dark") {
      return "black";
    }
    if (theme === "sand") {
      return "white";
    }
    if (theme === "black") {
      return "dark";
    }
    return "default";
  }, [theme]);

  const [form, setForm] = useState<{
    name?: string | null;
    phone?: string | null;
    mail?: string | null;
    comment?: string | null;
    isNameInvalid: boolean;
    isMailInvalid: boolean;
    isPhoneInvalid: boolean;
    isCommentInvalid: boolean;
  }>({
    name: itemContent.fields.name ? null : undefined,
    isNameInvalid: false,
    mail: itemContent.fields.mail ? null : undefined,
    isMailInvalid: false,
    phone: itemContent.fields.phone ? null : undefined,
    isPhoneInvalid: false,
    comment: itemContent.fields.comment ? null : undefined,
    isCommentInvalid: false,
  });
  const [loading, setLoading] = useState(false);

  const isMailEmpty = useMemo(() => {
    return !form.mail || !form.mail.trim().length;
  }, [form.mail]);
  const isPhoneEmpty = useMemo(() => {
    return !form.phone || !form.phone.trim().length;
  }, [form.phone]);
  const hasMailInForm = useMemo(() => {
    return itemContent.fields.mail;
  }, [itemContent.fields.mail]);
  const hasPhoneInForm = useMemo(() => {
    return itemContent.fields.phone;
  }, [itemContent.fields.phone]);

  function validateMail() {
    if (hasMailInForm && isMailEmpty) {
      return false;
    }
    return true;
  }
  function validatePhone() {
    if (hasPhoneInForm && isPhoneEmpty) {
      return false;
    }
    return true;
  }

  function validate() {
    return {
      isMailInvalid: !validateMail(),
      isPhoneInvalid: !validatePhone(),
    };
  }

  async function handleSubmit() {
    const validations = validate();
    setForm({
      ...form,
      isMailInvalid: validations.isMailInvalid,
      isPhoneInvalid: validations.isPhoneInvalid,
    });
    if (validations.isMailInvalid || validations.isPhoneInvalid) {
      return;
    }
    setLoading(true);
    const { data, error } = await supabase.from("applications").insert([
      {
        user_id: userId,
        item_id: itemId,
        name: form.name,
        mail: form.mail,
        phone: form.phone,
        comment: form.comment,
      },
    ]);
    console.log({ error, data });
    if (error) {
      toast.error("Ошибка при отправке формы");
      console.log({ error });
      setLoading(false);
      return;
    }
    if (onSubmit) {
      onSubmit();
    }
    modal.show();
    // toast.success("Заявка успешно отправлена!");
    setLoading(false);
    onChangePath("main");
  }

  return (
    <>
      {onChangePath && (
        <Button
          theme={isDarkTheme(theme) ? "black" : "secondary"}
          icon="left"
          size="sm"
          iconSize={16}
          onClick={() => {
            onChangePath("main");
          }}
        >
          Назад
        </Button>
      )}
      <form
        className="flex flex-col gap-6 w-full pt-6"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {itemContent.cover && (
          <img
            src={itemContent.cover}
            className="rounded-xl object-cover"
          ></img>
        )}
        <div className="flex flex-col gap-2">
          <div
            className={twMerge(
              "text-[20px] leading-[32px]",
              isDarkTheme(theme) && "text-neutral2",
            )}
          >
            {itemContent.title}
          </div>
          <div
            className={twMerge(
              "font-medium text-neutral4",
              isDarkTheme(theme) ? "text-neutral3" : "text-neutral4",
            )}
          >
            {itemContent.description}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          {itemContent.fields.name && (
            <Input
              error={form.isNameInvalid}
              theme={inputTheme}
              label="Имя"
              value={form.name ?? ""}
              onInput={(name) => {
                setForm({
                  ...form,
                  name,
                });
              }}
            ></Input>
          )}
          {itemContent.fields.mail && (
            <Input
              error={form.isMailInvalid}
              theme={inputTheme}
              label="Почта"
              value={form.mail ?? ""}
              onInput={(mail) => {
                setForm({
                  ...form,
                  isMailInvalid: false,
                  mail,
                });
              }}
            ></Input>
          )}
          {itemContent.fields.phone && (
            <Input
              error={form.isPhoneInvalid}
              theme={inputTheme}
              label="Телефон"
              value={form.phone ?? ""}
              onInput={(phone) => {
                setForm({
                  ...form,
                  isPhoneInvalid: false,
                  phone,
                });
              }}
            ></Input>
          )}
          {itemContent.fields.comment && (
            <Input
              error={form.isCommentInvalid}
              theme={inputTheme}
              label="Комментарий"
              value={form.comment ?? ""}
              onInput={(comment) => {
                setForm({
                  ...form,
                  comment,
                });
              }}
            ></Input>
          )}
        </div>
        {itemContent.button && (
          <Button
            theme={cardButtonTheme}
            grow
            disabled={loading}
            type="submit"
          >
            {itemContent.button}
          </Button>
        )}
      </form>
    </>
  );
}
