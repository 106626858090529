import React from "react";
import Label from "./Label";
import Button, { ButtonTheme } from "./Button";
import { twMerge } from "tailwind-merge";

export interface CardType {
  text: string | null;
  price: number;
  description: string | null;
  button: string | null;
  icon: string | null;
  bg?: string;
  buttonTheme?: ButtonTheme;
  dark?: boolean;
  onClick?: () => any;
}

export default function Card({
  text,
  icon,
  description,
  price,
  button,
  bg,
  buttonTheme,
  dark,
  onClick,
}: CardType) {
  return (
    <div
      className={twMerge(
        "border p-4 rounded-[10px] flex flex-col gap-4",
        dark ? "bg-neutral7 border-neutral6" : "bg-white border-neutral3",
      )}
      style={{ background: bg, borderColor: bg }}
    >
      {icon && (
        <img
          src={icon}
          className="w-full object-cover rounded-xl"
        ></img>
      )}
      {(text || description) && (
        <div className="flex flex-col gap-2">
          <div
            className={twMerge(
              "text-[20px] leading-[32px] font-semibold",
              dark && "text-neutral2",
            )}
          >
            {text}
          </div>
          <div
            className={twMerge(
              "font-medium",
              dark ? "text-neutral3" : "text-neutral4",
            )}
          >
            {description}
          </div>
        </div>
      )}
      <Label
        color="var(--secondary4)"
        size="sm"
      >
        {price ? price + "₽" : "Бесплатно"}
      </Label>
      {button && (
        <Button
          theme={buttonTheme}
          grow
          onClick={onClick}
        >
          {button}
        </Button>
      )}
    </div>
  );
}
