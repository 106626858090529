import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: ReactNode;
  size?: "md" | "sm";
  className?: string;
  color?: string;
  nowrap?: boolean;
  grow?: boolean;
}

export default function Label({
  children,
  size = "md",
  className,
  color,
  nowrap,
  grow,
}: Props) {
  return (
    <div
      className={twMerge(
        "bg-neutral3 rounded-lg inline-flex items-center text-[15px] leading-[24px] font-semibold",
        size === "md"
          ? "px-2 py-1 min-h-[24px] md:px-4 md:min-h-[40px]"
          : "px-2 py-1 min-h-[24px]",
        nowrap && "whitespace-nowrap",
        grow ? "w-full text-center" : "w-fit",
        className,
      )}
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  );
}
