import React, { useEffect } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Layout from "./Layout";
import Pricing from "./pages/pricing/Pricing";
import Store from "./pages/store/Store";
import Applicants from "./pages/applicants/NewApplicants";
import CompletedApplicants from "./pages/applicants/CompletedApplicants";
import { Toaster } from "react-hot-toast";
import CreateItem from "./pages/store/create-item/CreateItem";
import Appearance from "./pages/store/create-item/Appearance";
import Content from "./pages/store/create-item/Content";
import Creator from "./pages/creator/Creator";
import LoginPage from "./pages/auth/LoginPage";
import { AuthProvider } from "./providers/AuthProvider";
import { PrivateRoute } from "./components/PrivateRoute";
import MagicLinkPage from "./pages/auth/MagicLinkPage";
import AuthLayout from "./pages/auth/AuthLayout";
import SignUpPage from "./pages/auth/SignUpPage";
import RecoveryPage from "./pages/auth/RecoveryPage";
import ChangePassword from "./pages/settings/ChangePassword";
import SettingsBasicsPage from "./pages/settings/Basics";
import NiceModal from "@ebay/nice-modal-react";
import SubscriptionSuccessModal from "./components/modals/SubscriptionSuccessModal";
import DialogModal from "./components/modals/DialogModal";
import SetNicknameModal from "./components/modals/SetNicknameModal";
import Terms from "./pages/Terms";
import ApplicationSentModal from "./components/modals/ApplicationSentModal";
import NotFound from "./pages/NotFound";
import Root from "./Root";
import ItemTypeModal from "./components/modals/ItemTypeModal";
import PreviewPage from "./pages/Preview";
import DesignMobile from "./pages/design/DesignMobile";
import Design from "./pages/design/Design";

NiceModal.register("subscription-success-modal", SubscriptionSuccessModal);
NiceModal.register("application-sent-modal", ApplicationSentModal);
NiceModal.register("dialog", DialogModal);
NiceModal.register("set-nickname-modal", SetNicknameModal);
NiceModal.register("item-type-modal", ItemTypeModal);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root></Root>,
    children: [
      {
        path: "/docs/terms",
        element: <Terms></Terms>,
      },
      {
        path: "/design",
        element: (
          <PrivateRoute>
            <Design></Design>
          </PrivateRoute>
        ),
      },
      {
        path: "/preview",
        element: (
          <PrivateRoute>
            <PreviewPage></PreviewPage>
          </PrivateRoute>
        ),
      },
      {
        path: "/auth",
        element: <AuthLayout></AuthLayout>,
        children: [
          {
            index: true,
            element: (
              <Navigate
                to="/auth/login"
                replace
              ></Navigate>
            ),
          },
          {
            path: "/auth/login",
            element: <LoginPage></LoginPage>,
          },
          {
            path: "/auth/magic-link",
            element: <MagicLinkPage></MagicLinkPage>,
          },
          {
            path: "/auth/signup",
            element: <SignUpPage></SignUpPage>,
          },
          {
            path: "/auth/recovery",
            element: <RecoveryPage></RecoveryPage>,
          },
        ],
      },
      {
        path: "/change-password",
        element: (
          <PrivateRoute>
            <ChangePassword></ChangePassword>
          </PrivateRoute>
        ),
      },
      {
        element: <Layout />,
        children: [
          {
            path: "/",
            element: (
              <PrivateRoute>
                <Store></Store>
              </PrivateRoute>
            ),
          },
          {
            path: "/items/:id",
            element: (
              <PrivateRoute>
                <CreateItem></CreateItem>
              </PrivateRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <Navigate
                    to="/items/:id/appearance"
                    replace
                  ></Navigate>
                ),
              },
              {
                path: "/items/:id/appearance",
                element: <Appearance></Appearance>,
              },
              {
                path: "/items/:id/content",
                element: <Content></Content>,
              },
            ],
          },
          {
            path: "/design-mobile",
            element: (
              <PrivateRoute>
                <DesignMobile></DesignMobile>
              </PrivateRoute>
            ),
          },
          {
            path: "/pricing",
            element: (
              <PrivateRoute>
                <Pricing></Pricing>
              </PrivateRoute>
            ),
          },
          {
            path: "/applicants",
            element: (
              <Navigate
                to="/applicants/new"
                replace
              />
            ),
          },
          {
            path: "/applicants/new",
            element: (
              <PrivateRoute>
                <Applicants></Applicants>
              </PrivateRoute>
            ),
          },
          {
            path: "/applicants/completed",
            element: (
              <PrivateRoute>
                <CompletedApplicants></CompletedApplicants>
              </PrivateRoute>
            ),
          },
          {
            path: "/settings",
            element: (
              <PrivateRoute>
                <SettingsBasicsPage></SettingsBasicsPage>
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        path: "/not-found",
        element: <NotFound></NotFound>,
      },
      {
        path: "/:id",
        element: <Creator></Creator>,
      },
      {
        path: "*",
        element: (
          <Navigate
            replace
            to="/not-found"
          ></Navigate>
        ),
      },
    ],
  },
]);

export const adminUrls = [
  "docs",
  "not-found",
  "settings",
  "applicants",
  "pricing",
  "design",
  "auth",
  "change-password",
  "items",
  "pricing",
];

function App() {
  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      adminUrls.find((url) => window.location.pathname.includes(url))
    ) {
      const script = document.createElement("script");

      script.src = "//code.jivo.ru/widget/JgMazL1viY";
      script.async = true;

      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <Toaster></Toaster>
      <AuthProvider>
        <NiceModal.Provider>
          <RouterProvider router={router}></RouterProvider>
        </NiceModal.Provider>
      </AuthProvider>
    </>
  );
}

export default App;
