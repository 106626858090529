import { create } from "zustand";
import {
  CreatorPageItem,
  CreatorPageItemType,
  CreatorPageTheme,
  CreatorPageType,
  LinkAppearance,
  Profile,
} from "../components/creator/CreatorPage";
import { supabase } from "../lib/supabase";
import {
  mapContentFromServer,
  mapContentToServer,
  mapItemFromServer,
  mapItemTypeToServer,
} from "../helpers/mappers";
import { ButtonType } from "../components/ui/ButtonWithImage";
import { CardType } from "../components/ui/Card";
import { Content } from "../pages/store/create-item/CreateItem";

interface CreatorPageStoreType extends CreatorPageType {
  path: string;
  setPath: (path: string) => void;

  reset: () => void;

  items: CreatorPageItem[];
  setItems: (items: CreatorPageItem[]) => void;
  isCreating: boolean;
  itemList: number[];
  setItemList: (items: number[]) => void;
  updateItemList: (
    userId: string,
    itemIds: number[],
  ) => Promise<number[] | null>;
  createItem: (
    userId: string,
    itemType?: CreatorPageItemType,
  ) => Promise<CreatorPageItem | null>;
  deleteItem: (
    userId: string,
    itemId: number,
  ) => Promise<CreatorPageItem[] | null>;
  publishItem: (
    userId: string,
    itemId: number,
  ) => Promise<CreatorPageItem | null>;
  updateCardItem: (
    userId: string,
    itemId: number,
    item: CardType,
  ) => Promise<CreatorPageItem | null>;
  updateButtonItem: (
    userId: string,
    itemId: number,
    item: ButtonType,
  ) => Promise<CreatorPageItem | null>;
  updateAndPublishLinkItem: (
    userId: string,
    itemId: number,
    item: LinkAppearance,
  ) => Promise<CreatorPageItem | null>;
  isUpdating: boolean;
  isContentUpdating: boolean;
  isContentCreating: boolean;
  updateItemContent: (
    userId: string,
    itemId: number,
    content: Content,
  ) => Promise<Content | null>;
  fetchItems: (userId: string) => Promise<CreatorPageItem[] | null>;
  isItemsInvalidated: boolean;
  isItemsLoading: boolean;

  theme: CreatorPageTheme;
  setTheme: (theme: CreatorPageTheme) => void;

  profile: Profile;
  setProfile: (profile: Profile) => void;

  hasStoreLabel: boolean;
  setHasStoreLabel: (flag: boolean) => void;
}

export const useCreatorPage = create<CreatorPageStoreType>((set, get) => ({
  path: "main",
  setPath: (path) => {
    set({ path });
  },

  items: [],
  isItemsInvalidated: true,
  isItemsLoading: false,
  setItems: (items) => {
    set({ items: [...items] });
  },
  isCreating: false,
  createItem: async (userId, itemType) => {
    const { data, error } = await supabase
      .from("page_items")
      .insert([
        {
          user_id: userId,
          item_type: itemType ? mapItemTypeToServer(itemType) : 1,
        },
      ])
      .select();
    console.log({ data, error });
    if (data) {
      const createdItem = mapItemFromServer(data[0]);
      set({
        items: [...get().items, createdItem],
      });
      return createdItem;
    }
    return null;
  },
  deleteItem: async (userId, itemId) => {
    const { data, error } = await supabase
      .from("page_items")
      .update({
        is_deleted: true,
      })
      .eq("user_id", userId)
      .eq("id", itemId)
      .select();
    console.log({ data, error });
    if (!error) {
      const result = get().items.filter((iterated) => iterated.id !== itemId);
      set({
        items: result,
      });
      return result;
    }
    return null;
  },
  itemList: [],
  setItemList: (items) => {
    set({ itemList: items });
  },
  updateItemList: async (userId, itemIds) => {
    const { data, error } = await supabase
      .from("users")
      .update([{ items: itemIds }])
      .eq("id", userId)
      .select();
    console.log({ data, error });
    if (data) {
      set({ itemList: data[0].items });
      return data[0].items;
    }
    return null;
  },
  isUpdating: false,
  isContentUpdating: false,
  isContentCreating: false,
  publishItem: async (userId, itemId) => {
    const { data } = await supabase
      .from("page_items")
      .update({
        is_published: true,
      })
      .eq("user_id", userId)
      .eq("id", itemId)
      .select();
    if (data) {
      const result = mapItemFromServer(data[0]);
      set({
        items: get().items.map((iterated) => {
          return iterated.id === itemId
            ? {
                ...result,
                ...(iterated.type !== "link"
                  ? { content: iterated.content }
                  : {}),
              }
            : iterated;
        }),
      });
      return result;
    }
    return null;
  },
  updateButtonItem: async (
    userId: string,
    itemId: number,
    item: ButtonType,
  ) => {
    const { data } = await supabase
      .from("page_items")
      .update({
        item_type: 1,
        icon: item.icon,
        text: item.text,
      })
      .eq("user_id", userId)
      .eq("id", itemId)
      .select();
    if (data) {
      const result = mapItemFromServer(data[0]);
      set({
        items: get().items.map((iterated) => {
          return iterated.id === itemId && iterated.type !== "link"
            ? {
                ...result,
                content: iterated.content,
              }
            : iterated;
        }),
      });
      return result;
    }
    return null;
  },
  updateCardItem: async (userId: string, itemId: number, item: CardType) => {
    const { data } = await supabase
      .from("page_items")
      .update({
        item_type: 2,
        icon: item.icon,
        text: item.text,
        description: item.description,
        button: item.button,
        price: item.price,
      })
      .eq("user_id", userId)
      .eq("id", itemId)
      .select();
    if (data) {
      const result = mapItemFromServer(data[0]);
      set({
        items: get().items.map((iterated) => {
          return iterated.id === itemId && iterated.type !== "link"
            ? {
                ...result,
                content: iterated.content,
              }
            : iterated;
        }),
      });
      return result;
    }
    return null;
  },
  updateAndPublishLinkItem: async (
    userId: string,
    itemId: number,
    item: LinkAppearance,
  ) => {
    const { data } = await supabase
      .from("page_items")
      .update({
        item_type: 3,
        icon: item.icon,
        text: item.text,
        link: item.link,
        link_type: item.linkType,
        is_published: true,
      })
      .eq("user_id", userId)
      .eq("id", itemId)
      .select();
    if (data) {
      const result = mapItemFromServer(data[0]);
      set({
        items: get().items.map((iterated) => {
          return iterated.id === itemId
            ? {
                ...result,
              }
            : iterated;
        }),
      });
      return result;
    }
    return null;
  },
  updateItemContent: async (
    userId: string,
    itemId: number,
    content: Content,
  ) => {
    set({ isContentUpdating: true });
    const { data } = await supabase
      .from("page_items")
      .update(mapContentToServer(content))
      .eq("user_id", userId)
      .eq("id", itemId)
      .select();
    if (data) {
      const result = mapContentFromServer(data[0]);
      set({
        isContentUpdating: false,
        items: get().items.map((iterated) => {
          return iterated.id === itemId
            ? {
                ...iterated,
                content: result,
              }
            : iterated;
        }),
      });
      return result;
    }
    set({ isContentUpdating: false });
    return null;
  },
  fetchItems: async (userId: string) => {
    set({ isItemsLoading: true });

    const { data, error } = await supabase
      .from("page_items")
      .select()
      .eq("user_id", userId);
    if (data && !error) {
      const resultItems = data.map((item) => {
        return mapItemFromServer(item);
      });
      set({
        items: resultItems,
        isItemsLoading: false,
        isItemsInvalidated: false,
      });
      return resultItems;
    }
    set({ isItemsLoading: false, isItemsInvalidated: false });
    return null;
  },

  theme: null,
  setTheme: (theme) => {
    set({ theme });
  },

  profile: {
    name: null,
    avatar: null,
    bio: null,
    nickname: null,
    links: {
      vk: null,
      tg: null,
      yt: null,
      tt: null,
      site: null,
    },
  },
  setProfile: (profile) => {
    set({
      profile: {
        ...profile,
      },
    });
  },

  hasStoreLabel: false,
  setHasStoreLabel: (hasStoreLabel) => {
    set({ hasStoreLabel });
  },

  reset: () => {
    set({
      profile: {
        name: null,
        bio: null,
        avatar: null,
        nickname: null,
        links: {
          vk: null,
          tg: null,
          yt: null,
          tt: null,
          site: null,
        },
      },
      theme: null,
      items: [],
      isItemsInvalidated: true,
    });
  },
}));
