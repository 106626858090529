import React, { ReactNode, useRef } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  value: boolean;
  onInput?: (enabled: boolean) => any;
  children?: ReactNode;
  label?: string;
  stopPropagation?: boolean;
}

export default function Checkbox({
  value,
  onInput,
  children,
  label,
  stopPropagation,
}: Props) {
  const id = useRef(String(Date.now() + Math.random()));
  return (
    <div className={twMerge("inline-flex gap-3")}>
      <input
        id={id.current}
        className="transition-colors w-[24px] h-[24px] appearance-none border-2 border-[#6f767e66] rounded-md cursor-pointer checked:border-primary1 before:hidden checked:before:block before:rounded-sm checked:bg-primary1 before:-rotate-45 before:translate-y-1/4 before:translate-x-[15%] before:w-[16px] before:h-[10px] before:border-l-2 before:border-b-2 before:border-white"
        type="checkbox"
        checked={value}
        onClick={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
        }}
        onChange={(e) => {
          if (onInput) {
            onInput(e.target.checked);
          }
        }}
      ></input>
      {label && (
        <label
          className="text-[15px] leading-[24px] font-semibold cursor-pointer"
          htmlFor={id.current}
        >
          {label}
        </label>
      )}
      {children}
    </div>
  );
}
