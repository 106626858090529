import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import Title from "../../components/ui/Title";
import { useAuth } from "../../providers/AuthProvider";
import toast from "react-hot-toast";
import { trackEvent } from "../../lib/ym";

export default function ChangePassword() {
  const { changePassword } = useAuth();
  const [success, setSuccess] = useState(false);
  const [form, setForm] = useState<{
    loading: boolean;
    password: string | null;
    isPasswordInvalid: boolean;
  }>({
    loading: false,
    password: null,
    isPasswordInvalid: false,
  });

  function validate() {
    return {
      isPasswordInvalid: !form.password || form.password.length < 6,
    };
  }

  async function submit() {
    if (!form.password) {
      return;
    }
    try {
      setForm({
        ...form,
        loading: true,
      });
      const { data, error } = await changePassword(form.password);
      console.log({ data, error });
      if (!error) {
        setSuccess(true);
        trackEvent("change_password");
      } else {
        toast.error("Ошибка при смене пароля");
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setForm({
        ...form,
        loading: false,
      });
    }

    // submit
  }

  function handleSubmit() {
    const invalid = validate();
    if (invalid) {
      return;
    }
    submit();
  }

  return (
    <div className="h-full flex">
      <div className="w-96 flex flex-col gap-6 m-auto">
        <Link
          to="/auth/login"
          className="lg:hidden self-start flex max-w-96 w-full mx-auto mb-6"
        >
          <img
            className="w-16"
            src="/logo.svg"
            alt="Logo"
          ></img>
        </Link>
        {success ? (
          <>
            <Title size="lg">Пароль успешно изменен</Title>
            <Button
              to="/"
              grow
            >
              Войти
            </Button>
          </>
        ) : (
          <>
            <Title size="lg">Смена пароля</Title>
            <div className="flex flex-col gap-4">
              <Input
                type="password"
                placeholder="Введите новый пароль"
                error={form.isPasswordInvalid}
                value={form.password}
                onInput={(password) => {
                  setForm({
                    ...form,
                    password,
                    isPasswordInvalid: false,
                  });
                }}
              ></Input>
              <Button
                grow
                onClick={handleSubmit}
              >
                Сменить пароль
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
