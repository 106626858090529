import React, { useCallback, useEffect, useState } from "react";
import Title from "../../components/ui/Title";
import Avatar from "../../components/ui/Avatar";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import Textarea from "../../components/ui/Textarea";
import Page from "../../components/layout/Page";
import Upload from "../../components/ui/Upload";
import { useCreatorPage } from "../../store/useCreatorPage";
import { useShallow } from "zustand/react/shallow";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { useAuth } from "../../providers/AuthProvider";
import useBreakpoint from "use-breakpoint";
import { BREAKPOINTS } from "../../constants/breakpoints";
import { useDebounceFunction } from "../../hooks/useDebounce";
import { trackEvent } from "../../lib/ym";
import Icon from "../../components/ui/Icon";
import CreatorPage, { Links } from "../../components/creator/CreatorPage";
import PreviewWrapper from "../../components/creator/PreviewWrapper";
import ProfileContainer from "../../components/ProfileContainer";
import Profile from "../../components/ui/Profile";
import InputUpload from "../../components/ui/InputUpload";

export default function SettingsBasicsPage() {
  const { user, loading, isProfileLoading } = useAuth();
  const navigate = useNavigate();
  const { pageProfile, setPageProfile, theme } = useCreatorPage(
    useShallow((state) => ({
      pageProfile: state.profile,
      setPageProfile: state.setProfile,
      theme: state.theme,
    })),
  );

  const [profile, setProfile] = useState<{
    name: string | null;
    isNameInvalid: boolean;
    avatar: string | null;
    bio: string | null;
    nickname: string | null;
    isNicknameInvalid: boolean;
    links: {
      vk: string | null;
      tt: string | null;
      yt: string | null;
      tg: string | null;
      site: string | null;
    };
  }>({
    name: pageProfile.name,
    isNameInvalid: false,
    avatar: pageProfile.avatar ?? null,
    bio: pageProfile.bio ?? null,
    nickname: pageProfile?.nickname ?? null,
    isNicknameInvalid: false,
    links: {
      vk: null,
      tg: null,
      tt: null,
      yt: null,
      site: null,
    },
  });

  useEffect(() => {
    setProfile({
      ...profile,
      ...pageProfile,
    });
  }, [pageProfile]);

  const saveProfile = useCallback(async () => {
    if (!user?.id) {
      return "no user";
    }
    if (!profile.name?.trim()?.length) {
      setProfile({
        ...profile,
        isNameInvalid: true,
      });
      toast.error("Введите имя");
      return;
    }
    if (profile.nickname?.includes("/")) {
      toast.error("Символ `/` запрещено использовать в никнейме");
      setProfile({
        ...profile,
        isNicknameInvalid: true,
      });
      return;
    }
    if (!profile.nickname?.trim()?.length) {
      setProfile({
        ...profile,
        isNicknameInvalid: true,
      });
      toast.error("Введите никнейм");
      return;
    }
    trackEvent("profile_submit", {
      hasBio: Boolean(profile.bio),
      links: (Object.keys(profile.links) as Array<keyof Links>).filter(
        (key) => profile.links[key],
      ),
    });
    const { error } = await supabase
      .from("users")
      .update({
        name: profile.name,
        bio: profile.bio,
        avatar: profile.avatar,
        nickname: profile.nickname,
        vk_link: profile.links.vk,
        tiktok_link: profile.links.tt,
        youtube_link: profile.links.yt,
        telegram_link: profile.links.tg,
        site_url: profile.links.site,
      })
      .eq("id", user.id);
    if (!error) {
      setPageProfile(profile);
      toast.success("Профиль обновлен");
      navigate("/");
    }
    if (error?.code === "23514") {
      toast.error("Такой никнейм уже занят");
      setProfile({
        ...profile,
        isNicknameInvalid: true,
      });
    }
  }, [profile, setPageProfile]);

  const [isFileLoading, setIsFileLoading] = useState(false);
  async function uploadFile(file: File, source?: string) {
    console.log(`${user?.id}`, file);
    trackEvent("profile_click_avatar", {
      button: source,
    });
    setIsFileLoading(true);
    const filePath = `${user?.id}/${Date.now()}`;
    const { error: uploadError } = await supabase.storage
      .from("images")
      .upload(filePath, file, {
        upsert: true,
      });
    setIsFileLoading(false);
    if (uploadError) {
      console.log({ uploadError });
      if ((uploadError as any)?.statusCode === 413) {
        toast.error("Файл слишком большой");
      } else {
        toast.error("Ошибка загрузки файла");
      }
      return;
    }
    const { data } = await supabase.storage
      .from("images")
      .getPublicUrl(filePath);
    setProfile({
      ...profile,
      avatar: data.publicUrl,
    });
  }

  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const trackName = useDebounceFunction(() => {
    trackEvent("profile_input", {
      input: "name",
    });
  });
  const trackNickname = useDebounceFunction(() => {
    trackEvent("profile_input", {
      input: "nickname",
    });
  });
  const trackBio = useDebounceFunction(() => {
    trackEvent("profile_input", {
      input: "bio",
    });
  });
  const trackLink = useDebounceFunction((source: string) => {
    trackEvent("profile_input", {
      input: source + "_link",
    });
  });

  return !isProfileLoading && !loading ? (
    <Page className="w-full">
      <div className="flex gap-6 top-0 flex-col lg:flex-row">
        <div className="flex flex-col flex-1 max-w-[660px]">
          <div className="flex flex-col gap-6 rounded-xl p-6 bg-neutral1 mb-6">
            <div className="flex items-center justify-between mb-3">
              <Title>Профиль</Title>
              <Button
                theme="secondary"
                icon="left"
                to="/"
              >
                Назад
              </Button>
            </div>
            <ProfileContainer nickname={profile.nickname || ""}>
              <Profile
                avatar={profile.avatar || ""}
                name={profile.name || "Твое имя"}
                bio={profile.bio || "Твое био"}
              ></Profile>
            </ProfileContainer>
            <div className="flex gap-8 items-center">
              <InputUpload
                loading={isFileLoading}
                filePath={profile.avatar}
                accept="image/jpeg,image/png,image/svg+xml,image/webp"
                className="relative"
                onInput={(file) => {
                  uploadFile(file);
                }}
                onDelete={() => {
                  setProfile({
                    ...profile,
                    avatar: null,
                  });
                }}
              >
                Аватар
              </InputUpload>
            </div>
            <Input
              label="Имя"
              error={profile.isNameInvalid}
              value={profile.name}
              onInput={(name) => {
                setProfile({
                  ...profile,
                  isNameInvalid: false,
                  name,
                });
                trackName();
              }}
            ></Input>
            <Input
              label="Ссылка на твой магазин"
              labelTooltip="Задай уникальную публичную ссылку, на которой будет твой каталог"
              error={profile.isNicknameInvalid}
              value={profile.nickname}
              onInput={(nickname) => {
                setProfile({
                  ...profile,
                  isNicknameInvalid: false,
                  nickname,
                });
                trackNickname();
              }}
              theme="white"
            >
              creatorstore.pro/
            </Input>
            <Textarea
              label="Био"
              value={profile.bio || ""}
              onInput={(bio) => {
                setProfile({
                  ...profile,
                  bio,
                });
                trackBio();
              }}
            ></Textarea>
          </div>
          <div className="flex flex-col gap-6 rounded-xl p-6 bg-neutral1 mb-6">
            <Title>Соцсети и сайт</Title>
            <Input
              placeholder="https://vk.com/user"
              value={profile.links.vk}
              onInput={(vk) => {
                setProfile({
                  ...profile,
                  links: {
                    ...profile.links,
                    vk,
                  },
                });
                trackLink("vk");
              }}
            >
              <Icon
                id="vk"
                color="#0077FF"
                size={24}
              ></Icon>
            </Input>
            <Input
              placeholder="https://t.me/user"
              value={profile.links.tg}
              onInput={(tg) => {
                setProfile({
                  ...profile,
                  links: {
                    ...profile.links,
                    tg,
                  },
                });
                trackLink("telegram");
              }}
            >
              <Icon
                id="tg"
                color="#2AABEE"
                size={24}
              ></Icon>
            </Input>
            <Input
              placeholder="https://www.youtube.com/@user"
              value={profile.links.yt}
              onInput={(yt) => {
                setProfile({
                  ...profile,
                  links: {
                    ...profile.links,
                    yt,
                  },
                });
                trackLink("youtube");
              }}
            >
              <Icon
                id="yt"
                color="#FF0000"
                size={24}
              ></Icon>
            </Input>
            <Input
              placeholder="https://www.tiktok.com/@user"
              value={profile.links.tt}
              onInput={(tt) => {
                setProfile({
                  ...profile,
                  links: {
                    ...profile.links,
                    tt,
                  },
                });
                trackLink("tiktok");
              }}
            >
              <Icon
                id="tt"
                color="#000"
                size={24}
              ></Icon>
            </Input>
            <Input
              placeholder="Адрес вашего сайта"
              value={profile.links.site}
              onInput={(site) => {
                setProfile({
                  ...profile,
                  links: {
                    ...profile.links,
                    site,
                  },
                });
                trackLink("site");
              }}
            >
              <Icon
                id="open"
                size={24}
              ></Icon>
            </Input>
          </div>
          <Button onClick={saveProfile}>Сохранить</Button>
        </div>
      </div>
    </Page>
  ) : null;
}
