import React, { useState } from "react";
import Page from "../../components/layout/Page";
import Title from "../../components/ui/Title";
import PricingCard from "../../components/ui/PricingCard";
import { useModal } from "@ebay/nice-modal-react";
import { useAuth } from "../../providers/AuthProvider";
import { trackEvent } from "../../lib/ym";

export type SubscriptionType = 1 | 2;

const pricing = [
  {
    id: 1,
    title: "Pro на месяц",
    subtitle: "Списание каждый месяц",
    titleColor: "var(--secondary3)",
    options: [
      {
        id: "1",
        label: "Неограниченное количество продуктов",
      },
      {
        id: "2",
        label: "Доступ ко всем дизайн шаблонам",
      },
    ],
    price: 100,
  },
  {
    id: 2,
    title: "Pro На год",
    subtitle: "Списание 1 раз в год",
    titleColor: "var(--secondary2)",
    options: [
      {
        id: "1",
        label: "Неограниченное количество продуктов",
      },
      {
        id: "2",
        label: "Доступ ко всем дизайн шаблонам",
      },
      {
        id: "3",
        label: "Убрать логотип Сreator store",
      },
      {
        id: "4",
        label:
          "Все последующие платные аддоны, которые выйдут в течении года, будут предоставлены вам бесплатно",
      },
    ],
    price: 840,
    oldPrice: 1200,
    isPrimary: true,
    discountPercent: 30,
  },
];

export default function Pricing() {
  const modal = useModal("subscription-success-modal");
  const { updateSubscription, isSubscriptionActive } = useAuth();
  const [loading, setLoading] = useState(false);
  const dialog = useModal("dialog");

  return (
    <Page className="min-h-full">
      <Title
        size="lg"
        className="mb-6"
      >
        Купить тариф PRO
      </Title>
      <div className="flex p-8 gap-12 lg:p-12 rounded-lg bg-neutral1 w-fit flex-col lg:flex-row">
        <div className="flex-1 lg:mr-20 max-w-[380px]">
          <PricingCard
            loading={loading}
            {...pricing[1]}
            onClick={async () => {
              trackEvent("pricing_click_subscribe", {
                period: "year",
                has_active_subscription: isSubscriptionActive,
              });
              if (!isSubscriptionActive) {
                setLoading(true);
                await updateSubscription(1);
                setLoading(false);
                modal.show();
              } else {
                dialog.show({
                  title: "У вас уже есть активная подписка",
                });
              }
            }}
          ></PricingCard>
        </div>
        <div className="w-full h-[1px] lg:w-[1px] lg:h-auto bg-neutral3"></div>
        <div className="flex-1 lg:ml-20 max-w-[380px]">
          <PricingCard
            loading={loading}
            {...pricing[0]}
            onClick={async () => {
              trackEvent("pricing_click_subscribe", {
                period: "month",
                has_active_subscription: isSubscriptionActive,
              });
              if (!isSubscriptionActive) {
                setLoading(true);
                await updateSubscription(1);
                setLoading(false);
                modal.show();
              } else {
                dialog.show({
                  title: "У вас уже есть активная подписка",
                });
              }
            }}
          ></PricingCard>
        </div>
      </div>
    </Page>
  );
}
