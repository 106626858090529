import { Navigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

interface Props {
  children: JSX.Element | null;
}

export function PrivateRoute({ children }: Props) {
  const auth = useAuth();
  return !auth?.user && !auth?.loading ? (
    <Navigate to="/auth/login" />
  ) : (
    children
  );
}
