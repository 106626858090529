import { AnimatePresence, motion } from "framer-motion";
import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  visible: boolean;
  children?: ReactNode;
  disableSidebar?: boolean;
}

export default function BottomPanel({
  visible,
  children,
  disableSidebar,
}: Props) {
  return (
    <AnimatePresence>
      {Boolean(visible) && (
        <motion.div
          initial={{ opacity: 1, translateY: 40 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: 40 }}
          className={twMerge(
            "z-10 border-t border-t-neutral2 lg:border-l lg:border-l-neutral2 bottom-0 left-0 right-0 bg-neutral1 py-5 px-10 fixed flex justify-between items-center",
            !disableSidebar
              ? "mb-[68px] md:mb-0 md:ml-[96px] lg:ml-[270px]"
              : "",
          )}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
