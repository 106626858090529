import { useState } from "react";

export type IconList =
  | "copy"
  | "home"
  | "pocket"
  | "avatar"
  | "plus"
  | "info-fill"
  | "chevron"
  | "lock"
  | "lock-fill"
  | "upload"
  | "tree"
  | "shop"
  | "shop-fill"
  | "wallet"
  | "fire"
  | "fire-fill"
  | "open"
  | "edit"
  | "palette"
  | "more-vertical"
  | "drag"
  | "left"
  | "right"
  | "close"
  | "chevron-forward"
  | "chevron-backward"
  | "search"
  | "check"
  | "check-circle"
  | "check-all"
  | "vk"
  | "vk-circle"
  | "tt"
  | "tt-circle"
  | "yt"
  | "yt-circle"
  | "tg"
  | "tg-circle"
  | "global-circle"
  | "store"
  | "link"
  | "mail"
  | "phone-call-outgoing"
  | "image"
  | "trash"
  | "brush"
  | "add-circled"
  | "receipt"
  | "show"
  | "home-light"
  | "home-fill"
  | "show-fill"
  | "receipt-fill"
  | "brush-fill";

interface Props {
  id: IconList;
  size?: number;
  color?: string;
  hoverColor?: string;
  className?: string;
  onClick?: () => void;
}

export default function Icon({
  id,
  size = 24,
  color = "#33383F",
  hoverColor,
  className,
  onClick,
  ...props
}: Props) {
  const [hover, setHover] = useState(false);
  return (
    <svg
      className={className}
      style={{
        color: hoverColor && hover ? hoverColor : color,
        transition: "color .15s ease-in-out",
        minWidth: size + "px",
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      width={size}
      height={size}
      onClick={onClick}
      {...props}
    >
      <use href={`/sprite.svg#${id}`} />
    </svg>
  );
}
