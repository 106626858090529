import {
  useFloating,
  offset,
  flip,
  shift,
  autoUpdate,
  FloatingPortal,
  useClick,
  useFocus,
  useRole,
  useDismiss,
  useInteractions,
} from "@floating-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { twMerge } from "tailwind-merge";
import SelectOption from "./SelectOption";
import Icon, { IconList } from "../Icon";

type OptionId = number | string | null;

interface Props {
  value: OptionId;
  onInput: (value: OptionId) => void;
  width?: string | number;
  options?: { id: OptionId; label: string; icon?: IconList }[];
}

export default function Select({
  value,
  onInput,
  width,
  options = [
    {
      id: "1",
      label: "hey start iji ookj o hey start iji ookj o ",
    },
    {
      id: "2",
      label: "hey next",
    },
  ],
}: Props) {
  const [open, setOpen] = useState(false);
  const {
    x,
    y,
    refs,
    strategy,
    context,
    placement: computedPlacement,
  } = useFloating({
    placement: "bottom",
    open,
    onOpenChange(open) {
      setOpen(open);
    },
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocus(context),
    useRole(context, { role: "select" }),
    useDismiss(context),
  ]);

  const translate = {
    top: { translateY: 5 },
    bottom: { translateY: -5 },
    left: { translateX: 5 },
    right: { translateX: -5 },
  }[
    computedPlacement.includes("-")
      ? computedPlacement.split("-")[0]
      : computedPlacement
  ];

  const [optionHovered, setOptionHovered] = useState<OptionId | false>(false);

  return (
    <>
      <div
        className={twMerge(
          "w-full cursor-pointer flex gap-2 rounded-xl transition-colors h-[48px] px-[16px] border-2 items-center text-[15px] leading-[23px] font-semibold",
          open ? "border-[#2A85FF59]" : "border-neutral2",
        )}
        ref={refs.setReference}
        style={{ width: typeof width === "string" ? width : width + "px" }}
        {...getReferenceProps()}
      >
        <div className="truncate">
          {options.find((option) => option.id === value)?.label}
        </div>
        <Icon
          className="min-w-[16px] ml-auto"
          id="chevron"
          size={20}
          color="var(--neutral4)"
        ></Icon>
      </div>
      <FloatingPortal>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ opacity: 0, ...translate }}
              animate={{ opacity: 1, translateX: 0, translateY: 0 }}
              exit={{ opacity: 0, ...translate }}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  width: `${refs.reference.current?.getBoundingClientRect()
                    .width}px`,
                  boxShadow:
                    "0px 32px 48px -8px rgba(0, 0, 0, 0.10), 0px 0px 14px -4px rgba(0, 0, 0, 0.05)",
                  padding: 0,
                },
              })}
              className="bg-white rou py-1 px-3 rounded-xl "
            >
              {options.map((option, index) => (
                <SelectOption
                  key={option.id}
                  first={index === 0}
                  last={index === options.length - 1}
                  selected={option.id === value}
                  onClick={() => {
                    onInput(option.id);
                    setOpen(false);
                  }}
                >
                  <div
                    className="flex items-center gap-2 p-3 group"
                    onMouseEnter={() => {
                      setOptionHovered(option.id);
                    }}
                    onMouseLeave={() => {
                      setOptionHovered(false);
                    }}
                  >
                    {option.icon && (
                      <Icon
                        id={option.icon}
                        color={
                          option.id === value || optionHovered === option.id
                            ? "var(--neutral7)"
                            : "var(--neutral4)"
                        }
                      ></Icon>
                    )}
                    {option.label}
                  </div>
                </SelectOption>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  );
}
