import React, { ReactNode, useMemo, MouseEvent } from "react";
import Icon, { IconList } from "./Icon";
import { Link } from "react-router-dom";
import { useBreakpoint } from "use-breakpoint";
import { BREAKPOINTS } from "../../constants/breakpoints";

export type ButtonTheme =
  | "primary"
  | "secondary"
  | "black"
  | "orange"
  | "green"
  | "violet"
  | "sand"
  | "gray"
  | "red";
type Size = "md" | "sm" | "xs";

export interface ButtonProps {
  icon?: IconList;
  children?: ReactNode;
  theme?: ButtonTheme;
  iconAlignment?: "right" | "left";
  disabled?: boolean;
  manualHover?: boolean;
  grow?: boolean;
  onClick?: (e: MouseEvent) => any;
  iconSize?: number;
  to?: string;
  size?: Size;
  type?: "submit";
  target?: string;
  className?: string;
  href?: string;
}

const paddings = {
  md: "px-3",
  lg: "px-4",
};

export default function Button({
  icon,
  iconAlignment = "left",
  children,
  theme = "primary",
  disabled,
  manualHover,
  grow,
  onClick,
  iconSize = 24,
  to,
  size = "md",
  type,
  target,
  href,
  className,
}: ButtonProps) {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const buttonTheme = useMemo(() => {
    const getBg = (theme: ButtonTheme, manualHover?: boolean) => {
      if (theme === "red") {
        return "bg-primary3";
      }
      if (theme === "gray") {
        return "bg-[#F0EFEF]";
      }
      if (theme === "sand") {
        return "bg-[#E3D9D1]";
      }
      if (theme === "green") {
        return "bg-primary2";
      }
      if (theme === "violet") {
        return "bg-primary4";
      }
      if (theme === "orange") {
        return "bg-primary3";
      }
      if (theme === "secondary") {
        return "bg-white";
      }
      if (theme === "black") {
        return `hover:bg-neutral6 ${
          manualHover ? "bg-neutral6" : "bg-neutral7"
        }`;
      }
      return `hover:bg-[#3F91FF] ${
        manualHover ? "bg-[#3F91FF]" : "bg-primary1"
      }`;
    };
    return {
      border:
        theme === "secondary"
          ? `border-2 hover:border-neutral6 ${
              manualHover ? "border-neutral6" : "border-neutral3"
            }`
          : theme === "black"
            ? "border-2 border-neutral6"
            : "",
      backgroundColor: getBg(theme, manualHover),
      color: ["secondary", "gray", "sand"].includes(theme)
        ? "text-neutral7"
        : "text-neutral1",
      order: iconAlignment === "right" ? "flex-row-reverse" : "flex",
      padding:
        theme === "secondary" || theme === "black"
          ? size === "md"
            ? "px-2 md:px-2.5"
            : "px-3 md:px-3.5"
          : size === "md"
            ? "px-2 md:px-3"
            : "px-3 md:px-4",
      rounded: size === "xs" ? "rounded-lg" : "rounded-xl",
    };
  }, [iconAlignment, theme, manualHover, size]);

  const content = (
    <>
      {icon !== undefined && (
        <Icon
          id={icon}
          color={buttonTheme.color}
          size={breakpoint === "mobile" ? iconSize - 4 : iconSize}
        ></Icon>
      )}
      {children}
    </>
  );
  const props = {
    className: `inline-flex ${buttonTheme.order} items-center gap-2 ${
      buttonTheme.padding
    } ${
      size === "md"
        ? "h-[40px] min-h-[40px] text-[13px] leading-[24px] md:h-[48px] md:min-h-[48px] md:text-[15px] md:leading-[24px]"
        : size === "sm"
          ? "h-[32px] min-h-[32px] text-[12px] leading-[24px] md:h-[40px] md:min-h-[40px] md:text-[13px] md:leading-[24px]"
          : "h-[32px] min-h-[32px] text-[12px] leading-[24px]"
    } ${buttonTheme.color} ${buttonTheme.backgroundColor} ${
      buttonTheme.border
    } ${buttonTheme.rounded} font-bold transition-colors ${
      disabled ? "opacity-50 pointer-events-none" : ""
    } ${grow ? "w-full justify-center" : "w-fit"} ${className}`,
    onClick,
    to: to || "",
    href,
    type,
    target,
  };

  return to ? (
    <Link {...props}>{content}</Link>
  ) : href ? (
    <a {...props}>{content}</a>
  ) : (
    <button {...props}>{content}</button>
  );
}
