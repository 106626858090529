import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export default function Page({
  children,
  className,
  row,
}: {
  children: ReactNode;
  className?: string;
  row?: boolean;
}) {
  return (
    <div
      className={twMerge(
        "flex px-4 py-6 md:p-10 flex-1",
        !row && "flex-col",
        className,
      )}
    >
      {children}
    </div>
  );
}
