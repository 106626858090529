import React from "react";
import { twMerge } from "tailwind-merge";
import { CreatorPageTheme, Links } from "../creator/CreatorPage";
import ProfileIconList from "./ProfileIconList";

interface Props {
  avatar?: string;
  name: string;
  bio?: string;
  dark?: boolean;
  className?: string;
  theme: CreatorPageTheme;
  links?: Links;
}

export default function FullscreenProfile({
  avatar,
  name,
  bio,
  className,
  links,
}: Props) {
  return (
    <>
      <div
        className={twMerge(
          "w-full flex flex-col items-center gap-6",
          className,
        )}
      >
        <div
          className={twMerge(
            "mb-auto relative px-6 flex flex-col items-center gap-2 z-10 mt-6",
          )}
        >
          <div
            className={twMerge(
              "text-[32px] leading-[40px] font-semibold text-white text-center",
            )}
          >
            {name}
          </div>
          <div
            className={twMerge(
              "text-white text-[15px] leading-[24px] font-medium text-center whitespace-pre-wrap",
            )}
          >
            {bio || "Ваше био"}
          </div>
          {links && (
            <ProfileIconList
              links={links}
              className="mt-4"
            ></ProfileIconList>
          )}
        </div>
      </div>
    </>
  );
}
