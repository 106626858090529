import React from "react";
import Icon from "./Icon";
import Button from "./Button";
import { twMerge } from "tailwind-merge";

interface Props {
  text: string;
  href: string;
  onClose?: () => void;
  className?: string;
}

export default function Note({ text, href, onClose, className }: Props) {
  return (
    <div
      className={twMerge(
        "bg-primary1 py-1.5 px-6 flex items-center",
        className,
      )}
    >
      <Icon
        id="close"
        size={16}
        color="var(--neutral1)"
        className="mr-1 cursor-pointer"
        onClick={() => {
          onClose && onClose();
        }}
      ></Icon>
      <div className="text-[12px] leading-[24px] text-neutral1 font-semibold mr-auto">
        {text}
      </div>
      <Button
        to={href}
        theme="secondary"
        size="sm"
      >
        Подробнее
      </Button>
    </div>
  );
}
