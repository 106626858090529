import React from "react";
import Menu from "./components/layout/Menu/Menu";
import Header from "./components/layout/Header";
import { Outlet } from "react-router-dom";
import MenuMobile from "./components/layout/Menu/MenuMobile";
import Note from "./components/ui/Note";
import { useCookies } from "react-cookie";
import { useAuth } from "./providers/AuthProvider";

export default function Layout() {
  const [cookies, setCookie] = useCookies(["hide-subscription-reminder"]);
  const { isSubscriptionActive, isProfileLoading } = useAuth();

  return (
    <div className="flex h-auto min-h-full w-full relative min-w-0 flex-col">
      <Menu className="hidden md:flex flex-col z-30 fixed h-[96px] left-0 bottom-0 right-0 md:top-0 md:right-auto md:h-full max-w-[270px] overflow-hidden"></Menu>
      <MenuMobile className="z-30 flex md:hidden fixed bottom-0"></MenuMobile>
      <div className="mb-[68px] md:mb-0  md:ml-[270px] flex h-full flex-col min-w-0 flex-1">
        <Header className="sticky z-30 top-0 border-l border-neutral2"></Header>
        {!cookies["hide-subscription-reminder"] &&
          !isSubscriptionActive &&
          !isProfileLoading && (
            <Note
              className="z-30 sticky top-[57px] md:hidden"
              text="Попробуй тариф Pro бесплатно"
              href={"/pricing"}
              onClose={() => {
                const date = new Date();
                date.setDate(date.getDate() + 1);
                console.log(date);
                setCookie("hide-subscription-reminder", "true", {
                  expires: date,
                });
              }}
            ></Note>
          )}
        <div className="flex-1 bg-neutral2 min-w-0 flex flex-col">
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
}
