import { useRef } from "react";

export function useDebounceFunction(
  cb: (...args: any[]) => any,
  delay: number = 1000,
) {
  const debouncingRef = useRef<NodeJS.Timeout | null>(null);

  function trigger(...args: any[]) {
    if (debouncingRef.current) {
      clearTimeout(debouncingRef.current);
    }
    debouncingRef.current = setTimeout(() => {
      cb(...args);
    }, delay);
  }

  return trigger;
}
