import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";
import Input from "../../components/ui/Input";
import Title from "../../components/ui/Title";
import ButtonWithImage from "../../components/ui/ButtonWithImage";
import { useAuth } from "../../providers/AuthProvider";
import { useState } from "react";
import { validateEmail } from "../../helpers/validations";
import toast from "react-hot-toast";
import { trackEvent } from "../../lib/ym";

export default function LoginPage() {
  const navigate = useNavigate();
  const { signInWithGoogle, signIn } = useAuth();

  const [form, setForm] = useState<{
    loading: boolean;
    email: string | null;
    isEmailInvalid: boolean;
    password: string | null;
    isPasswordInvalid: boolean;
  }>({
    loading: false,
    email: null,
    isEmailInvalid: false,
    password: null,
    isPasswordInvalid: false,
  });

  function validate() {
    const isEmailInvalid = !form.email || !validateEmail(form.email);
    const isPasswordInvalid = !form.password || form.password.length < 6;
    setForm({
      ...form,
      isEmailInvalid,
      isPasswordInvalid,
    });
    return isEmailInvalid || isPasswordInvalid;
  }
  async function submit() {
    const { data, error } = await signIn({
      email: form.email as string,
      password: form.password as string,
    });
    if (error) {
      if (error.message === "Email not confirmed") {
        toast.error("Пожалуйста, подтвердите свою почту, чтобы продолжить");
      }
      if (error.message === "Invalid login credentials") {
        toast.error("Неправильные логин или пароль");
      }
      console.log({ error });
      return;
    }
    if (data) {
      trackEvent("login");
      navigate("/");
    }
  }

  function handleSubmit() {
    const invalid = validate();
    if (invalid) {
      return;
    }
    submit();
  }

  return (
    <div className="w-96 flex flex-col gap-6">
      <Title size="lg">Войти</Title>
      <div className="flex flex-col gap-4">
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Input
            type="email"
            placeholder="Почта"
            error={form.isEmailInvalid}
            value={form.email}
            onInput={(email) => {
              setForm({
                ...form,
                email,
                isEmailInvalid: false,
              });
            }}
          ></Input>
          <Input
            type="password"
            placeholder="Пароль"
            error={form.isPasswordInvalid}
            value={form.password}
            onInput={(password) => {
              setForm({
                ...form,
                password,
                isPasswordInvalid: false,
              });
            }}
          ></Input>
          <Button
            grow
            type="submit"
          >
            Войти
          </Button>
        </form>
        <div className="h-[1px] w-full bg-neutral3"></div>
        <div className="flex flex-col gap-3">
          <div className="text-[15px] leading-[15px] text-neutral4">
            Войти с помощью:
          </div>
          <ButtonWithImage
            icon="/google.svg"
            text="Google"
            onClick={async () => {
              const { data, error } = await signInWithGoogle();
              console.log({ data, error });
              if (!error) {
                trackEvent("login", {
                  provider: "google",
                });
              }
            }}
          ></ButtonWithImage>
        </div>
      </div>
      <div className="flex flex-col gap-4 items-center">
        <Link
          to="/auth/signup"
          className="text-neutral4 underline font-medium"
          onClick={() => {
            trackEvent("auth_click", {
              button: "from_login_to_signup",
            });
          }}
        >
          Нет аккаунта? Зарегистрироваться
        </Link>
        <Link
          to="/auth/recovery"
          className="text-neutral4 underline font-medium"
          onClick={() => {
            trackEvent("auth_click", {
              button: "from_login_to_recovery",
            });
          }}
        >
          Восстановить пароль
        </Link>
      </div>
    </div>
  );
}
