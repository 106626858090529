import React from "react";
import { Links } from "../creator/CreatorPage";
import { twMerge } from "tailwind-merge";
import ProfileIcon from "./ProfileIcon";

interface Props {
  links: Links;
  className?: string;
  theme?: "default" | "dark";
  disabled?: boolean;
}

export default function ProfileIconList({
  links,
  className,
  theme = "default",
  disabled,
}: Props) {
  const hasLinks = (Object.keys(links) as Array<keyof Links>).find(
    (key) => links[key],
  );
  return hasLinks ? (
    <div className={twMerge("flex items-center gap-2", className)}>
      {links.vk && (
        <ProfileIcon
          theme={theme}
          link={links.vk}
          icon="vk-circle"
          disabled={disabled}
        ></ProfileIcon>
      )}
      {links.tg && (
        <ProfileIcon
          theme={theme}
          link={links.tg}
          icon="tg-circle"
          disabled={disabled}
        ></ProfileIcon>
      )}
      {links.yt && (
        <ProfileIcon
          theme={theme}
          link={links.yt}
          icon="yt-circle"
          disabled={disabled}
        ></ProfileIcon>
      )}
      {links.tt && (
        <ProfileIcon
          theme={theme}
          link={links.tt}
          icon="tt-circle"
          disabled={disabled}
        ></ProfileIcon>
      )}
      {links.site && (
        <ProfileIcon
          theme={theme}
          link={links.site}
          icon="global-circle"
          disabled={disabled}
        ></ProfileIcon>
      )}
    </div>
  ) : null;
}
