import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Modal from "../ui/Modal";
import Result from "../ui/Result";

interface Props {
  onContinue?: () => void;
}

export default NiceModal.create(({ onContinue }: Props) => {
  const modal = useModal("subscription-success-modal");
  return (
    <Modal
      visible={modal.visible}
      onClose={() => modal.hide()}
      afterClose={() => modal.remove()}
    >
      <Result
        state="success"
        title={"Поздравляем!\nВы стали A/B тестером продукта"}
        text="Мы не возьмем с вас оплату, потому что вы попали в спискок первых пользователей creator store. Вам открыты все функции, пользуйтесь без ограничений!"
        button="Продолжить как PRO"
        onClick={() => {
          if (onContinue) {
            onContinue();
          }
          modal.hide();
        }}
      ></Result>
    </Modal>
  );
});
