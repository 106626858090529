import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { TabsTheme } from "./Tabs";

interface Props {
  children: ReactNode;
  selected?: boolean;
  onClick?: () => any;
  theme?: TabsTheme;
}

export default function TabItem({ children, selected, onClick, theme }: Props) {
  return (
    <div
      className={twMerge(
        "px-4 truncate min-w-0 duration-500 transition-colors cursor-pointer w-full justify-center h-[36px] md:h-[40px] rounded-lg flex items-center text-[15px] leading-[24px] font-semibold bg-transparent",
        theme === "white"
          ? selected
            ? "bg-neutral2 text-neutral7"
            : "bg-white text-neutral4"
          : selected
            ? "bg-white text-neutral7"
            : "bg-neutral2 text-neutral4",
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
