import React, { useCallback, useEffect, useMemo, useState } from "react";
import Page from "../../components/layout/Page";
import Title from "../../components/ui/Title";
import Table from "../../components/ui/Table/Table";
import Checkbox from "../../components/ui/Checkbox";
import TableCell from "../../components/ui/Table/TableCell";
import TableHeaderCell from "../../components/ui/Table/TableHeaderCell";
import TableRow from "../../components/ui/Table/TableRow";
import Input from "../../components/ui/Input";
import { supabase } from "../../lib/supabase";
import { useAuth } from "../../providers/AuthProvider";
import toast from "react-hot-toast";
import {
  Application,
  ApplicationServer,
  mapApplicationsFromServer,
  mapApplicationsToServer,
} from "../../helpers/mappers";
import { useCreatorPage } from "../../store/useCreatorPage";
import { useShallow } from "zustand/react/shallow";
import Label from "../../components/ui/Label";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import Button from "../../components/ui/Button";
import Icon from "../../components/ui/Icon";
import { useApplications } from "../../store/useApplications";
import Result from "../../components/ui/Result";
import { useNavigate } from "react-router-dom";
import { PostgrestError } from "@supabase/supabase-js";
import { useDebounceFunction } from "../../hooks/useDebounce";
import { trackEvent } from "../../lib/ym";
import BottomPanel from "../../components/ui/BottomPanel";
import { ButtonItem, CardItem } from "../../components/creator/CreatorPage";

const headers = [
  {
    header: "Продукт",
    minWidth: 250,
  },
  {
    header: "Имя",
    minWidth: 100,
  },
  {
    header: "Контакты",
    minWidth: 100,
  },
  {
    header: "Дата заказа",
    minWidth: 100,
  },
  {
    header: "Сумма заказа",
    minWidth: 100,
  },
];

export interface TableRowData {
  id: number;
  icon?: string;
  text?: string;
  name?: string;
  phone: string | null;
  mail: string | null;
  createdAt: Date;
  price: number | null;
}

export default function Applicants() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { user, isProfileLoading } = useAuth();
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState<Application[]>([]);
  const [tableRows, setTableRows] = useState<TableRowData[]>([]);
  const [updating, setUpdating] = useState(false);
  const setCount = useApplications((state) => state.setCount);
  const count = useApplications((state) => state.count);

  const filteredRows = useMemo(() => {
    return search.trim().length
      ? tableRows.filter((row) => row.text?.toLowerCase().includes(search))
      : tableRows;
  }, [tableRows, search]);

  const [itemIds, setItemIds] = useState<number[]>([]);
  useEffect(() => {
    setItemIds(
      itemIds.filter((itemId) => filteredRows.find((row) => row.id === itemId)),
    );
  }, [filteredRows]);
  function toggleItemIds(itemId: number) {
    if (itemIds.includes(itemId)) {
      setItemIds(itemIds.filter((id) => id !== itemId));
    } else {
      setItemIds([...itemIds, itemId]);
    }
  }

  async function getNewApplications() {
    if (!user?.id) {
      return;
    }
    setLoading(true);
    const {
      data,
      error,
    }: { data: ApplicationServer[] | null; error: PostgrestError | null } =
      await supabase
        .from("applications")
        .select()
        .eq("user_id", user.id)
        .eq("completed", true);
    console.log({ data, error });
    if (error || !data) {
      toast.error(
        "Ошибка при загрузке заявок. Перезагрузите страницу или попробуйте позже",
      );
      console.log({ error });
      setLoading(false);
      return;
    }
    setApplications(mapApplicationsFromServer(data));
    setLoading(false);
  }

  const { fetchItems, isItemsInvalidated, isItemsLoading, items } =
    useCreatorPage(
      useShallow((state) => ({
        fetchItems: state.fetchItems,
        isItemsInvalidated: state.isItemsInvalidated,
        isItemsLoading: state.isItemsLoading,
        items: state.items,
      })),
    );

  useEffect(() => {
    if (!isItemsLoading && isItemsInvalidated && user?.id) {
      fetchItems(user.id).then((items) => {
        if (!items) {
          toast.error(
            "Ошибка при загрузке страницы. Перезагрузите страницу или попробуйте позже",
          );
        }
      });
    }
  }, [isItemsInvalidated, user?.id]);

  useEffect(() => {
    if (user) {
      getNewApplications();
    }
  }, [user]);

  const updateTableRows = useCallback(() => {
    setTableRows(
      applications
        .map((application) => {
          const item = items
            .filter((iterated) => iterated.type !== "link")
            .find((iterated) => iterated.id === application.itemId) as
            | ButtonItem
            | CardItem;
          return {
            id: application.id,
            name: application.name ?? undefined,
            mail: application.mail,
            phone: application.phone,
            price: item?.type === "card" ? item.card.price : null,
            text: item?.content?.title ?? undefined,
            icon: item?.content?.cover ?? undefined,
            createdAt: new Date(application.createdAt),
          };
        })
        .sort((a, b) => {
          return b.createdAt.getTime() - a.createdAt.getTime();
        }),
    );
  }, [items, applications]);

  useEffect(() => {
    if (!isItemsInvalidated && !loading) {
      updateTableRows();
    }
  }, [isItemsInvalidated, loading, updating]);

  const save = useCallback(async () => {
    if (!user?.id) {
      return;
    }
    setUpdating(true);
    console.log({
      applications: mapApplicationsToServer(
        applications
          .filter((application) => itemIds.includes(application.id))
          .map((application) => ({
            ...application,
            completed: false,
          })),
      ),
    });
    const { data, error } = await supabase
      .from("applications")
      .upsert(
        mapApplicationsToServer(
          applications
            .filter((application) => itemIds.includes(application.id))
            .map((application) => ({
              ...application,
              completed: false,
            })),
        ),
      )
      .eq("user_id", user.id)
      .select();
    console.log({ error, data });
    if (error || !data) {
      toast.error(
        "Ошибка при обновлении заявок. Перезагрузите страницу или попробуйте позже",
      );
      setUpdating(false);
      return;
    }
    trackEvent("applications_click_save", {
      applications_type: "completed",
      amount: itemIds.length,
    });
    console.log({
      newappl: applications.filter(
        (application) => !itemIds.includes(application.id),
      ),
    });
    setApplications(
      applications.filter((application) => !itemIds.includes(application.id)),
    );
    setCount(count + itemIds.length);
    setItemIds([]);
    setUpdating(false);
  }, [itemIds, user?.id, applications]);

  const trackSearch = useDebounceFunction((value: string) => {
    trackEvent("applications_input_search", {
      applications_type: "completed",
      search: value,
    });
  });

  return (
    <Page>
      <Title
        size="lg"
        className="mb-6"
      >
        Завершенные заявки
      </Title>
      <div className="bg-neutral1 p-3 rounded-lg">
        <div className="flex items-center justify-between mb-8 p-3 gap-6">
          <Title color="var(--secondary2)">Заявки</Title>
          <Input
            icon="search"
            placeholder="Поиск заявок"
            className="flex-1 max-w-[360px]"
            value={search}
            onInput={(value) => {
              setSearch(value);
              trackSearch(value);
            }}
          ></Input>
        </div>
        <div className="flex w-full max-w-full overflow-x-auto justify-center">
          {loading || isItemsLoading || isProfileLoading ? (
            <div className="flex flex-col gap-3">
              <div className="flex gap-8 px-3">
                <div className="w-[24px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
                <div className="flex gap-5">
                  <div className="w-[80px] h-[80px] rounded-md bg-neutral2 animate-pulse"></div>
                  <div className="w-[168px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
                </div>
                <div className="w-[168px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
              </div>
              <div className="flex gap-8 px-3">
                <div className="w-[24px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
                <div className="flex gap-5">
                  <div className="w-[80px] h-[80px] rounded-md bg-neutral2 animate-pulse"></div>
                  <div className="w-[168px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
                </div>
                <div className="w-[168px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
              </div>
              <div className="flex gap-8 px-3">
                <div className="w-[24px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
                <div className="flex gap-5">
                  <div className="w-[80px] h-[80px] rounded-md bg-neutral2 animate-pulse"></div>
                  <div className="w-[168px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
                </div>
                <div className="w-[168px] h-[24px] rounded-md bg-neutral2 animate-pulse"></div>
              </div>
            </div>
          ) : !tableRows.length && !search ? (
            <Result
              className="py-6"
              title="Обработанных заявок пока нет"
              text="Ждем заявок, а пока можно перейти на главную и проверить свою страницу"
              button="Настроить страницу"
              onClick={() => {
                navigate("/");
              }}
            ></Result>
          ) : !filteredRows.length && search.length ? (
            <Result
              className="py-6"
              title="При указанных фильтрах заявки не найдены"
              text="Попробуйте сбросить фильтры"
              button="Сбросить фильтры"
              onClick={() => {
                setSearch("");
              }}
            ></Result>
          ) : (
            <Table
              headers={[
                <TableHeaderCell
                  key="checkbox"
                  className="pl-3 pb-[10px] border-b-[1px] border-neutral3"
                >
                  <Checkbox
                    stopPropagation
                    value={itemIds.length === filteredRows.length}
                    onInput={() => {
                      trackEvent("applications_click_checkbox", {
                        toggle_all: true,
                        applications_type: "completed",
                      });
                      if (itemIds.length === filteredRows.length) {
                        setItemIds([]);
                      } else {
                        setItemIds(filteredRows.map((row) => row.id));
                      }
                    }}
                  ></Checkbox>
                </TableHeaderCell>,
                ...headers.map((header) => {
                  return (
                    <TableHeaderCell
                      key={header.header}
                      minWidth={header.minWidth}
                      className="pl-3 pb-[10px] border-b-[1px] border-neutral3"
                    >
                      {header.header}
                    </TableHeaderCell>
                  );
                }),
              ]}
            >
              {filteredRows.map((item) => (
                <TableRow
                  key={item.id}
                  className="cursor-pointer align-top"
                  onClick={() => {
                    trackEvent("applications_click_table_row", {
                      applications_type: "completed",
                    });
                    toggleItemIds(item.id);
                  }}
                >
                  <TableCell className="py-3 pl-3 pr-4 rounded-l-lg">
                    <Checkbox
                      stopPropagation
                      value={itemIds.includes(item.id)}
                      onInput={() => {
                        trackEvent("applications_click_checkbox", {
                          toggle_all: false,
                          applications_type: "completed",
                        });
                        toggleItemIds(item.id);
                      }}
                    ></Checkbox>
                  </TableCell>
                  <TableCell className="px-4 py-3">
                    <div className="flex gap-5">
                      <img
                        src={item.icon}
                        className="w-[80px] h-[80px] rounded-lg object-cover"
                      ></img>
                      <div className="font-bold">{item.text}</div>
                    </div>
                  </TableCell>
                  <TableCell className="px-4 py-3">
                    {item.name ?? "–"}
                  </TableCell>
                  <TableCell className="px-4 py-3">
                    <div className="flex flex-col text-neutral4 leading-[20px] text-[14px]">
                      {item.mail && <span>{item.mail}</span>}
                      {item.phone && <span>{item.phone}</span>}
                    </div>
                  </TableCell>
                  <TableCell className="px-4 py-3 text-neutral4 leading-[20px] text-[14px]">
                    {format(item.createdAt, "PPP", { locale: ru })}
                  </TableCell>
                  <TableCell className="px-4 py-3">
                    <Label size="sm">
                      {item.price ? item.price + "₽" : "Бесплатно"}
                    </Label>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          )}
        </div>
      </div>
      <BottomPanel visible={Boolean(itemIds.length)}>
        <div className="flex gap-3 items-center">
          <Icon
            id="check-all"
            color="var(--neutral4)"
          ></Icon>
          <span className="flex gap-1 font-semibold text-[13px] leading-[16px]">
            <span className="text-neutral4 ">Выбрано продуктов:</span>
            {itemIds.length}
          </span>
        </div>
        <Button
          disabled={updating}
          onClick={save}
        >
          Вернуть<span className="md:flex hidden">в новые</span>
        </Button>
      </BottomPanel>
    </Page>
  );
}
