import React, { ReactNode } from "react";
import Icon from "./ui/Icon";

interface Props {
  nickname: string;
  children?: ReactNode;
}

export default function ProfileContainer({ nickname, children }: Props) {
  return (
    <div className="rounded-[10px] pb-6 min-h-[204px] bg-neutral2 flex flex-col justify-center border border-neutral3 rounded-t-[10px]">
      <div className="flex items-center gap-4 px-4 py-3 bg-neutral1 rounded-t-[inherit]">
        <div className="flex items-center gap-2">
          <div className="rounded-full bg-[#EE6A5F] border border-[#CE5347] w-[12px] h-[12px]"></div>
          <div className="rounded-full bg-[#F5BD4F] border border-[#D6A243] w-[12px] h-[12px]"></div>
          <div className="rounded-full bg-[#61C454] border border-[#58A942] w-[12px] h-[12px]"></div>
        </div>
        <div className="rounded-[6px] bg-neutral2 px-4 py-1.5 flex items-center gap-2 flex-1 min-w-0">
          <Icon
            id="lock-fill"
            color="#9E9E9E"
            size={16}
          />
          <div className="text-[14px] leading-[24px] font-normal truncate">{`creatorstore.pro/${nickname}`}</div>
        </div>
      </div>
      {children}
    </div>
  );
}
