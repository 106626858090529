import React from "react";
import Title from "../components/ui/Title";
import Button from "../components/ui/Button";

export default function NotFound() {
  return (
    <div className="flex h-full w-full items-center justify-center flex-col gap-8">
      <a
        href="/"
        className="w-[96px] h-[96px]"
      >
        <img
          src="/logo.svg"
          className="w-full"
        ></img>
      </a>
      <Title
        size="lg"
        className="text-center"
      >
        Страница не найдена
      </Title>
      <a href="/">
        <Button>Перейти на главную</Button>
      </a>
    </div>
  );
}
