import React, { useMemo } from "react";
import { Content, Fields } from "../../pages/store/create-item/CreateItem";
import Card, { CardType } from "../ui/Card";
import ButtonWithImage, { ButtonType } from "../ui/ButtonWithImage";
import { twMerge } from "tailwind-merge";
import CreatorProfile from "./CreatorProfile";
import CreatorItemContent from "./CreatorItemContent";
import Avatar from "../ui/Avatar";

export type CreatorPageTheme = null | "sand" | "dark" | "black" | "violet";

export type CreatorPageItemType = "button" | "card" | "link";

export type ButtonItem = {
  id: number;
  type: "button";
  button: ButtonType;
  content?: Content;
  published: boolean;
  deleted?: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type CardItem = {
  id: number;
  type: "card";
  card: CardType;
  content?: Content;
  published: boolean;
  deleted?: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type LinkType = 1 | 2 | null;

export type LinkAppearance = {
  icon: string | null;
  text: string | null;
  link: string | null;
  linkType: LinkType;
};

export type LinkItem = {
  id: number;
  type: "link";
  link: LinkAppearance;
  published: boolean;
  deleted?: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type CreatorPageItem = ButtonItem | CardItem | LinkItem;

export function getItemMeta(item: CreatorPageItem, hideContent?: boolean) {
  return {
    item_type: item.type,

    item_icon:
      item?.type === "button"
        ? item.button.icon
        : item.type === "link"
          ? item.link.icon
          : item.card.icon,
    item_text:
      item?.type === "button"
        ? item.button.text
        : item.type === "link"
          ? item.link.text
          : item.card.text,
    ...(item?.type === "card"
      ? {
          item_description: item.card.description,
          item_price: item.card.price,
          item_button_text: item.card.button,
        }
      : {}),

    ...(!hideContent && item.type !== "link"
      ? {
          item_content_title: item?.content?.title,
          item_content_description: item?.content?.description,
          item_content_image: item.content?.description,
          item_content_button_text: item.content?.button,
          item_content_fields: (
            Object.keys(item.content?.fields || []) as Array<keyof Fields>
          ).filter((key) => item.content?.fields?.[key]),
        }
      : {}),
  };
}

export function isDarkTheme(theme: CreatorPageTheme) {
  return theme !== null && ["dark", "black"].includes(theme);
}

export interface Links {
  vk: string | null;
  tg: string | null;
  tt: string | null;
  yt: string | null;
  site: string | null;
}

export interface Profile {
  name: string | null;
  avatar?: string | null;
  bio?: string | null;
  nickname: string | null;
  links: Links;
}

export interface CreatorPageType {
  path?: string;
  onChangePath?: (path: string) => void;
  items?: CreatorPageItem[];
  theme?: CreatorPageTheme;
  profile?: Profile;
  hasStoreLabel?: boolean;
  isItemsLoading?: boolean;
  isUserLoading?: boolean;
  onCreatorStoreButtonClick?: () => void;
}

export interface CreatorPageProps extends CreatorPageType {
  className?: string;
  userId: string | null;
  onSubmit?: () => void;
  disableEvents?: boolean;
  maxWidth?: number;
}

export default function CreatorPage({
  items,
  path = "main",
  onChangePath,
  theme = null,
  profile,
  className,
  hasStoreLabel,
  isItemsLoading,
  isUserLoading,
  userId,
  onSubmit,
  onCreatorStoreButtonClick,
  disableEvents,
  maxWidth,
}: CreatorPageProps) {
  const item = useMemo(() => {
    return items?.find(
      (item) => item.id === parseInt(path.split("content/")[1]),
    );
  }, [path, items]);

  const buttonTheme = useMemo(() => {
    if (theme === "dark") {
      return "black";
    }
    if (theme === "sand") {
      return "sand";
    }
    if (theme === "black") {
      return "black";
    }
    if (theme === "violet") {
      return "gray";
    }
    return "secondary";
  }, [theme]);
  const cardButtonTheme = useMemo(() => {
    if (theme === "dark") {
      return "primary";
    }
    if (theme === "sand") {
      return "orange";
    }
    if (theme === "black") {
      return "green";
    }
    if (theme === "violet") {
      return "violet";
    }
    return "primary";
  }, [theme]);

  const bg = useMemo(() => {
    if (theme === "dark") {
      return "bg-neutral6";
    }
    if (theme === "sand") {
      return "bg-[#F7F5F1]";
    }
    if (theme === "black") {
      return "bg-neutral8";
    }
    return "bg-white";
  }, [theme]);

  const cardBg = useMemo(() => {
    if (theme === "sand") {
      return "#E3D9D1";
    }
    if (theme === "violet") {
      return "rgba(255, 255, 255, 0.90)";
    }
    return "";
  }, [theme]);

  function formLink(link: LinkAppearance) {
    console.log({ link });
    if (!link.linkType) {
      return link.link;
    }

    return `${link.linkType === 1 ? "tel:" : "mailto:"}${link.link}`;
  }

  return (
    <div
      className={twMerge(
        bg,
        "overflow-hidden h-full -full flex flex-col items-center gap-6 relative",
        className,
      )}
    >
      {theme === "violet" && profile?.avatar && (
        <div
          className="absolute flex-col w-full h-full -mx-6 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${profile.avatar})` }}
        >
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full bg-gradient-to-b from-[#0000006e] to-transparent to-[60%]"></div>
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full bg-gradient-to-t from-[#00000054] to-transparent to-[60%]"></div>
        </div>
      )}
      <div className="w-full h-full overflow-y-auto z-10 flex flex-col items-center">
        {isUserLoading && (
          <div className="mt-8 flex flex-col gap-4 w-full items-center">
            <Avatar size={96}></Avatar>
            <div className="h-[20px] w-[40%] rounded-md animate-pulse bg-neutral2"></div>
          </div>
        )}
        {!isUserLoading && profile && path === "main" && (
          <CreatorProfile
            theme={theme}
            className={theme === "dark" ? "self-start" : ""}
            name={profile?.name || ""}
            bio={profile?.bio || ""}
            avatar={profile?.avatar || ""}
            dark={isDarkTheme(theme)}
            links={profile?.links}
            disableEvents={disableEvents}
            maxWidth={maxWidth}
          ></CreatorProfile>
        )}
        <div
          className={twMerge(
            "px-6 pb-6 z-10 w-full pt-6",
            theme === "violet" && "pt-[240px]",
          )}
        >
          {isItemsLoading && (
            <div className="flex flex-col gap-6">
              <div className="h-[48px] rounded-lg animate-pulse bg-neutral2"></div>
              <div className="h-[48px] rounded-lg animate-pulse bg-neutral2"></div>
              <div className="h-[48px] rounded-lg animate-pulse bg-neutral2"></div>
            </div>
          )}
          {!isItemsLoading && items && path === "main" && (
            <div className={twMerge("flex flex-col w-full gap-4 md:gap-6")}>
              {items.map((item) =>
                item.type === "button" ? (
                  <ButtonWithImage
                    theme={buttonTheme}
                    key={item.id}
                    {...item.button}
                    onClick={() => {
                      item.content &&
                        onChangePath &&
                        onChangePath("content/" + item.id);
                    }}
                  ></ButtonWithImage>
                ) : item.type === "link" ? (
                  <ButtonWithImage
                    theme={buttonTheme}
                    key={item.id}
                    icon={item.link.icon}
                    text={item.link.text}
                    href={formLink(item.link) ?? undefined}
                    target="_blank"
                  ></ButtonWithImage>
                ) : (
                  <Card
                    dark={isDarkTheme(theme)}
                    buttonTheme={cardButtonTheme}
                    bg={cardBg}
                    key={item.id}
                    {...item.card}
                    onClick={() => {
                      item.content &&
                        onChangePath &&
                        onChangePath("content/" + item.id);
                    }}
                  ></Card>
                ),
              )}
            </div>
          )}
          {userId &&
            path !== "main" &&
            item?.type !== "link" &&
            item?.content &&
            onChangePath && (
              <CreatorItemContent
                itemId={item.id}
                itemContent={item.content}
                theme={theme}
                cardButtonTheme={cardButtonTheme}
                onChangePath={onChangePath}
                userId={userId}
                onSubmit={onSubmit}
              ></CreatorItemContent>
            )}
        </div>
        {hasStoreLabel && (
          <div className="mt-auto mb-12 z-10">
            <ButtonWithImage
              theme={buttonTheme}
              text="Создай свой магазин"
              icon="/logo.svg"
              fit
              to="/"
              target="_blank"
              onClick={() => {
                onCreatorStoreButtonClick && onCreatorStoreButtonClick();
              }}
            ></ButtonWithImage>
          </div>
        )}
      </div>
    </div>
  );
}
