import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children: ReactNode;
  selected?: boolean;
  first?: boolean;
  last?: boolean;
  onClick: (e: any) => void;
  className?: string;
}

export default function SelectOption({
  onClick,
  children,
  selected,
  first,
  last,
  className,
}: Props) {
  return (
    <div
      className={twMerge(
        "transition-colors text-[15px] leading-[23px] font-semibold text-neutral4 hover:text-neutral7 hover:bg-neutral2 cursor-pointer",
        selected && "text-primary7",
        first && "rounded-t-lg",
        last && "rounded-b-lg",
        className,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
